import { createSlice } from '@reduxjs/toolkit';
import { initialGainState } from '../../states/gain/gain.state';
import {
  setGainReducer,
  setRetrieveGainFailureReducer,
  setRetrieveGainReducer,
  setRetrieveGainSuccessReducer,
} from './gain.reducer';

export const gainSlice = createSlice({
  name: 'gain',
  initialState: initialGainState,
  reducers: {
    setGain: setGainReducer,
    setRetrieveGain: setRetrieveGainReducer,
    setRetrieveGainSuccess: setRetrieveGainSuccessReducer,
    setRetrieveGainFailure: setRetrieveGainFailureReducer,
  },
});

export const { setGain, setRetrieveGain, setRetrieveGainSuccess, setRetrieveGainFailure } =
  gainSlice.actions;

export const gainReducers = gainSlice.reducer;
