import { createSlice } from '@reduxjs/toolkit';
import { initialSiteState } from '../states/site/site.states';
import {
  setCurrentSiteReducer,
  setRetrieveSiteReducer,
  setRetrieveSiteSuccessReducer,
  setRetrieveSiteFailureReducer,
  setUpdateSiteCurveReducer,
  setUpdateSiteCurveSuccessReducer,
  setUpdateSiteCurveFailureReducer,
  setCreateSiteReducer,
  setCreateSiteSuccessReducer,
  setCreateSiteFailureReducer,
  setUpdateSiteParameterReducer,
  setUpdateSiteParameterSuccessReducer,
  setUpdateSiteParameterFailureReducer,
  setUpdateSiteModelReducer,
  setUpdateSiteModelSuccessReducer,
  setUpdateSiteModelFailureReducer,
  setDeleteSiteFailureReducer,
  setDeleteSiteReducer,
  setDeleteSiteSuccessReducer,
} from './site.reducer';

export const siteSlice = createSlice({
  name: 'site',
  initialState: initialSiteState,
  reducers: {
    setRetrieveSite: setRetrieveSiteReducer,
    setRetrieveSiteSuccess: setRetrieveSiteSuccessReducer,
    setRetrieveSiteFailure: setRetrieveSiteFailureReducer,
    setCurrentSite: setCurrentSiteReducer,
    setUpdateSiteParameter: setUpdateSiteParameterReducer,
    setUpdateSiteParameterSuccess: setUpdateSiteParameterSuccessReducer,
    setUpdateSiteParameterFailure: setUpdateSiteParameterFailureReducer,
    setUpdateSiteModel: setUpdateSiteModelReducer,
    setUpdateSiteModelSuccess: setUpdateSiteModelSuccessReducer,
    setUpdateSiteModelFailure: setUpdateSiteModelFailureReducer,
    setUpdateSiteCurve: setUpdateSiteCurveReducer,
    setUpdateSiteCurveSuccess: setUpdateSiteCurveSuccessReducer,
    setUpdateSiteCurveFailure: setUpdateSiteCurveFailureReducer,
    setCreateSite: setCreateSiteReducer,
    setCreateSiteSuccess: setCreateSiteSuccessReducer,
    setCreateSiteFailure: setCreateSiteFailureReducer,
    setDeleteSite: setDeleteSiteReducer,
    setDeleteSiteSuccess: setDeleteSiteSuccessReducer,
    setDeleteSiteFailure: setDeleteSiteFailureReducer,
  },
});

export const {
  setRetrieveSite,
  setRetrieveSiteFailure,
  setRetrieveSiteSuccess,
  setCurrentSite,
  setUpdateSiteCurve,
  setUpdateSiteCurveSuccess,
  setUpdateSiteCurveFailure,
  setCreateSite,
  setCreateSiteSuccess,
  setCreateSiteFailure,
  setUpdateSiteParameter,
  setUpdateSiteParameterSuccess,
  setUpdateSiteParameterFailure,
  setUpdateSiteModel,
  setUpdateSiteModelSuccess,
  setUpdateSiteModelFailure,
  setDeleteSite,
  setDeleteSiteSuccess,
  setDeleteSiteFailure,
} = siteSlice.actions;

export const siteReducers = siteSlice.reducer;
