/* eslint-disable prettier/prettier */
import { PayloadAction } from '@reduxjs/toolkit';
import { CurrentProductionKpiCurveNameState } from '../../states/currentTimeseries/currentKpiCurveName/currentKpiCurveName.state';

export const setCurrentProductionKpiCurveNameReducer = (
    state: CurrentProductionKpiCurveNameState,
    action: PayloadAction<string>,
) => {
    state.productionkpiCurveName = action.payload;
    state.isLoading = false;
};
