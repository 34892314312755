/* eslint-disable @typescript-eslint/no-unused-vars */
import { PmdKpiMeanGateway } from '../../../corelogic/domain/gateways/pmd-kpi-mean.gateways';
import { PmdKpiMeanDto } from '../../../corelogic/domain/models/pmdKpiMean/pmdKpiMeanDto.model';

import * as MOCKED_KPI_LAST_PMD from '../../../test/fixture/pmdKpiMeanDto.json';

export class InMemoryPmdKpiMeanGateway implements PmdKpiMeanGateway {
  expectedKpiLastPmd: PmdKpiMeanDto[] = MOCKED_KPI_LAST_PMD as PmdKpiMeanDto[];

  retrieveLast10Pmd = async (): Promise<PmdKpiMeanDto[]> => this.expectedKpiLastPmd;
}
