import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

type Props = { open: boolean; handleClose: () => void; fortuitSites: string[] };

const FortuitAlert: FC<Props> = ({ open, handleClose, fortuitSites }) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({ id: 'overview.alert.title' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.formatMessage({ id: 'overview.alert.description' })}
        </DialogContentText>
        {fortuitSites.map(site => (
          <Typography key={site}>{site}</Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {intl.formatMessage({ id: 'button.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FortuitAlert;
