export interface AdminRightState {
  admin: string[];
  isLoading: boolean;
  error?: string;
}

export const initialAdminState: AdminRightState = {
  isLoading: false,
  admin: [],
};
