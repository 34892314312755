import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import { setRetrieveSiteFailure, setRetrieveSiteSuccess } from '../../../../redux/site/site.slice';
import { Site } from '../../../models/site/site.model';

export const retrieveSiteEpic = (
  action$: Observable<PayloadAction<string>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('site/setRetrieveSite'),
    switchMap(action => {
      return from(dependencies.siteGateway.retrieveSite(action.payload)).pipe(
        map((site: Site) => setRetrieveSiteSuccess(site)),
        catchError(() =>
          of(setRetrieveSiteFailure(`Retrieving site ${action.payload} has failed`)),
        ),
      );
    }),
  );
