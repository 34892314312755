import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import {
  setUpdateSiteParameterFailure,
  setUpdateSiteParameterSuccess,
} from '../../../../redux/site/site.slice';
import { Site } from '../../../models/site/site.model';

export const updateSiteParameterEpic = (
  action$: Observable<PayloadAction<{ siteId: string; updateBody: Partial<Site> }>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('site/setUpdateSiteParameter'),
    switchMap(action => {
      return from(
        dependencies.siteGateway.updateSiteParameter(
          action.payload.siteId,
          action.payload.updateBody,
        ),
      ).pipe(
        map((site: Site) => setUpdateSiteParameterSuccess(site)),
        catchError(() =>
          of(
            setUpdateSiteParameterFailure(
              `update site parameter ${action.payload.siteId} has failed`,
            ),
          ),
        ),
      );
    }),
  );
