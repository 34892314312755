import { PayloadAction } from '@reduxjs/toolkit';
import { SiteFortuitState } from '../states/fortuit/siteFortuit.state';
import { SiteFortuitDTO } from '../../domain/models/site/siteFortuit.dto';

export const setRetrieveSiteFortuitReducer = (state: SiteFortuitState) => {
  state.isLoading = true;
};

export const setRetrieveSiteFortuitSuccessReducer = (
  state: SiteFortuitState,
  action: PayloadAction<SiteFortuitDTO[]>,
) => {
  state.isLoading = false;
  state.fortuit = action.payload;
};

export const setRetrieveSiteFortuitFailureReducer = (
  state: SiteFortuitState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
  state.fortuit = [];
};
