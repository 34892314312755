import { Data } from 'plotly.js';
import { Curve } from '../../../../../../corelogic/domain/models/curve/curve.model';

const biVariablePlotlyDataBuilder = (curve: Curve): Data[] => {
  const dataPlotly: Data[] = [];
  if (curve.data.x2 !== undefined) {
    let tempX = [curve.data.x1[0]];
    let tempY = [curve.data.y[0]];
    for (let i = 1; i <= curve.data.x1.length; i++) {
      if (i != 0 && curve.data.x1[i] > curve.data.x1[i - 1]) {
        tempX.push(curve.data.x1[i]);
        tempY.push(curve.data.y[i]);
      } else {
        const dataName = String(curve.data.x2[i - 1]);
        dataPlotly.push({
          x: tempX,
          y: tempY,
          type: 'scatter',
          name: dataName,
          hovertemplate: 'y=%{y}<br>x=%{x}<extra></extra>',
        });
        tempX = [curve.data.x1[i]];
        tempY = [curve.data.y[i]];
      }
    }
  }
  return dataPlotly;
};

const monoVariablePlotlyDataBuilder = (curve: Curve): Data[] => {
  const dataPlotly: Data[] = [
    {
      x: curve.data.x1,
      y: curve.data.y,
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: '#009EB3' },
      hovertemplate: 'y=%{y}<br>x=%{x}<extra></extra>',
    },
  ];
  return dataPlotly;
};
export const createPlotlyData = (curve: Curve): Data[] => {
  let curveData: Data[];
  if (curve.data.x2 !== undefined && curve.data.x2.length > 1) {
    curveData = biVariablePlotlyDataBuilder(curve);
  } else {
    curveData = monoVariablePlotlyDataBuilder(curve);
  }
  return curveData;
};
