import { LocalizationProvider, enUS, esES, frFR } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, es, fr } from 'date-fns/locale';
import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import AdminModule from './adapters/primaries/react/components/admin/admin';
import DailyCapacity from './adapters/primaries/react/components/dailyCapacity/dailyCapacity';
import PowerDispatch from './adapters/primaries/react/components/dispatch/power/powerDispatch';
import Exclusion from './adapters/primaries/react/components/exclusion/exclusion';
import Failures from './adapters/primaries/react/components/failures/failures';
import Gains from './adapters/primaries/react/components/gains/gains';
import EfficiencySelector from './adapters/primaries/react/components/generalDashboard/component/efficenciencySelector/efficiencySelector';
import Power from './adapters/primaries/react/components/generalDashboard/component/power/power';
import ManufacturerInformation from './adapters/primaries/react/components/manufacturerInformation/manufacturerInformation';
import MlModel from './adapters/primaries/react/components/mlModel/mlModel';
import TagMapping from './adapters/primaries/react/components/tagMapping/tag-mapping';
import Overview from './adapters/primaries/react/pages/overview/overview';
import SiteInformation from './adapters/primaries/react/pages/siteInformation/site-information';
import { PATHS } from './constants/path';
import { flattenMessages } from './i18n/intl';
import enMessages from './translation/en.json';
import esMessages from './translation/es.json';
import frMessages from './translation/fr.json';

export const locales = {
  en: flattenMessages(enMessages),
  fr: flattenMessages(frMessages),
  es: flattenMessages(esMessages),
};

const localesFNS: { [key: string]: Locale } = {
  en: enGB,
  fr: fr,
  es: es,
};

const localesMUI: { [key: string]: any } = {
  en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  fr: frFR.components.MuiLocalizationProvider.defaultProps.localeText,
  es: esES.components.MuiLocalizationProvider.defaultProps.localeText,
};

let languageDefault: string = navigator.language.split(/[-_]/)[0];

const language = window.localStorage.getItem('language');

if (language && language !== null) {
  languageDefault = language;
}
window.localStorage.setItem('language', languageDefault);

const RoutesInitialization: React.FC = () => {
  const [language, setLanguage] = useState<string>(languageDefault);

  return (
    <IntlProvider
      locale={language}
      messages={locales[language as keyof typeof locales]}
      defaultLocale={language}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localesFNS[language]}
        localeText={localesMUI[language]}
      >
        <div className="wrapper">
          <HashRouter>
            <SiteInformation language={language} setLanguage={setLanguage} />
            <Routes>
              <Route
                path={PATHS.HOME}
                element={<Navigate to={PATHS.GENERAL_DASHBOARD} replace />}
              />
              <Route
                path={PATHS.GENERAL_DASHBOARD}
                element={<Navigate to={PATHS.POWER} replace />}
              />
              <Route path={PATHS.ML_MODEL} element={<MlModel />} />
              <Route path={PATHS.MANUFACTURER_INFORMATION} element={<ManufacturerInformation />} />
              <Route path={PATHS.METROSCOPE_FAILURE} element={<Failures />} />
              <Route path={PATHS.ADMIN} element={<AdminModule />} />
              <Route path={PATHS.TAG_MAPPING} element={<TagMapping />} />
              <Route path={PATHS.POWER} element={<Power />} />
              <Route path={PATHS.EFFICIENCY} element={<EfficiencySelector />} />
              <Route path={PATHS.DATA_EXCLUSION} element={<Exclusion />} />
              <Route path={PATHS.DAILY_CAPACITY} element={<DailyCapacity />} />
              <Route path={PATHS.GAINS} element={<Gains />} />
              <Route path={PATHS.OVERVIEW} element={<Overview />} />
              <Route path={PATHS.POWER_DISPATCH} element={<PowerDispatch />} />
            </Routes>
          </HashRouter>
        </div>
      </LocalizationProvider>
    </IntlProvider>
  );
};

export default RoutesInitialization;
