import { PayloadAction } from '@reduxjs/toolkit';
import { DownloadUrlState } from '../states/downloadUrl/downloadUrl.state';

export interface DailyCapacityFileInputType {
  creationDate: string;
  siteId: string;
  type: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setRetrieveDailyCapacityFileFromS3Reducer = (
  state: DownloadUrlState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<DailyCapacityFileInputType>,
) => {
  state.isLoading = true;
};

export const setRetrieveDailyCapacityFileFromS3SuccessReducer = (
  state: DownloadUrlState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<{ url: string }>,
) => {
  window.open(action.payload.url, '_blank');
  state.isLoading = false;
};

export const setRetrieveDailyCapacityFileFromS3FailureReducer = (
  state: DownloadUrlState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
};
