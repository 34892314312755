/* eslint-disable prettier/prettier */

import { Gain } from '../../../../corelogic/domain/models/gain/gain.model';

export const gainFixture: Gain = {
  site_id: '1',
  gains_counters: [
    {
      gain_counter: 2422.79,
      year: '2023',
      cumulated_pmd_minutes: 11207,
      mean_gain_counter_on_pmd: 12.97
    },
    {
      gain_counter: 0,
      year: '2024',
      cumulated_pmd_minutes: 0,
      mean_gain_counter_on_pmd: 0
    }
  ],
  total_gain_counter: 2422.79,
  total_mean_gain_counter_on_pmd: 6.485
}


export const gainsFixture: Gain[] = [
  {
    site_id: '1',
    gains_counters: [
      {
        gain_counter: 2422.79,
        year: '2023',
        cumulated_pmd_minutes: 11207,
        mean_gain_counter_on_pmd: 12.97
      },
      {
        gain_counter: 0,
        year: '2024',
        cumulated_pmd_minutes: 0,
        mean_gain_counter_on_pmd: 0
      }
    ],
    total_gain_counter: 2422.79,
    total_mean_gain_counter_on_pmd: 6.485
  },
  {
    site_id: '2',
    gains_counters: [
      {
        gain_counter: 32,
        year: '2024',
        cumulated_pmd_minutes: 542,
        mean_gain_counter_on_pmd: 1.563
      }
    ],
    total_gain_counter: 156.79,
    total_mean_gain_counter_on_pmd: 9.431
  }
];
