import { CardContent } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { CustomCard } from '../../../../../util/functionUtil';
import TabComponent from '../tabComponent/tabComponent';
import MlModelTableComponent from './component/MlModelTable/mlModelTableComponent';
import { buttonMessage } from './component/constant';
import styles from './mlModel.module.scss';

const MlModel: FC = () => {
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const [selectedModelType, setSelectedModelType] = useState<string>('All');
  const intl = useIntl();

  const efficiencyRecord: Record<string, string> = {
    'Max Efficiency': intl.formatMessage({ id: 'button.efficiency.max' }),
    'Min Efficiency': intl.formatMessage({ id: 'button.efficiency.minEfficiency' }),
    All: intl.formatMessage({ id: 'button.efficiency.all' }),
    Power: intl.formatMessage({ id: 'button.efficiency.power' }),
  };

  return (
    <div className={clsx(styles.mlModelPageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined">
        <CardContent className={styles.parent_gap_container}>
          <div className={styles.mlPagetitle}>
            <h1>
              <FormattedMessage id="home.mlModel" />
            </h1>
          </div>
          <TabComponent
            selectedTab={selectedModelType}
            setSelectedTab={setSelectedModelType}
            fieldInTab={efficiencyRecord}
            buttonMessage={buttonMessage}
          />
          <MlModelTableComponent selectedModelType={selectedModelType} />
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default MlModel;
