/* eslint-disable prettier/prettier */
import { PayloadAction } from '@reduxjs/toolkit';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../configurations/dependencies.interface';
import { RootState } from '../../../redux/create-store';

import { ForecastInformationDTO } from '../../models/overview/forecastInformation.dto';
import {
  setRetrieveForecastInformationFailure,
  setRetrieveForecastInformationSuccess,
} from '../../../redux/forecastInformation/forecastInformation.slice';

export const retrieveForecastInformationEpic = (
  action$: Observable<PayloadAction<{ siteId: string; startDate: string; endDate: string }>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('forecastInformation/setRetrieveForecastInformation'),
    switchMap(action => {
      return from(
        dependencies.overviewGateway.retrieveForecastInformation(
          action.payload.siteId,
          action.payload.startDate,
          action.payload.endDate,
        ),
      ).pipe(
        map((forecast: ForecastInformationDTO[]) =>
          setRetrieveForecastInformationSuccess(forecast),
        ),
        catchError(() =>
          of(
            setRetrieveForecastInformationFailure(
              `Retrieve forecast information for ${action.payload.siteId} at ${action.payload.startDate} to ${action.payload.endDate} has failed`,
            ),
          ),
        ),
      );
    }),
  );
