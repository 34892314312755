/* eslint-disable @typescript-eslint/no-unused-vars */
import { KpiImbalanceGateway } from '../../../corelogic/domain/gateways/kpi-imbalance.gateways';
import { KpiImbalance } from '../../../corelogic/domain/models/kpiImbalance/kpiImbalance.model';
import { SiteFortuitDTO } from '../../../corelogic/domain/models/site/siteFortuit.dto';

import * as MOCKED_KPIIMBALANCE from '../../../test/fixture/kpiImbalance.json';
import * as MOCKED_FORTIT from '../../../test/fixture/fortuit.json';

export class InMemoryKpiImbalanceGateway implements KpiImbalanceGateway {
  expectedKpiImbalance: KpiImbalance[] = MOCKED_KPIIMBALANCE as KpiImbalance[];

  retrieveKpiImbalance(
    siteId: string,
    startDate: string,
    endDate: string,
  ): Promise<KpiImbalance[]> {
    return new Promise<KpiImbalance[]>(() => {
      return this.expectedKpiImbalance;
    });
  }

  retrieveFortuit(): Promise<SiteFortuitDTO[]> {
    return Promise.resolve(MOCKED_FORTIT) as Promise<SiteFortuitDTO[]>;
  }
}
