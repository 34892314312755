import { PayloadAction } from '@reduxjs/toolkit';
import { SiteNameIdDto } from '../../domain/models/site/siteNameId.dto';
import { SiteNamesIdsState } from '../states/siteNamesIds/siteNamesIds.states';

export const setRetrieveSiteNamesIdsReducer = (state: SiteNamesIdsState) => {
  state.isLoading = true;
};

export const setRetrieveSiteNamesIdsSuccessReducer = (
  state: SiteNamesIdsState,
  action: PayloadAction<SiteNameIdDto[]>,
) => {
  state.isLoading = false;
  state.siteNamesIds = action.payload;
};

export const setRetrieveSiteNamesIdsFailureReducer = (
  state: SiteNamesIdsState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
  state.siteNamesIds = [];
};
