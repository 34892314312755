export interface GraphIndexState {
  graphIndex: number;
  isLoading: boolean;
  error?: string;
}

export const initialGraphIndexState: GraphIndexState = {
  graphIndex: -1,
  isLoading: false,
};
