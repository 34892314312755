import { ApiKpiImbalanceGateway } from '../adapters/secondaries/kpiImbalance/api-kpiImbalance.gateway';
import { ApiPmdKpiMeanGateway } from '../adapters/secondaries/pmdKpiMean/api-pmd-kpi-mean.gateway';
import { ApiSiteGateway } from '../adapters/secondaries/site/api-site.gateway';
import { ApiSiteNamesIdsGateway } from '../adapters/secondaries/siteNames/api-site-names-ids.gateway';
import { Dependencies } from './dependencies.interface';
import { ApiOverviewGateway } from '../adapters/secondaries/overview/api-overview.gateways';

const siteGateway: ApiSiteGateway = new ApiSiteGateway();
const siteNamesIdsGateway: ApiSiteNamesIdsGateway = new ApiSiteNamesIdsGateway();
const kpiImbalanceGateway: ApiKpiImbalanceGateway = new ApiKpiImbalanceGateway();
const pmdKpiMeanGateway: ApiPmdKpiMeanGateway = new ApiPmdKpiMeanGateway();
const overviewGateway: ApiOverviewGateway = new ApiOverviewGateway();

export const productionDependencies: Dependencies = {
  siteGateway,
  siteNamesIdsGateway,
  kpiImbalanceGateway,
  pmdKpiMeanGateway,
  overviewGateway,
};
