import { createSlice } from '@reduxjs/toolkit';
import { initialYearOfCurrentGainState } from '../states/yearOfCurrentGain/yearOfCurrentGain.state';
import { setRetrieveYearOfCurrentGainReducer } from './yearOfCurrentGain.reducer';

export const yearOfCurrentGainSlice = createSlice({
  name: 'yearOfCurrentGain',
  initialState: initialYearOfCurrentGainState,
  reducers: {
    setRetrieveYearOfCurrentGain: setRetrieveYearOfCurrentGainReducer,
  },
});

export const { setRetrieveYearOfCurrentGain } = yearOfCurrentGainSlice.actions;

export const yearOfCurrentGainReducers = yearOfCurrentGainSlice.reducer;
