import { InMemoryKpiImbalanceGateway } from '../adapters/secondaries/kpiImbalance/in-memory-kpiImbalance.gateway';
import { InMemoryOverviewGateway } from '../adapters/secondaries/overview/in-memory-overview.gateways';
import { InMemoryPmdKpiMeanGateway } from '../adapters/secondaries/pmdKpiMean/in-memory-pmd-kpi-mean.gateway';
import { InMemorySiteGateway } from '../adapters/secondaries/site/in-memory-site.gateway';
import { InMemorySiteNamesIdsGateway } from '../adapters/secondaries/siteNames/in-memory-site-names-ids.gateway';
import { Dependencies } from './dependencies.interface';

const siteGateway = new InMemorySiteGateway();
const siteNamesIdsGateway = new InMemorySiteNamesIdsGateway();
const kpiImbalanceGateway = new InMemoryKpiImbalanceGateway();
const pmdKpiMeanGateway = new InMemoryPmdKpiMeanGateway();
const overviewGateway = new InMemoryOverviewGateway();

export const inMemoryDependencies: Dependencies = {
  siteGateway,
  siteNamesIdsGateway,
  kpiImbalanceGateway,
  pmdKpiMeanGateway,
  overviewGateway,
};
