import { Card, CardContent, Divider, Typography } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './overviewTooltip.module.scss';
import { OverviewData } from '../../../../../../corelogic/domain/models/overview/overview.dto';
import { getLocalHours } from '../../../../../../util/functionUtil';
type Props = {
  overviewData: OverviewData[];
};

const OverviewToolTip: FC<Props> = ({ overviewData }) => {
  const renderOverviewData = () => {
    const overviewItems = [];
    for (let i = 1; i <= 4; i++) {
      const timestamp = getLocalHours(overviewData[i].timestamp);
      const estimatedGap = overviewData[i].estimated_gap ?? 'N/A';

      overviewItems.push(
        <div className={styles.primaryValueAndUnit} key={i}>
          <Typography fontSize={14} fontWeight={'bold'} variant="body1">
            {timestamp}
          </Typography>
          <Divider
            sx={{ margin: '8px', borderRightWidth: 'medium' }}
            variant="fullWidth"
            orientation="vertical"
            flexItem
          />
          <Typography variant="body1" fontSize={14}>
            <FormattedMessage id={'overview.gap'} />
          </Typography>
          <Typography fontWeight={'bold'} fontSize={16} variant="body1">
            {estimatedGap}
          </Typography>
          <Typography fontSize={14} variant="body1">
            {'MW'}
          </Typography>
        </div>,
      );
    }
    return overviewItems;
  };

  return (
    <Card>
      <CardContent>
        <Typography marginBottom={4} variant="h5" fontWeight={'bold'}>
          <FormattedMessage id={'overview.hoverTitle'} />
        </Typography>
        <div className={styles.primaryValueContainer}>{renderOverviewData()}</div>
      </CardContent>
    </Card>
  );
};

export default OverviewToolTip;
