/* eslint-disable prettier/prettier */
import { PayloadAction } from '@reduxjs/toolkit';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../configurations/dependencies.interface';
import { RootState } from '../../../redux/create-store';
import { setRetrievePmdKpiMeanFailure, setRetrievePmdKpiMeanSuccess } from '../../../redux/pmdKpiMean/pmd-kpi-mean.slice';
import { PmdKpiMeanDto } from '../../models/pmdKpiMean/pmdKpiMeanDto.model';

export const retrievePmdKpiMeanEpic = (
    action$: Observable<PayloadAction<string>>,
    _state$: StateObservable<RootState>,
    dependencies: Dependencies,
) =>
    action$.pipe(
        ofType('pmdKpiMean/setRetrievePmdKpiMean'),
        switchMap(action => {
            return from(dependencies.pmdKpiMeanGateway.retrieveLast10Pmd(action.payload)).pipe(
                map((pmdKpiMeanDto: PmdKpiMeanDto[]) =>
                    setRetrievePmdKpiMeanSuccess(
                        pmdKpiMeanDto.sort(
                            (pmdKpiMean1: PmdKpiMeanDto, pmdKpiMean2: PmdKpiMeanDto) =>
                                new Date(pmdKpiMean1.start_pmd).getTime() - new Date(pmdKpiMean2.start_pmd).getTime(),
                        ),
                    ),
                ),

                catchError(() =>
                    of(setRetrievePmdKpiMeanFailure(`Retrieving pmd kpi mean ${action.payload} has failed`)),
                ),
            );
        }),
    );
