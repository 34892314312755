import { createSlice } from '@reduxjs/toolkit';
import { setGraphIndexReducer } from './graphIndex.reducers';
import { initialGraphIndexState } from '../../../states/currentTimeseries/graphIndex/graphIndex.state';

export const graphIndexSlice = createSlice({
  name: 'graphIndex',
  initialState: initialGraphIndexState,
  reducers: {
    setGraphIndex: setGraphIndexReducer,
  },
});

export const { setGraphIndex } = graphIndexSlice.actions;

export const graphIndexReducers = graphIndexSlice.reducer;
