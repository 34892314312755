import { Tooltip } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/custom-components/upload-files.css';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Curve } from '../../../../../corelogic/domain/models/curve/curve.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { findMinMax } from '../../../../../util/functionUtil';
import ExportExcel from '../exportExcel/exportExcel';
import { EXCEL_TEMPLATE_PATH } from '../exportExcel/paths';
import ConstructorCurveGraph from '../graph/constructorCurveGraph/constructor-curve-graph';
import { createPlotlyData } from '../graph/constructorCurveGraph/function-data-constructor';
import { CURVE_TYPE, ErrorByCurve, TYPE_CURVE_PARAMETER } from '../type';
import UploadCurve from '../uploadCurve/uploadCurve';
import { marginGraphInPopin } from './constant';
import { ParameterNameVariableMapper } from './parameterNameMonoMulti';
import styles from './uploadPopinFiles.module.scss';

type Props = {
  newCurvesData: Curve[];
  setNewCurvesData: React.Dispatch<React.SetStateAction<Curve[]>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadPopinFiles: React.FC<Props> = ({ newCurvesData, setNewCurvesData, setHasError }) => {
  const typeOfCurve: string[] = ['Power Output', 'Heat Rate'];

  const [errorByCurve, setErrorByCurve] = useState<ErrorByCurve[]>([]);
  const intl = useIntl();
  const { site } = useSelector((state: RootState) => state.site);
  const { parameterName } = useSelector((state: RootState) => state.currentParameter);
  const curvesRef = useRef<HTMLDivElement>(null);
  const [curvesWidth, setCurvesWidth] = useState(0);

  useEffect(() => {
    errorByCurve.length > 0 ? setHasError(true) : setHasError(false);
  }, [errorByCurve]);

  function handleResize() {
    if (curvesRef?.current?.clientWidth) {
      setCurvesWidth(curvesRef?.current?.clientWidth);
    }
  }

  useEffect(() => {
    handleResize();
  }, [curvesRef.current?.clientWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const correctionCurve: Curve[] | undefined = site.correction_parameters
    .filter(parameter => parameter.name === parameterName)
    .map(curve => curve.correction)[0];

  const constructorGraph = (name: string): Curve => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const curve = correctionCurve.find(constructorCurve => constructorCurve.name === name)!;
    return curve;
  };

  const axisParameter: string[] =
    ParameterNameVariableMapper[parameterName as keyof typeof ParameterNameVariableMapper]
      .input_variable;

  const parameterType: string =
    ParameterNameVariableMapper[parameterName as keyof typeof ParameterNameVariableMapper].variable;

  const displayCorrectCurve = (curveName: string): ReactNode => {
    if (newCurvesData !== undefined) {
      const newCurve = newCurvesData.find((curve: Curve) => curve.name === curveName);
      if (newCurve !== undefined) {
        return (
          <ConstructorCurveGraph
            dataPlotly={createPlotlyData(newCurve)}
            width={667}
            height={300}
            margin={marginGraphInPopin}
            xAxis={findMinMax(newCurve.data.x2)}
            yAxis={findMinMax(newCurve.data.x1)}
            xAxisName={
              ParameterNameVariableMapper[parameterName as keyof typeof ParameterNameVariableMapper]
                .x_axis_name
            }
            yAxisName={
              newCurve.name === CURVE_TYPE.HeatRate
                ? intl.formatMessage({ id: 'home.heatRateCoefficient' })
                : intl.formatMessage({ id: 'home.powerCoefficient' })
            }
          />
        );
      } else if (correctionCurve !== undefined) {
        return (
          <ConstructorCurveGraph
            dataPlotly={createPlotlyData(constructorGraph(curveName))}
            width={curvesWidth / 2 - 75}
            height={300}
            margin={marginGraphInPopin}
            xAxis={findMinMax(constructorGraph(curveName).data.x2)}
            yAxis={findMinMax(constructorGraph(curveName).data.x1)}
            xAxisName={
              ParameterNameVariableMapper[parameterName as keyof typeof ParameterNameVariableMapper]
                .x_axis_name
            }
            yAxisName={
              curveName === CURVE_TYPE.HeatRate
                ? intl.formatMessage({ id: 'home.heatRateCoefficient' })
                : intl.formatMessage({ id: 'home.powerCoefficient' })
            }
          />
        );
      }
    } else if (correctionCurve !== undefined) {
      return (
        <ConstructorCurveGraph
          dataPlotly={createPlotlyData(constructorGraph(curveName))}
          width={curvesWidth / 2 - 75}
          height={300}
          xAxis={findMinMax(constructorGraph(curveName).data.x2)}
          yAxis={findMinMax(constructorGraph(curveName).data.x1)}
          margin={marginGraphInPopin}
          xAxisName={
            ParameterNameVariableMapper[parameterName as keyof typeof ParameterNameVariableMapper]
              .x_axis_name
          }
          yAxisName={
            curveName === CURVE_TYPE.HeatRate
              ? intl.formatMessage({ id: 'home.heatRateCoefficient' })
              : intl.formatMessage({ id: 'home.powerCoefficient' })
          }
        />
      );
    }
  };

  const downloadTextToDisplay = (
    <span>
      <FormattedMessage id="popin.downloadTemplate" /> {parameterType}
    </span>
  );

  const curveTypeFilename: string =
    parameterType === TYPE_CURVE_PARAMETER.Bivariable
      ? EXCEL_TEMPLATE_PATH.BIVARIABLE
      : EXCEL_TEMPLATE_PATH.MONOVARIABLE;

  return (
    <div className={styles.uploadFileText}>
      <div className={styles.uploadContainer}>
        <FormattedMessage id="popin.uploadFiles" />
      </div>
      {parameterName !== undefined ? (
        <>
          <div className={styles.uploadFileText}>
            <span>
              <div className={styles.inputVariable}>
                <FormattedMessage id="popin.inputVariable" />
              </div>
              <div>
                {axisParameter.map((axis: string) => (
                  <div key={axis}>{axis}</div>
                ))}
              </div>
            </span>
          </div>
          <div className={styles.inputVariableContainer}>
            <div>
              <Tooltip title={parameterType + '_template.xlsx'}>
                <a href={curveTypeFilename} target="_blank" download rel="noopener noreferrer">
                  <button className={styles.downloadTemplateButton}>
                    <span
                      className="material-icons-outlined"
                      aria-hidden="true"
                      style={{ verticalAlign: 'bottom' }}
                    >
                      <FormattedMessage id="icon.download" />
                    </span>
                    {downloadTextToDisplay}
                  </button>
                </a>
              </Tooltip>
            </div>
          </div>
        </>
      ) : null}

      <div className={styles.importCurvesContainer} ref={curvesRef}>
        {typeOfCurve.map((curveName: string) => (
          <div key={curveName} className={styles.importBlockContainer}>
            <div className={styles.titleAndExportButton}>
              <div className={styles.importCurvePopinNameContainer}>{curveName}</div>
              <ExportExcel
                curve={constructorGraph(curveName)}
                parameterName={parameterName}
              ></ExportExcel>
            </div>
            <div className={styles.uploadCurveContainer}>
              <UploadCurve
                isCurveAlreadyDefined={
                  newCurvesData.some((curve: Curve) => curve.name === curveName) ||
                  correctionCurve !== undefined
                }
                setNewCurvesData={setNewCurvesData}
                curveName={curveName}
                newCurvesData={newCurvesData}
                setErrorByCurve={setErrorByCurve}
                errorByCurve={errorByCurve}
              />
              {errorByCurve.some((error: ErrorByCurve) => error.name === curveName) ? (
                <div className={styles.errorContainer}>
                  {errorByCurve
                    .filter((errorFiltered: ErrorByCurve) => errorFiltered.name === curveName)
                    .map((error: ErrorByCurve) => (
                      <div key={error.name} className={styles.logoAndErrorTextContainer}>
                        <span className="material-icons-outlined">
                          <FormattedMessage id="icon.error" />
                        </span>
                        <div>{error.errorMessage.message}</div>
                      </div>
                    ))}
                </div>
              ) : (
                <div>{displayCorrectCurve(curveName)}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadPopinFiles;
