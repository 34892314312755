import { PayloadAction } from '@reduxjs/toolkit';
import { ContextCurrentParameterState } from '../states/parameter/context-current-parameter.state';

export const setContextCurrentParameterReducer = (
  state: ContextCurrentParameterState,
  action: PayloadAction<string>,
) => {
  state.parameterName = action.payload;
  state.isLoading = false;
};
