import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Checkbox, FormControlLabel, IconButton, Popover, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { subDays } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { MlModel } from '../../../../../../../corelogic/domain/models/mlModel/mlModel.model';
import { RootState } from '../../../../../../../corelogic/redux/create-store';
import { setUpdateSiteModel } from '../../../../../../../corelogic/redux/site/site.slice';
import { getFormattedDateWithMinute } from '../../../../../../../helpers/dateFunction';
import { CustomButton, StripedDataGrid } from '../../../../../../../util/functionUtil';
import ExportExcel from '../../../exportExcel/exportExcel';
import { COLUMN_LABEL, MODEL_TYPE, PAGE } from '../../../type';
import styles from './mlModelTable.module.scss';

type Props = {
  selectedModelType: string;
};

const MlModelTableComponent: FC<Props> = ({ selectedModelType }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state: RootState) => state.isAdmin);
  const { site } = useSelector((state: RootState) => state.site);
  const [downloadedModel, setDownloadedModel] = useState<string[]>([]);
  const [isStatusChecked, setIsStatusChecked] = useState({
    approved: false,
    toBeApproved: false,
  });

  useEffect(() => {
    function checkUserData() {
      const dlModel = localStorage.getItem('downloadedModel')?.split('#');
      if (dlModel) {
        setDownloadedModel(dlModel);
      }
    }
    window.addEventListener('storage', checkUserData);
    checkUserData();
    return () => {
      window.removeEventListener('storage', checkUserData);
    };
  }, []);

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsStatusChecked({ ...isStatusChecked, [event.target.name]: event.target.checked });
  };
  const handleStatusButton = (status: boolean, modelId: string) => {
    let confirmMessage = 'Are you sure to approve this document ?';
    if (status === false) {
      confirmMessage = 'Are you sure to reject this document ?';
    }
    if (confirm(confirmMessage)) {
      const effectiveDateToSave: string =
        status === true ? subDays(new Date(), 1).toISOString() : '';
      dispatch(
        setUpdateSiteModel({
          siteId: site._id,
          modelId: modelId,
          status,
          effectiveDate: effectiveDateToSave,
        }),
      );
    }
  };
  const statusToDisplay = (value: boolean | null, modelId: string) => {
    if (value === null && isAdmin) {
      return (
        <div className={styles.status_button}>
          <Tooltip
            title={
              !downloadedModel?.includes(modelId)
                ? intl.formatMessage({ id: 'mlModel.downloadFirst' })
                : ''
            }
            placement="top"
          >
            <span>
              <CustomButton
                variant="contained"
                type="button"
                disabled={!downloadedModel?.includes(modelId)}
                onClick={() => handleStatusButton(true, modelId)}
                color="primary"
              >
                <FormattedMessage id="button.approve" />
              </CustomButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !downloadedModel?.includes(modelId)
                ? intl.formatMessage({ id: 'mlModel.downloadFirst' })
                : ''
            }
            placement="top"
          >
            <span>
              <CustomButton
                variant="outlined"
                disabled={!downloadedModel?.includes(modelId)}
                type="button"
                className={styles.button_check_style}
                onClick={() => handleStatusButton(false, modelId)}
              >
                <FormattedMessage id="button.reject" />
              </CustomButton>
            </span>
          </Tooltip>
        </div>
      );
    } else if (value === null && !isAdmin) {
      return (
        <div className={`${styles.icon_and_status_container} ${styles.iconPending}`}>
          <span className={`${styles.material_icons_outlined} ${styles.icon_status_container}`}>
            timer
          </span>
          <FormattedMessage id="mlModel.pending" />
        </div>
      );
    } else if (value === true) {
      return (
        <div className={`${styles.icon_and_status_container} ${styles.iconApproved}`}>
          <span className={`${styles.material_icons_outlined} ${styles.icon_status_container}`}>
            <FormattedMessage id="icon.check_circle" />
          </span>
          <FormattedMessage id="mlModel.approved" />
        </div>
      );
    } else {
      return (
        <div className={`${styles.icon_and_status_container} ${styles.iconRejected}`}>
          <span className={`${styles.material_icons_outlined} ${styles.icon_status_container}`}>
            <FormattedMessage id="icon.cancel" />
          </span>
          <FormattedMessage id="mlModel.rejected" />
        </div>
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: COLUMN_LABEL.NAME,
      headerName: intl.formatMessage({ id: 'mlModel.fileName' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: params => {
        const value = params.value as string;
        const parts = value.split('/');
        return parts.length > 1 ? parts[1] : value;
      },
    },
    {
      field: COLUMN_LABEL.MODEL_TYPE,
      headerName: intl.formatMessage({ id: 'mlModel.modelType' }),
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: params => {
        const value = params.value as string;
        if (value === MODEL_TYPE.MAX_EFFICIENCY) {
          return PAGE.MAX_EFFICIENCY;
        } else if (value === MODEL_TYPE.MIN_EFFICIENCY) {
          return PAGE.MIN_EFFICIENCY;
        }
        return value;
      },
    },
    {
      field: COLUMN_LABEL.CREATION_DATE,
      headerName: intl.formatMessage({ id: 'mlModel.creationDate' }),
      editable: false,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      renderCell: params => {
        const date = new Date(params.value);
        return getFormattedDateWithMinute(date);
      },
      sortable: true,
      disableReorder: true,
      disableExport: true,
    },
    {
      field: COLUMN_LABEL.STATUS,
      headerName: intl.formatMessage({ id: 'mlModel.status' }),
      align: 'left',
      headerAlign: 'left',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
        return statusToDisplay(params.value, params.row.modelId);
      },
      renderHeader: () => (
        <Fragment>
          <div style={{ fontWeight: 500 }}>{intl.formatMessage({ id: 'mlModel.status' })}</div>
          <IconButton
            aria-describedby={idStatus}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorElStatus(event.currentTarget);
            }}
          >
            <FilterAltIcon></FilterAltIcon>
          </IconButton>
        </Fragment>
      ),
    },
    {
      field: COLUMN_LABEL.EFFECTIVE_DATE,
      headerName: intl.formatMessage({ id: 'mlModel.effectiveDate' }),
      editable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: params => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return getFormattedDateWithMinute(date);
      },
      sortable: true,
      disableReorder: true,
      disableExport: true,
    },
    {
      field: COLUMN_LABEL.DOWNLOAD,
      headerName: intl.formatMessage({ id: 'mlModel.download' }),
      align: 'center',
      headerAlign: 'center',
      description: intl.formatMessage({ id: 'mlModel.download' }),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: params => {
        return <ExportExcel fileName={params.row.modelId} />;
      },
    },
  ];

  const [anchorElStatus, setAnchorElStatus] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorElStatus);
  const idStatus = open ? 'simple-popover' : undefined;
  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };

  const filteredRows = site.mlModel
    .filter((row: MlModel) => {
      if (isStatusChecked.approved && isStatusChecked.toBeApproved) {
        return row.status === true || row.status === null;
      } else if (isStatusChecked.approved) {
        return row.status === true;
      } else if (isStatusChecked.toBeApproved) {
        return row.status === null;
      }
      return row;
    })
    .filter((row: MlModel) => {
      if (selectedModelType === MODEL_TYPE.POWER) {
        return row.modelType === MODEL_TYPE.POWER;
      }
      if (selectedModelType === PAGE.MAX_EFFICIENCY) {
        return row.modelType === MODEL_TYPE.MAX_EFFICIENCY;
      }
      if (selectedModelType === PAGE.MIN_EFFICIENCY) {
        return row.modelType === MODEL_TYPE.MIN_EFFICIENCY;
      }
      return site.mlModel;
    });

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Popover
        id={idStatus}
        open={open}
        anchorEl={anchorElStatus}
        onClose={handleCloseStatus}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={styles.statusFilterContainer}>
          <FormControlLabel
            className={styles.statusFilter}
            control={
              <Checkbox
                checked={isStatusChecked.approved}
                onChange={handleStatusChange}
                name="approved"
                color="primary"
              />
            }
            label={intl.formatMessage({ id: 'mlModel.approved' })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isStatusChecked.toBeApproved}
                onChange={handleStatusChange}
                name="toBeApproved"
                color="primary"
              />
            }
            label={intl.formatMessage({ id: 'mlModel.pending' })}
          />
        </div>
      </Popover>
      <StripedDataGrid
        disableColumnMenu
        disableColumnFilter
        disableRowSelectionOnClick
        sortingOrder={['desc', 'asc']}
        initialState={{
          sorting: {
            sortModel: [
              { field: COLUMN_LABEL.CREATION_DATE, sort: 'desc' },
              { field: COLUMN_LABEL.EFFECTIVE_DATE, sort: 'desc' },
            ],
          },
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        slotProps={{
          panel: {
            sx: {
              top: '-60px !important',
              minWidth: '650px !important',
            },
          },
        }}
        sx={{
          borderRadius: '16px',
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
        }}
        rows={filteredRows}
        columns={columns}
        getRowId={row => row.modelId}
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        pageSizeOptions={[10]}
      />
    </Box>
  );
};

export default MlModelTableComponent;
