export const parameterNameMapping = {
  ambient_temperature: 'Ambient Temperature',
  ambient_pressure: 'Ambient Pressure',
  relative_humidity: 'Relative Humidity',
  network_frequency: 'Grid Frequency',
  lower_heating_value_and_c_h_ratio: 'Lower Heating Value and C/H',
  excess_gt_inlet_pressure_drop: 'Excess GT inlet Pressure Drop',
  anti_icing_dew_point_temperature: 'Anti-icing Dew Point Temperature',
  anti_icing_fixed_temperature_relative_humidity:
    'Anti-icing Fixed Temperature - Relative Humidity',
  degradation: 'Degradation',
  sulfur_content: 'Sulfur Content',
  gt_exhaust_pressure_drop: 'GT Exhaust Pressure Drop',
  wind_speed: 'Wind Speed',
  gas_temperature: 'GT Fuel Gas Inlet Temperature',
  fogging: 'Fogging',
  load_limit: 'Load Limit',
};

export const referencesMapping = {
  ambient_temperature: 'Ambient Temperature',
  ambient_pressure: 'Ambient Pressure',
  relative_humidity: 'Relative Humidity',
  network_frequency: 'Grid Frequency',
  lower_heating_value: 'Lower Heating Value',
  c_h_ratio: 'C/H Ratio',
  gas_turbine_exhaust_pressure: 'GT Inlet Pressure Drop',
  inlet_temperature_increase: 'Delta Inlet Temperature Increase',
  fixed_set_point_temperature: 'Fixed Set Point Temperature',
  threshold_temperature: 'Threshold Temperature',
  threshold_relative_humidity: 'Threshold Relative Humidity',
  set_point_temperature: 'Set Point Temperature',
  EOH: 'EOH',
  sulfur_content: 'Sulfur Content',
  wind_speed: 'Wind Speed',
  gas_temperature: 'GT Fuel Gas Inlet Temperature',
  gt_power_limit: 'GT Gross Power Limit',
  ccgt_power_limit: 'CCGT Net Power Limit',
};
