import { addDays } from 'date-fns';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Roles } from '../../../../../corelogic/domain/usecases/site/adminRight/adminRight.usecase';
import { setRetrieveAdminRight } from '../../../../../corelogic/redux/adminRight/adminRight.slice';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setIsAdmin } from '../../../../../corelogic/redux/isAdmin/is-admin.slice';
import { setRetrieveKpiImbalance } from '../../../../../corelogic/redux/kpiImbalance/kpi-imbalance.slice';
import { setRetrieveSite } from '../../../../../corelogic/redux/site/site.slice';
import { Spinner } from '../../../../../ui/spinner/spinner.component';
import { NUMBER_OF_DAYS } from '../../components/graph/timeseriesGraph/constant';
import Header from '../../components/header/headerPage';

type Props = { language: string; setLanguage: (language: string) => void };

const SiteInformation: FC<Props> = ({ language, setLanguage }): JSX.Element => {
  const { site, isLoading } = useSelector((state: RootState) => state.site);
  const { startDate } = useSelector((state: RootState) => state.startDate);

  const { admin } = useSelector((state: RootState) => state.admin);
  const { isAdmin } = useSelector((state: RootState) => state.isAdmin);

  useEffect(() => {
    if (isAdmin === undefined && admin.length > 0) {
      if (admin.some(adminRole => adminRole === Roles.admin || adminRole === Roles.superAdmin)) {
        dispatch(setIsAdmin(true));
      } else {
        dispatch(setIsAdmin(false));
      }
    }
  }, [admin]);

  const dispatch = useDispatch();

  useEffect(() => {
    const storedSiteId = localStorage.getItem('favouriteSite');

    if (storedSiteId && storedSiteId !== null) {
      dispatch(setRetrieveAdminRight(storedSiteId));
      dispatch(setRetrieveSite(storedSiteId));
      dispatch(
        setRetrieveKpiImbalance({
          siteId: storedSiteId,
          startDate: startDate,
          endDate: addDays(new Date(startDate), NUMBER_OF_DAYS).toISOString(),
        }),
      );
    }
  }, [dispatch]);

  if (isLoading || site === undefined) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Header language={language} setLanguage={setLanguage} />
    </div>
  );
};

export default SiteInformation;
