import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CardContent, IconButton, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { FC, Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { METROSCOPE_PATH } from '../../../../../constants/path';
import { Failure } from '../../../../../corelogic/domain/models/failures/failures.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setUpdateSiteParameter } from '../../../../../corelogic/redux/site/site.slice';
import { getFormattedDateWithMinute } from '../../../../../helpers/dateFunction';
import { CustomButton, CustomCard, StripedDataGrid } from '../../../../../util/functionUtil';
import FailuresPopin from '../failuresPopin/failuresPopin';
import styles from './failures.module.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { GridColDef } from '@mui/x-data-grid';

const Failures: FC = () => {
  const { site } = useSelector((state: RootState) => state.site);
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const { isAdmin } = useSelector((state: RootState) => state.isAdmin);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isNewFailure, setIsNewFailure] = useState(true);
  const [selectedFailure, setSelectedFailure] = useState<Failure>();

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleOpen = (failure: Failure) => {
    setSelectedFailure(failure);
    setIsNewFailure(false);
    setOpen(true);
  };

  const handleNew = () => {
    setIsNewFailure(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getImpact(): number {
    let impact = 0;
    site.failures.forEach(e => {
      if (e.endDate) return;
      if (new Date(e.startDate).getTime() > Date.now()) return;
      impact -= e.impactPower;
    });
    return impact;
  }

  const handleDelete = (failure: Failure) => {
    const updatedFailures = site.failures.filter(x => x !== failure);
    const updateBodyFailures = { failures: updatedFailures };
    dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: updateBodyFailures }));
  };

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: intl.formatMessage({ id: 'failures.description' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'startDate',
      headerName: intl.formatMessage({ id: 'failures.start' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: params => {
        const date = new Date(params.value);
        return params.value ? getFormattedDateWithMinute(date) : '';
      },
    },
    {
      field: 'endDate',
      headerName: intl.formatMessage({ id: 'failures.end' }),
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      flex: 1.5,
      renderCell: params => {
        const date = new Date(params.value);
        return params.value ? getFormattedDateWithMinute(date) : '';
      },
    },
    {
      field: 'impactPower',
      headerName: intl.formatMessage({ id: 'failures.impactPower' }),
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'impactHeat',
      headerName: intl.formatMessage({ id: 'failures.impactHeat' }),
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'action',
      headerName: intl.formatMessage({ id: 'failures.action' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: param => {
        if (isAdmin)
          return (
            <Fragment key={param.row.description + param.row.startDate}>
              <div className={styles.actionContainer}>
                <IconButton color="actionButton" onClick={() => handleOpen(param.row)}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  color="actionButton"
                  onClick={() => {
                    if (confirm(intl.formatMessage({ id: 'home.deletionConfirmation' }))) {
                      handleDelete(param.row);
                    }
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>
            </Fragment>
          );
      },
    },
  ];

  return (
    <div className={clsx(styles.failurePageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined">
        <CardContent className={styles.parent_gap_container}>
          <div className={styles.title}>
            <h1>
              <FormattedMessage id="failures.title" />
            </h1>
            <div>
              <a
                className={'link-primary me-4 ' + styles.metroscope_container}
                target="_blank"
                rel="noopener noreferrer"
                href={METROSCOPE_PATH}
              >
                <span
                  style={{ color: theme.palette.primary.main }}
                  className="material-icons-outlined btn-primary"
                >
                  exit_to_app
                </span>
                <div
                  style={{ color: theme.palette.primary.main }}
                  className={styles.link_metroscope}
                >
                  <FormattedMessage id="failures.link" />
                </div>
              </a>
            </div>
          </div>
          <div className={styles.impact_container}>
            <CustomCard sx={{ padding: '32px', boxShadow: 'none' }}>
              <h4>
                <FormattedMessage id="failures.impact" />
              </h4>
              <div className={styles.impact_value_container}>
                <h1 className={styles.impact_value}>{getImpact()}</h1>
                <div>
                  <FormattedMessage id="unit.mw" />
                </div>
              </div>
            </CustomCard>

            <div className={styles.tip}>
              <Typography variant="h6" textAlign={'left'}>
                <FormattedMessage id="failures.tipTitle" />
              </Typography>
              <Typography variant="body1" textAlign={'left'}>
                <FormattedMessage id="failures.tip" values={{ br: <br /> }} />
              </Typography>
            </div>
          </div>
          <div className={styles.report_container}>
            {isAdmin && (
              <CustomButton
                variant="contained"
                onClick={() => handleNew()}
                disabled={site.name === ''}
                startIcon={<AddCircleOutlineIcon />}
              >
                <FormattedMessage id="failures.declare" />
              </CustomButton>
            )}
            {open && (
              <FailuresPopin
                isNew={isNewFailure}
                failure={selectedFailure}
                isOpen={open}
                handleClose={handleClose}
              />
            )}
          </div>
          <StripedDataGrid
            disableColumnMenu
            disableColumnFilter
            disableRowSelectionOnClick
            sortingOrder={['desc', 'asc']}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: {
                sortModel: [{ field: 'endDate', sort: 'asc' }],
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: '-60px !important',
                  minWidth: '650px !important',
                },
              },
            }}
            sx={{
              borderRadius: '16px',
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
            }}
            rows={site.failures}
            columns={columns}
            getRowId={row => row.description + row.startDate}
            getRowClassName={params =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            pageSizeOptions={[10]}
          />
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default Failures;
