import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { axiosP2MSchematicApiConfiguration } from '../../../configurations/axios.configuration';
import { SiteNamesIdsGateway } from '../../../corelogic/domain/gateways/site-names-ids.gateway';
import { SiteNameIdDto } from '../../../corelogic/domain/models/site/siteNameId.dto';
import { acquireAccessToken } from '../providers/authentication/tokenProvider';

const axiosClient: AxiosInstance = axios.create(axiosP2MSchematicApiConfiguration);
export class ApiSiteNamesIdsGateway implements SiteNamesIdsGateway {
  retrieveSitesNameId = async (): Promise<SiteNameIdDto[]> => {
    const response: AxiosResponse = await axiosClient.get<SiteNameIdDto[]>(`/sites`, {
      ...axiosP2MSchematicApiConfiguration,
      headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
    });
    return response.data;
  };
}
