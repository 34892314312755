import { Data } from 'plotly.js';
import Plot from 'react-plotly.js';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { Spinner } from '../../../../../../ui/spinner/spinner.component';
import { MarginGraph } from '../../type';
import styles from './constructor-curve-graph.module.scss';
import { useTheme } from '@mui/material';

type Props = {
  width: number;
  height: number;
  margin: MarginGraph;
  xAxis: { minXaxisValue: number; maxXaxisValue: number };
  yAxis?: { minXaxisValue: number; maxXaxisValue: number };
  dataPlotly: Data[];
  xAxisName: string;
  yAxisName: string;
};

const CurveGraph: React.FC<Props> = ({
  width,
  height,
  margin,
  xAxis,
  yAxis,
  dataPlotly,
  xAxisName,
  yAxisName,
}) => {
  const { isLoading } = useSelector((state: RootState) => state.site);
  const theme = useTheme();

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.curveContainer}>
        <Plot
          config={{ displaylogo: false, responsive: true }}
          data={dataPlotly}
          layout={{
            legend: {
              x: 1.03,
              y: 1,
            },
            height: height,
            width: width,
            font: {
              family: 'Roboto',
              size: 12,
              color: '#222B2D',
            },
            plot_bgcolor: theme.palette.background.default,
            paper_bgcolor: theme.palette.backgroundLayer1.main,
            xaxis: {
              title: {
                text: xAxisName,
                font: { size: 14, color: '#8C9294' },
              },
              showgrid: true,
              rangemode: 'normal',
              gridwidth: 1,
              zerolinecolor: '#8C9294',
              zerolinewidth: 2,
              linecolor: '#D0D3D5',
              linewidth: 1,
              mirror: true,
              range: [yAxis?.minXaxisValue, yAxis?.maxXaxisValue],
              color: theme.palette.text.primary,
            },
            xaxis2: {
              showgrid: true,
              rangemode: 'normal',
              gridwidth: 1,
              zerolinecolor: '#8C9294',
              zerolinewidth: 2,
              linecolor: '#D0D3D5',
              linewidth: 1,
              mirror: true,
              color: theme.palette.text.primary,
            },
            yaxis: {
              title: { text: yAxisName, font: { size: 14, color: '#8C9294' }, standoff: 20 },
              showgrid: true,
              zerolinecolor: '#8C9294',
              zerolinewidth: 2,
              gridwidth: 1,
              linecolor: '#D0D3D5',
              linewidth: 1,
              mirror: true,
              color: theme.palette.text.primary,
            },
            margin: margin,
            autosize: true,
            shapes: [
              {
                type: 'line',
                x0: xAxis.minXaxisValue,
                y0: 1,
                x1: xAxis.maxXaxisValue,
                y1: 1,
                line: {
                  color: '#8C9294',
                  width: 2,
                  dash: 'solid',
                },
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default CurveGraph;
