import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { pca } from '../../../..';
import { msalConfig } from '../../../../configurations/msal-client.configuration';

export const acquireAccessToken = async () => {
  const activeAccount = pca.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = pca.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }
  const request = {
    scopes: msalConfig.scopes,
    account: activeAccount ?? accounts[0],
  };

  const authResult = await pca.acquireTokenSilent(request).catch(async () => {
    pca.acquireTokenRedirect(request);
  });
  if (authResult instanceof InteractionRequiredAuthError) {
    return await pca.acquireTokenRedirect(request);
  }
  return Promise.resolve(authResult?.accessToken);
};
