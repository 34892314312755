import { ForecastInformationDTO } from '../../../domain/models/overview/forecastInformation.dto';

export interface ForecastInformationState {
  forecastInformation: ForecastInformationDTO[];
  isLoading: boolean;
  error?: string;
}

export const initialForecastInformationState: ForecastInformationState = {
  forecastInformation: [],
  isLoading: false,
};
