import { KpiImbalance } from '../../domain/models/kpiImbalance/kpiImbalance.model';
import { PayloadAction } from '@reduxjs/toolkit';
import { KpiImbalanceState } from '../states/kpiImbalance/kpiImbalance.state';
import { NUMBER_OF_DAYS } from '../../../adapters/primaries/react/components/graph/timeseriesGraph/constant';

export const setRetrieveKpiImbalanceReducer = (
  state: KpiImbalanceState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<{ siteId: string; startDate: string; endDate: string; chunk?: number }>,
) => {
  state.isLoading = true;
};

export const setRetrieveKpiImbalanceSuccessReducer = (
  state: KpiImbalanceState,
  action: PayloadAction<KpiImbalance[]>,
) => {
  action.payload.forEach(kpi => state.kpiImbalance.push(kpi));
  state.kpiImbalance.sort(
    (kpiA: KpiImbalance, kpiB: KpiImbalance) =>
      new Date(kpiA.timestamp).getTime() - new Date(kpiB.timestamp).getTime(),
  );
  state.progress += (1 / NUMBER_OF_DAYS) * 100;
};

export const setRetrieveKpiImbalanceFailureReducer = (
  state: KpiImbalanceState,
  action: PayloadAction<string>,
) => {
  state.error = action.payload;
  state.progress += (1 / NUMBER_OF_DAYS) * 100;
};

export const setLoadedKpiImbalanceReducer = (state: KpiImbalanceState) => {
  state.isLoading = false;
  state.progress = 0;
};

export const setLoadingKpiImbalanceReducer = (state: KpiImbalanceState) => {
  state.progress = 0;
  state.isLoading = true;
};

export const setResetKpiImbalanceReducer = (state: KpiImbalanceState) => {
  state.kpiImbalance = [];
  state.isLoading = false;
  state.progress = 0;
};
