import { AdminRightState } from './../states/adminRight/adminRight.state';
import { PayloadAction } from '@reduxjs/toolkit';

export const setRetrieveAdminRightReducer = (
  state: AdminRightState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<string>,
) => {
  state.isLoading = true;
};

export const setRetrieveAdminRightSuccessReducer = (
  state: AdminRightState,
  action: PayloadAction<string[]>,
) => {
  state.isLoading = false;
  state.admin = action.payload;
};

export const setRetrieveAdminRightFailureReducer = (
  state: AdminRightState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
  state.admin = [];
};
