import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { axiosP2MSchematicApiConfiguration } from '../../../configurations/axios.configuration';
import { PmdKpiMeanGateway } from '../../../corelogic/domain/gateways/pmd-kpi-mean.gateways';
import { PmdKpiMeanDto } from '../../../corelogic/domain/models/pmdKpiMean/pmdKpiMeanDto.model';
import { acquireAccessToken } from '../providers/authentication/tokenProvider';

const axiosClient: AxiosInstance = axios.create(axiosP2MSchematicApiConfiguration);
export class ApiPmdKpiMeanGateway implements PmdKpiMeanGateway {
  retrieveLast10Pmd = async (siteId: string): Promise<PmdKpiMeanDto[]> => {
    const response: AxiosResponse = await axiosClient.get<PmdKpiMeanDto[]>(
      `/pmdKpiMean/${siteId}`,
      {
        ...axiosP2MSchematicApiConfiguration,
        headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
      },
    );
    return response.data;
  };
}
