import { Button, useTheme } from '@mui/material';
import { FC } from 'react';
import styles from './tabComponent.module.scss';

type Props = {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  fieldInTab: Record<string, string>;
  buttonMessage: string[];
};

const TabComponent: FC<Props> = ({ selectedTab, setSelectedTab, fieldInTab, buttonMessage }) => {
  const theme = useTheme();

  return (
    <div
      className={styles.boxContainer}
      style={{ backgroundColor: theme.palette.backgroundLayer1active.main }}
    >
      {buttonMessage.map(message => (
        <Button
          key={message}
          size="large"
          variant="text"
          className={styles.smallBoxContainer}
          onClick={() => setSelectedTab(message)}
          sx={{ margin: '4px 4px 4px 4px' }}
          style={
            selectedTab === message
              ? {
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.primary.main,
                  textTransform: 'none',
                  fontWeight: 'bold',
                }
              : {
                  color: theme.palette.text.primary,
                  textTransform: 'none',
                  fontWeight: 'bold',
                }
          }
          onKeyDown={() => setSelectedTab(message)}
        >
          <div>{fieldInTab[message]}</div>
        </Button>
      ))}
    </div>
  );
};

export default TabComponent;
