import { PayloadAction } from '@reduxjs/toolkit';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import {
  setRetrieveAdminRightFailure,
  setRetrieveAdminRightSuccess,
} from '../../../../redux/adminRight/adminRight.slice';
import { RootState } from '../../../../redux/create-store';

export enum Roles {
  admin = 'admin',
  superAdmin = 'superAdmin',
  user = 'user',
}

export const RetrieveAdminRightEpic = (
  action$: Observable<PayloadAction<string>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('adminRight/setRetrieveAdminRight'),
    switchMap(action => {
      return from(dependencies.siteGateway.retrieveAdminRight(action.payload)).pipe(
        map((roles: string[]) => setRetrieveAdminRightSuccess(roles)),
        catchError(() =>
          of(setRetrieveAdminRightFailure(`Retrieving site gain ${action.payload} has failed`)),
        ),
      );
    }),
  );
