import { PmdKpiMeanDto } from '../../../domain/models/pmdKpiMean/pmdKpiMeanDto.model';

export interface PmdKpiMeanState {
  pmdKpiMeanDto: PmdKpiMeanDto[];
  isLoadingPmdKpiMean: boolean;
  error?: string;
}

export const initialPmdKpiMeanState: PmdKpiMeanState = {
  isLoadingPmdKpiMean: false,
  pmdKpiMeanDto: [],
};
