import '@totalenergiescode/design-system/dist/css/custom-components/upload-files.css';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import { SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Curve } from '../../../../../corelogic/domain/models/curve/curve.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { ErrorByCurve } from '../type';
import { readExcelFile } from '../uploadPopinFiles/function.util';
import styles from './uploadCurve.module.scss';
import { VisuallyHiddenInput } from '../../../../../util/functionUtil';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button } from '@mui/material';
type Props = {
  isCurveAlreadyDefined?: boolean;
  setNewCurvesData: React.Dispatch<SetStateAction<Curve[]>>;
  curveName: string;
  newCurvesData: Curve[];
  setErrorByCurve: React.Dispatch<React.SetStateAction<ErrorByCurve[]>>;
  errorByCurve: ErrorByCurve[];
};

const UploadCurve: React.FC<Props> = ({
  isCurveAlreadyDefined,
  setNewCurvesData,
  curveName,
  newCurvesData,
  setErrorByCurve,
  errorByCurve,
}) => {
  const intl = useIntl();
  const [fileName, setFileName] = useState<string>();
  const { parameterName } = useSelector((state: RootState) => state.currentParameter);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    curveName: string,
  ) => {
    const file = event.target.files?.[0];
    if (file !== null) {
      setFileName(file?.name);
    }
    if (file && parameterName !== undefined) {
      try {
        await readExcelFile(file, intl, parameterName)
          .then(({ x1, x2, y }) => {
            const newCurve: Curve = {
              name: curveName,
              last_update: new Date().toISOString(),
              data: { x1, x2, y },
            };
            manageCurveData(newCurve);
          })
          .catch((error: Error) => {
            manageErrorCurve(error);
          });
      } catch (error) {
        throw Error('Impossible to handle this file');
      }
    }
  };

  const manageCurveData = (newCurve: Curve) => {
    const indexCurve: number = newCurvesData.findIndex(curve => curve.name === curveName);
    if (indexCurve === -1) {
      const updatedCurvesData = [...newCurvesData, newCurve];
      setNewCurvesData(updatedCurvesData);
    } else {
      const updatedCurvesData: Curve[] = [...newCurvesData];
      updatedCurvesData[indexCurve] = newCurve;
      setNewCurvesData(updatedCurvesData);
    }
    const indexError: number = errorByCurve.findIndex(
      (error: ErrorByCurve) => error.name === curveName,
    );
    if (indexError !== -1) {
      const updatededError: ErrorByCurve[] = [...errorByCurve];
      updatededError.splice(indexError, 1);
      setErrorByCurve(updatededError);
    }
  };

  const manageErrorCurve = (error: Error) => {
    const indexError: number = errorByCurve.findIndex(
      (error: ErrorByCurve) => error.name === curveName,
    );
    if (indexError === -1) {
      const updatededError: ErrorByCurve[] = [
        ...errorByCurve,
        { name: curveName, errorMessage: error },
      ];
      setErrorByCurve(updatededError);
    } else {
      const updatededError: ErrorByCurve[] = [...errorByCurve];
      updatededError[indexError] = { name: curveName, errorMessage: error };
      setErrorByCurve(updatededError);
    }
  };

  const handleDeleteClick = async () => {
    if (!confirm(intl.formatMessage({ id: 'home.deletionConfirmation' }))) return;
    const deletedCurveData = {
      name: curveName,
      last_update: new Date().toISOString(),
      data: { x1: [], y: [] },
    };

    const indexCurve: number = newCurvesData.findIndex(curve => curve.name === curveName);
    if (indexCurve === -1) {
      const updatedCurvesData: Curve[] = [...newCurvesData];
      updatedCurvesData.push(deletedCurveData);
      setNewCurvesData(updatedCurvesData);
    } else {
      const updatedCurvesData: Curve[] = [...newCurvesData];
      updatedCurvesData[indexCurve] = deletedCurveData;
      setNewCurvesData(updatedCurvesData);
    }
    setFileName('Deleted Curve');
  };

  return (
    <div className={styles.uploadFileAndMessageContainer}>
      <div className={styles.messageContainer}>
        {fileName ?? <FormattedMessage id="popin.Handledformat" />}
      </div>
      <div className={styles.uploadAndDeleteFileContainer}>
        <Button
          sx={{ borderRadius: '16px' }}
          startIcon={<DeleteOutlineOutlinedIcon />}
          component="label"
          variant="outlined"
        >
          <VisuallyHiddenInput
            type="file"
            accept=".xlsx, .xls"
            onChange={e => handleFileChange(e, curveName)}
          />
          {isCurveAlreadyDefined === true ? (
            <FormattedMessage id="popin.changeFile" />
          ) : (
            <FormattedMessage id="popin.uploadFile" />
          )}
        </Button>
        <Button sx={{ borderRadius: '16px' }} variant="text" onClick={handleDeleteClick}>
          <FormattedMessage id="popin.deleteFile" />
        </Button>
      </div>
    </div>
  );
};

export default UploadCurve;
