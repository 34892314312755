import en from '../../../../translation/en.json';
import es from '../../../../translation/es.json';
import fr from '../../../../translation/fr.json';
export interface HiddenCurveState {
  hiddenCurveList: string[];
  isLoading: boolean;
  error?: string;
}

export const initialHiddenCurvesState: HiddenCurveState = {
  hiddenCurveList: [
    en.generalDashboard.minEfficiencyOEM,
    en.generalDashboard.maxEfficiencyOEM,
    en.generalDashboard.maxEfficiencyForecast,
    en.generalDashboard.minEfficiencyForecast,
    en.generalDashboard.minEfficiencyModel,
    en.generalDashboard.maxEfficiencyModel,

    es.generalDashboard.minEfficiencyOEM,
    es.generalDashboard.maxEfficiencyOEM,
    es.generalDashboard.maxEfficiencyForecast,
    es.generalDashboard.minEfficiencyForecast,
    es.generalDashboard.minEfficiencyModel,
    es.generalDashboard.maxEfficiencyModel,

    fr.generalDashboard.minEfficiencyOEM,
    fr.generalDashboard.maxEfficiencyOEM,
    fr.generalDashboard.maxEfficiencyForecast,
    fr.generalDashboard.minEfficiencyForecast,
    fr.generalDashboard.minEfficiencyModel,
    fr.generalDashboard.maxEfficiencyModel,
  ],
  isLoading: false,
};
