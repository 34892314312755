import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { CustomCard } from '../../../../../../util/functionUtil';
import DropDownKpiCurveNameList from '../../dropdown/dropDownProductionKpiCurveName/dropdown-productionKpiCurveName-list';
import ForecastImbalance from '../../generalDashboard/component/power/imbalance/ForecastImbalance/forecastImbalance';
import Imbalance from '../../generalDashboard/component/power/imbalance/Imbalance/imbalance';
import {
  kpiForecastPmdPlotlyDataBuilder,
  kpiProductionPmdPlotlyDataBuilder,
} from '../../graph/kpiGraph/function-kpi-graph';
import KpiGraph from '../../graph/kpiGraph/kpiGraph';
import {
  dispatchDashboardPlotlyDataBuilder,
  generalDashboardPlotlyDataBuilder,
} from '../../graph/timeseriesGraph/function-timeseries-graph';
import TimeseriesGraph from '../../graph/timeseriesGraph/timeseriesGraph';
import { EfficiencyData, MarginGraph, PAGE } from '../../type';
import styles from './cardComponent.module.scss';

type Props = {
  title?: string;
  isImbalance?: boolean;
  isCurves?: boolean;
  isEfficiency: boolean;
  efficiencyData?: EfficiencyData;
  isDispatch?: true;
};

const CardComponent: React.FC<Props> = ({
  title,
  isImbalance,
  isCurves,
  isEfficiency,
  efficiencyData,
  isDispatch,
}) => {
  const { isLoading, kpiImbalance } = useSelector((state: RootState) => state.kpiImbalance);
  const { forecastInformation } = useSelector((state: RootState) => state.forecastInformation);
  const { pmdKpiMeanDto } = useSelector((state: RootState) => state.pmdKpiMeanDto);
  const { productionkpiCurveName } = useSelector(
    (state: RootState) => state.currentProductionKpiCurveName,
  );
  const theme = useTheme();

  const intl = useIntl();

  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const { graphIndex } = useSelector((state: RootState) => state.graphIndex);
  const { hiddenCurveList } = useSelector((state: RootState) => state.hiddenCurves);
  const curvesRef = useRef<HTMLDivElement>(null);
  const curveContainerRef = useRef<HTMLDivElement>(null);
  const [curvesWidth, setCurvesWidth] = useState(0);

  const [showLoader, setShowLoader] = useState<boolean>(isLoading);

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  let realProductionImbalance: number | null = 0;
  let productionImbalance: number | null = 0;
  let potentialPowerGainImbalance: number | null = 0;
  if (kpiImbalance.length > 0) {
    const currentKpiImbalance = kpiImbalance[graphIndex];
    if (isDispatch === true) {
      const { net_power_p2m_forecast, power_production_running_program } =
        currentKpiImbalance || {};
      const modelForecast = (net_power_p2m_forecast as number) || 0;
      const runningProgram = (power_production_running_program as number) || 0;
      potentialPowerGainImbalance = modelForecast - runningProgram;
      productionImbalance = currentKpiImbalance?.production_imbalance;
    } else if (isEfficiency === false) {
      realProductionImbalance = currentKpiImbalance?.real_production_imbalance;
      productionImbalance = currentKpiImbalance?.production_imbalance;
    } else {
      const { efficiency_imbalance, efficiency_min_imbalance } = currentKpiImbalance || {};
      productionImbalance =
        efficiencyData?.title === PAGE.MAX_EFFICIENCY
          ? efficiency_imbalance
          : efficiency_min_imbalance;
      realProductionImbalance = currentKpiImbalance?.real_efficiency_imbalance;
    }
  }

  let p2MProductionSubTitle = '';
  let realProductionSubTitle = '';

  if (isEfficiency === false) {
    p2MProductionSubTitle =
      intl.formatMessage({ id: 'generalDashboard.netActivePower' }) +
      ' - ' +
      intl.formatMessage({ id: 'generalDashboard.imbalances.modelForecast' });
    realProductionSubTitle =
      intl.formatMessage({ id: 'generalDashboard.netActivePower' }) +
      ' - ' +
      intl.formatMessage({ id: 'generalDashboard.runningProgram' });
  } else {
    p2MProductionSubTitle =
      intl.formatMessage({ id: 'generalDashboard.hhvEfficiency' }) +
      ' - ' +
      intl.formatMessage({ id: 'generalDashboard.imbalances.modelForecast' });
    realProductionSubTitle =
      intl.formatMessage({ id: 'generalDashboard.hhvEfficiency' }) +
      ' - ' +
      intl.formatMessage({ id: 'generalDashboard.runningProgram' });
  }

  const potentialPowerGainSubTitle =
    intl.formatMessage({ id: 'generalDashboard.imbalances.pmdModelForecast' }) +
    ' - ' +
    intl.formatMessage({ id: 'generalDashboard.runningProgram' });

  const imbalanceUnit =
    isEfficiency === false
      ? intl.formatMessage({ id: 'unit.mw' })
      : intl.formatMessage({ id: 'unit.pt%' });

  const handleResize = () => {
    if (curvesRef?.current?.clientWidth) {
      setCurvesWidth(curvesRef?.current?.clientWidth);
    }
  };

  useEffect(() => {
    handleResize();
  }, [curveContainerRef.current?.clientWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [menuStatus]);

  const marginGraphInCard: MarginGraph = {
    l: 90,
    r: 50,
    b: 70,
    t: 15,
    pad: 10,
  };

  return (
    <CustomCard boxShadow="unset" ref={curvesRef}>
      {title !== undefined && (
        <div className={styles.title}>
          <h3 className={styles.titleText}>
            <FormattedMessage id={title} />
          </h3>
        </div>
      )}
      {isImbalance && (
        <div className={styles.imbalancesAndGraph}>
          <div className={styles.imbalances}>
            <div className={styles.kpis}>
              {isDispatch === true ? (
                <Imbalance
                  title={intl.formatMessage({
                    id: 'generalDashboard.imbalances.potentialPowerGain',
                  })}
                  subTitle={potentialPowerGainSubTitle}
                  value={potentialPowerGainImbalance}
                  unit={imbalanceUnit}
                  showLoader={showLoader}
                />
              ) : (
                <Imbalance
                  title={intl.formatMessage({
                    id: 'generalDashboard.imbalances.realProductionImbalance',
                  })}
                  subTitle={realProductionSubTitle}
                  value={realProductionImbalance}
                  unit={imbalanceUnit}
                  showLoader={showLoader}
                />
              )}
              <Imbalance
                title={intl.formatMessage({
                  id: 'generalDashboard.imbalances.productionImbalance',
                })}
                subTitle={p2MProductionSubTitle}
                value={productionImbalance}
                unit={imbalanceUnit}
                showLoader={showLoader}
              />
            </div>
            <ForecastImbalance
              isEfficiency={isEfficiency}
              showLoader={showLoader}
              efficiencyCurveName={efficiencyData?.title}
            />
          </div>
          {isEfficiency === false && isDispatch !== true && (
            <Accordion
              square={true}
              sx={{
                boxShadow: 'unset',
                border: 'solid 1px #C3C5C5',
                borderRadius: '16px',
                '&:before': {
                  display: 'none',
                },
                overflow: 'hidden',
              }}
              classes={{ expanded: styles.accordionContainer }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={styles.titleKpi}>
                  <FormattedMessage id="generalDashboard.imbalances.kpiTrends" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: theme.palette.backgroundLayer1.main }}>
                <KpiGraph
                  windowSize={curvesWidth - 100}
                  dataPlotly={kpiProductionPmdPlotlyDataBuilder(pmdKpiMeanDto)}
                  xAxisName={intl.formatMessage({
                    id: 'generalDashboard.imbalances.eventStartDate',
                  })}
                  yAxisName={intl.formatMessage({
                    id: 'generalDashboard.imbalances.productionPMD',
                  })}
                  barmode={{ barmode: 'group' }}
                />
                <div className={styles.dropDownAndPlotContainer}>
                  <DropDownKpiCurveNameList />
                  <KpiGraph
                    windowSize={curvesWidth - 100}
                    dataPlotly={kpiForecastPmdPlotlyDataBuilder(
                      productionkpiCurveName,
                      pmdKpiMeanDto,
                    )}
                    xAxisName={intl.formatMessage({
                      id: 'generalDashboard.imbalances.eventStartDate',
                    })}
                    yAxisName={intl.formatMessage({
                      id: 'generalDashboard.imbalances.forecastPMD',
                    })}
                    barmode={{ barmode: 'relative' }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      )}
      {isCurves && (
        <TimeseriesGraph
          width={curvesWidth - 50}
          height={350}
          margin={marginGraphInCard}
          dataPlotly={
            isDispatch
              ? dispatchDashboardPlotlyDataBuilder(
                  kpiImbalance,
                  forecastInformation,
                  hiddenCurveList,
                )
              : generalDashboardPlotlyDataBuilder(
                  isEfficiency,
                  kpiImbalance,
                  hiddenCurveList,
                  efficiencyData,
                )
          }
          rangeSlider={isDispatch ?? false}
          min={efficiencyData?.min ?? false}
          yAxisName={
            isEfficiency
              ? intl.formatMessage({ id: 'generalDashboard.imbalances.efficiency' })
              : intl.formatMessage({ id: 'generalDashboard.imbalances.power' })
          }
          isEfficiency={isEfficiency ?? false}
          isDispatch={isDispatch ?? false}
        />
      )}
    </CustomCard>
  );
};

export default CardComponent;
