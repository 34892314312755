import { DailyCapacity } from '../../../domain/models/dailyCapacity/dailyCapacity.model';

export interface DailyCapacitiesState {
  dailyCapacities: DailyCapacity[] | null;
  isLoading: boolean;
  error?: string;
}

export const initialDailyCapacitiesState: DailyCapacitiesState = {
  dailyCapacities: null,
  isLoading: false,
};
