import { TYPE_CURVE_PARAMETER } from '../type';

export const ParameterNameVariableMapper = {
  ambient_temperature: {
    input_variable: ['x1: Delta Ambient temperature °C'],
    variable: TYPE_CURVE_PARAMETER.MonoVariable,
    x_axis_name: 'Delta Ambient temperature °C',
  },
  ambient_pressure: {
    input_variable: ['x1: Delta Ambient Pressure (mbar)'],
    variable: TYPE_CURVE_PARAMETER.MonoVariable,
    x_axis_name: 'Delta Ambient Pressure (mbar)',
  },
  relative_humidity: {
    input_variable: ['x1: Delta Relative Humidity (%)', 'x2: Ambient Temperature (°C)'],
    variable: TYPE_CURVE_PARAMETER.Bivariable,
    x_axis_name: 'Delta Relative Humidity (%)',
  },
  network_frequency: {
    input_variable: ['x1:Delta Network Frequency (%)', 'x2: Ambient Temperature (°C)'],
    variable: TYPE_CURVE_PARAMETER.Bivariable,
    x_axis_name: 'Delta Network Frequency (%)',
  },
  lower_heating_value_and_c_h_ratio: {
    input_variable: ['x1: Delta Lower Heating Value (kJ/kg)', 'x2: C/H ratio (ratio)'],
    variable: TYPE_CURVE_PARAMETER.Bivariable,
    x_axis_name: 'Delta Lower Heating Value (kJ/kg)',
  },
  excess_gt_inlet_pressure_drop: {
    input_variable: ['Delta GT Inlet Pressure Drop (mbar)'],
    variable: TYPE_CURVE_PARAMETER.MonoVariable,
    x_axis_name: 'Delta GT Inlet Pressure Drop (mbar)',
  },
  anti_icing_dew_point_temperature: {
    input_variable: ['x1: Delta Inlet Temperature Increase (°C)', 'x2: Ambient Temperature (°C)'],
    variable: TYPE_CURVE_PARAMETER.Bivariable,
    x_axis_name: 'Delta Inlet Temperature Increase (°C)',
  },
  anti_icing_fixed_temperature_relative_humidity: {
    input_variable: ['x1: Ambient Temperature (°C)', 'x2: Relative Humidity (%) '],
    variable: TYPE_CURVE_PARAMETER.Bivariable,
    x_axis_name: 'Ambient Temperature (°C)',
  },
  degradation: {
    input_variable: ['x1: Delta EOH (h)'],
    variable: TYPE_CURVE_PARAMETER.MonoVariable,
    x_axis_name: 'Delta EOH (h)',
  },
  sulfur_content: {
    input_variable: ['x1: Delta Sulfur Content (mg/Nm3)'],
    variable: TYPE_CURVE_PARAMETER.MonoVariable,
    x_axis_name: 'Delta Sulfur Content (mg/Nm3)',
  },
  gt_exhaust_pressure_drop: {
    input_variable: ['x1: Delta Exhaust Pressure Drop (mbar)'],
    variable: TYPE_CURVE_PARAMETER.MonoVariable,
    x_axis_name: 'Delta Exhaust Pressure Drop (mbar)',
  },
  wind_speed: {
    input_variable: ['x1: Delta Wind Speed (m/s)'],
    variable: TYPE_CURVE_PARAMETER.MonoVariable,
    x_axis_name: 'Delta Wind Speed (m/s)',
  },
  gas_temperature: {
    input_variable: ['x1: Delta GT fuel Gas Inlet Temperature (°C)'],
    variable: TYPE_CURVE_PARAMETER.MonoVariable,
    x_axis_name: 'Delta GT fuel Gas Inlet Temperature (°C)',
  },
  fogging: {
    input_variable: ['x1: Ambient Temperature (°C)'],
    variable: TYPE_CURVE_PARAMETER.Bivariable,
    x_axis_name: 'Ambient Temperature (°C)',
  },
  load_limit: {
    input_variable: ['x1: Ambient Temperature (°C)'],
    variable: TYPE_CURVE_PARAMETER.Bivariable,
    x_axis_name: 'Ambient Temperature (°C)',
  },
};
