import { FC } from 'react';
import './App.css';
import RoutesInitialization from './route';
import { useSelector } from 'react-redux';
import { RootState } from './corelogic/redux/create-store';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { lightTheme, darkTheme } from './theme';

export const App: FC = () => {
  const { isDarkMode } = useSelector((state: RootState) => state.isDarkMode);

  return (
    <ThemeProvider theme={!isDarkMode ? lightTheme : darkTheme}>
      <CssBaseline />
      <div data-testid="P2M-init-div" className="App">
        <RoutesInitialization />
      </div>
    </ThemeProvider>
  );
};

export default App;
