import { createSlice } from '@reduxjs/toolkit';
import {
  setResetForecastInformationReducer,
  setRetrieveForecastInformationFailureReducer,
  setRetrieveForecastInformationReducer,
  setRetrieveForecastInformationSuccessReducer,
} from './forecastInformation.reducer';
import { initialForecastInformationState } from '../states/forecastInformation/forecastInformation.state';

export const forecastInformationSlice = createSlice({
  name: 'forecastInformation',
  initialState: initialForecastInformationState,
  reducers: {
    setRetrieveForecastInformation: setRetrieveForecastInformationReducer,
    setRetrieveForecastInformationSuccess: setRetrieveForecastInformationSuccessReducer,
    setRetrieveForecastInformationFailure: setRetrieveForecastInformationFailureReducer,
    setResetForecastInformation: setResetForecastInformationReducer,
  },
});

export const {
  setRetrieveForecastInformation,
  setRetrieveForecastInformationSuccess,
  setRetrieveForecastInformationFailure,
  setResetForecastInformation,
} = forecastInformationSlice.actions;

export const forecastInformationReducers = forecastInformationSlice.reducer;
