import { FC, useState } from 'react';

import { Grid } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setIsUpdatedSiteName } from '../../../../../corelogic/redux/updatedSiteNameBoolean/is-updated-site-name.slice';
import { getFormattedDate } from '../../../../../helpers/dateFunction';
import { CustomButton, isNullOrUndefined } from '../../../../../util/functionUtil';
import PopinIdentity from '../popinIdentity/popinIdentity';
import styles from './identitySite.module.scss';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

const IdentitySite: FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { site } = useSelector((state: RootState) => state.site);
  const { isAdmin } = useSelector((state: RootState) => state.isAdmin);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    dispatch(setIsUpdatedSiteName({ errorTitleMessage: '', isUpdatedSiteName: false }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePmin = () => {
    if (isNullOrUndefined(site.identity.pMin) && isNullOrUndefined(site.identity.pMinPercentage)) {
      return '-';
    }
    if (isNullOrUndefined(site.identity.pMin)) {
      return site.identity.pMinPercentage + ' %';
    } else {
      return site.identity.pMin + ' ' + intl.formatMessage({ id: 'unit.mw' });
    }
  };

  return (
    <div className={styles.parameterCardAndGraphContainer}>
      <div className={styles.identityContainer}>
        <div className={styles.textAndButtonContainer}>
          <div className={styles.iconAndTextContainer}>
            <AccountBoxOutlinedIcon color="primary" />
            <h3 className={clsx('text-start', styles.subTitle)}>
              <FormattedMessage id="identity.title" />
            </h3>
            <h4 className={clsx('text-start', styles.manufacturerSubTitle)}>
              {site.identity.manufacturerName === '' || site.identity.manufacturerName === null
                ? '-'
                : site.identity.manufacturerName}
            </h4>
          </div>
          {isAdmin && (
            <CustomButton
              type="button"
              variant="outlined"
              size="small"
              className={styles.buttonContainer}
              onClick={handleOpen}
              disabled={site.name === ''}
            >
              <FormattedMessage id="button.editSiteIdentity" />
            </CustomButton>
          )}
          {open === true && <PopinIdentity isOpen={open} handleClose={handleClose} />}
        </div>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid item xs={3}>
            <div>
              <h3 className={clsx('text-start', styles.title)}>
                <FormattedMessage id="identity.powerOutput" />
              </h3>
              <div className={styles.value}>
                {site.identity.powerOutput ?? '-'} <FormattedMessage id="unit.mw" />
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <h3 className={clsx('text-start', styles.title)}>
                <FormattedMessage id="identity.heatRate" />
              </h3>
              <div className={styles.value}>
                {site.identity.heatRate ?? '-'} <FormattedMessage id="unit.kjkwh" />
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <h3 className={clsx('text-start', styles.title)}>
                <FormattedMessage id="identity.netHeatRate" />
              </h3>
              <div className={styles.value}>
                {site.identity.netHeatRate ?? '-'} <FormattedMessage id="unit.kjkwh" />
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div>
              <h3 className={clsx('text-start', styles.title)}>
                <FormattedMessage id="identity.igvPosition" />
              </h3>
              <div className={styles.value}>{site.identity.igvPosition ?? '-'} %</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <h3 className={clsx('text-start', styles.title)}>
                {site.identity.pMin !== null && <FormattedMessage id="identity.pmin" />}
                {site.identity.pMin === null && <FormattedMessage id="identity.pminVariable" />}
              </h3>
              <div className={styles.value}>{handlePmin()}</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <h3 className={clsx('text-start', styles.title)}>
                <FormattedMessage id="identity.lastRevisionDate" />
              </h3>
              <div className={styles.value}>
                {site.identity.lastRevisionDate === '' || site.identity.lastRevisionDate === null
                  ? '-'
                  : getFormattedDate(new Date(site.identity.lastRevisionDate))}
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div>
              <h3 className={clsx('text-start', styles.title)}>
                <FormattedMessage id="identity.lastMajorMaintenance" />
              </h3>
              <div className={styles.value}>
                {site.identity.lastMajorMaintenance === '' ||
                site.identity.lastMajorMaintenance === null
                  ? '-'
                  : getFormattedDate(new Date(site.identity.lastMajorMaintenance))}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default IdentitySite;
