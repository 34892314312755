/* eslint-disable prettier/prettier */
import { MarginGraph } from '../type';

export const marginGraphInPopin: MarginGraph = {
    l: 70,
    r: 40,
    b: 50,
    t: 15,
    pad: 10,
};


