import { IconButton, Typography, useTheme } from '@mui/material';
import { addDays, addHours, differenceInHours, startOfDay, subDays, subHours } from 'date-fns';
import {
  Data,
  LegendClickEvent,
  PlotMouseEvent,
  PlotRelayoutEvent,
  RangeSelector,
  Shape,
} from 'plotly.js';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Plot from 'react-plotly.js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { setGraphIndex } from '../../../../../../corelogic/redux/currentTimeseries/graphIndex/graphDate/graphIndex.slice';
import { setHiddenCurves } from '../../../../../../corelogic/redux/hiddenCurve/hiddenCurve.slice';
import { setRetrieveYearOfCurrentGain } from '../../../../../../corelogic/redux/yearOfCurrentGain/yearOfCurrentGain.slice';
import { getDateWithCurrentTimezone } from '../../../../../../helpers/dateFunction';
import CustomDatePicker from '../../customDatePicker/customDatePicker';
import { MarginGraph } from '../../type';
import { MAX_EFFICIENCY, MAX_POWER, MIN_POWER, NUMBER_OF_DAYS } from './constant';
import styles from './timeseriesGraph.module.scss';
import { CircularProgressWithLabel } from '../../circularProgressWithLabel/circularProgressWithLabel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { setStartDate } from '../../../../../../corelogic/redux/currentTimeseries/startDate/startDate.slice';
import { setRetrieveKpiImbalance } from '../../../../../../corelogic/redux/kpiImbalance/kpi-imbalance.slice';

type Props = {
  width: number;
  height: number;
  margin: MarginGraph;
  dataPlotly: Data[];
  yAxisName: string;
  isEfficiency: boolean;
  min: boolean;
  rangeSlider: boolean;
  isDispatch: boolean;
};

const selectorOptions: Partial<RangeSelector> = {
  buttons: [
    {
      step: 'day',
      stepmode: 'backward',
      count: 1,
      label: '1 day',
    },
    {
      step: 'hour',
      stepmode: 'backward',
      count: 12,
      label: '-6h',
    },
    {
      step: 'hour',
      stepmode: 'todate',
      count: 18,
      label: '-12h',
    },
  ],
};

const TimeseriesGraph: React.FC<Props> = ({
  width,
  height,
  margin,
  dataPlotly,
  yAxisName,
  isEfficiency,
  min,
  isDispatch,
}) => {
  const { isLoading, kpiImbalance, progress } = useSelector(
    (state: RootState) => state.kpiImbalance,
  );
  const { startDate } = useSelector((state: RootState) => state.startDate);
  const { graphIndex } = useSelector((state: RootState) => state.graphIndex);
  const { hiddenCurveList } = useSelector((state: RootState) => state.hiddenCurves);
  const siteId = localStorage.getItem('favouriteSite');

  const gap = useRef(168);
  const startZoom = useRef<Date | undefined>(undefined);

  const rectangles: Partial<Shape>[] = [];
  const theme = useTheme();
  const intl = useIntl();

  let pmdPresent = false;
  let pminPresent = false;

  let timeMin: Date | undefined = undefined;
  let timeMax: Date | undefined = undefined;
  kpiImbalance.forEach((kpi, index) => {
    if (timeMin === undefined || new Date(kpi.timestamp) < timeMin) {
      timeMin = new Date(kpi.timestamp);
    } else if (timeMax === undefined || new Date(kpi.timestamp) > timeMax) {
      timeMax = new Date(kpi.timestamp);
    }
    if (
      (!isEfficiency || (isEfficiency && !min)) &&
      kpi.pmd === true &&
      index < kpiImbalance.length - 2 &&
      kpiImbalance[index + 1].pmd === true
    ) {
      rectangles.push({
        type: 'rect',
        xref: 'x',
        yref: 'paper',
        x0: getDateWithCurrentTimezone(
          kpi.timestamp,
          -new Date(kpi.timestamp).getTimezoneOffset() / 60,
        ),
        x1: getDateWithCurrentTimezone(
          kpiImbalance[index + 1].timestamp,
          -new Date(kpi.timestamp).getTimezoneOffset() / 60,
        ),
        y0: 0,
        y1: 1,
        showlegend: !pmdPresent,
        name: 'PMD',
        opacity: 0.8,
        fillcolor: theme.palette.pmd.main,
        line: {
          width: 0,
        },
        layer: 'below',
      });
      pmdPresent = true;
    } else if (min && index < kpiImbalance.length - 2 && kpi.is_pmin) {
      rectangles.push({
        type: 'rect',
        xref: 'x',
        yref: 'paper',
        x0: getDateWithCurrentTimezone(
          kpi.timestamp,
          -new Date(kpi.timestamp).getTimezoneOffset() / 60,
        ),
        x1: getDateWithCurrentTimezone(
          kpiImbalance[index + 1].timestamp,
          -new Date(kpi.timestamp).getTimezoneOffset() / 60,
        ),
        y0: 0,
        y1: 1,
        opacity: 0.8,
        showlegend: !pminPresent,
        fillcolor: theme.palette.pmd.main,
        name: 'Pmin',
        line: {
          width: 0,
        },
        layer: 'below',
      });
      pminPresent = true;
    }
  });

  const dispatch = useDispatch();

  const [endDate, setEndDate] = useState<Date | null>();
  const [xAxisRange, setXAxisRange] = useState<[Date, Date]>([
    startZoom.current ?? new Date(startDate),
    startZoom.current != undefined
      ? addHours(startZoom.current, gap.current)
      : endDate ?? new Date(),
  ]);
  const [yAxisRange, setYAxisRange] = useState<[number, number]>([
    MIN_POWER,
    isEfficiency ? MAX_EFFICIENCY : MAX_POWER,
  ]);

  useEffect(() => {
    setEndDate(addDays(new Date(startDate), NUMBER_OF_DAYS));
  }, []);

  useEffect(() => {
    if (gap.current !== NUMBER_OF_DAYS * 24 && startZoom.current !== undefined) {
      setXAxisRange([startZoom.current, addHours(startZoom.current, gap.current)]);
    } else if (endDate !== undefined && endDate !== null) {
      setXAxisRange([new Date(startDate), endDate]);
      setYAxisRange([MIN_POWER, isEfficiency ? MAX_EFFICIENCY : MAX_POWER]);
    }
  }, [startDate, endDate]);

  const clickOnGraph = (event: Readonly<PlotMouseEvent>) => {
    const currentXAxisRange = xAxisRange;
    dispatch(setGraphIndex(event.points[0].pointIndex));
    dispatch(
      setRetrieveYearOfCurrentGain(
        new Date(kpiImbalance[event.points[0].pointIndex]?.timestamp).getFullYear().toString(),
      ),
    );
    setXAxisRange(currentXAxisRange);
  };

  const resetGap = () => {
    gap.current = NUMBER_OF_DAYS * 24;
    startZoom.current = undefined;
  };

  const browseDate = (right?: boolean) => {
    gap.current = Math.abs(differenceInHours(new Date(xAxisRange[0]), new Date(xAxisRange[1])));
    if (right) {
      const tmpXAxisRange: [Date, Date] = [
        addHours(xAxisRange[0], gap.current),
        addHours(xAxisRange[1], gap.current),
      ];
      setXAxisRange(tmpXAxisRange);
      startZoom.current = tmpXAxisRange[0];
      if (siteId) {
        const newStartDate = subDays(startOfDay(tmpXAxisRange[0]), 1);
        const newEndDate = addDays(newStartDate, NUMBER_OF_DAYS);
        dispatch(
          setRetrieveKpiImbalance({
            siteId: siteId,
            startDate: newStartDate.toISOString(),
            endDate: newEndDate.toISOString(),
          }),
        );
        setEndDate(newEndDate);
        dispatch(setStartDate(newStartDate.toISOString()));
      }
    } else {
      const tmpXAxisRange: [Date, Date] = [
        subHours(xAxisRange[0], gap.current),
        subHours(xAxisRange[1], gap.current),
      ];
      startZoom.current = tmpXAxisRange[0];
      setXAxisRange(tmpXAxisRange);
      if (siteId) {
        const newStartDate = subDays(startOfDay(tmpXAxisRange[0]), 0);
        const newEndDate = addDays(newStartDate, NUMBER_OF_DAYS);
        dispatch(
          setRetrieveKpiImbalance({
            siteId: siteId,
            startDate: newStartDate.toISOString(),
            endDate: newEndDate.toISOString(),
          }),
        );
        setEndDate(newEndDate);
        dispatch(setStartDate(newStartDate.toISOString()));
      }
    }
  };

  const canGoInFutur = (): boolean => {
    if (endDate !== null && endDate !== undefined) {
      return endDate < new Date();
    }
    return false;
  };

  const verticalLineDate: Date =
    kpiImbalance.length > 0 ? new Date(kpiImbalance[graphIndex]?.timestamp) : new Date();

  const onDisplayAxis = (e: Readonly<PlotRelayoutEvent>) => {
    let newXAxisRange: [Date, Date] = [new Date(), new Date()];
    if (e['xaxis.range[0]'] !== undefined && e['xaxis.range[1]'] !== undefined) {
      newXAxisRange = [new Date(e['xaxis.range[0]']), new Date(e['xaxis.range[1]'])];
    }
    if (e['xaxis.range'] !== undefined) {
      newXAxisRange = [timeMin ?? new Date(), timeMax ?? new Date()];
    }
    if (timeMin && newXAxisRange !== undefined && newXAxisRange[0] < timeMin) {
      newXAxisRange[0] = timeMin;
    }
    if (timeMax && newXAxisRange !== undefined && newXAxisRange[1] > timeMax) {
      newXAxisRange[1] = timeMax;
    }
    gap.current = Math.abs(differenceInHours(newXAxisRange[0], newXAxisRange[1]));
    setXAxisRange(newXAxisRange);

    let newYAxisRange: [number, number] = [0, 500];
    if (e['yaxis.range[0]'] !== undefined && e['yaxis.range[1]'] !== undefined) {
      newYAxisRange = [e['yaxis.range[0]'] >= 0 ? e['yaxis.range[0]'] : 0, e['yaxis.range[1]']];
    }
    if (e['yaxis.range'] !== undefined) {
      newYAxisRange = [0, e['yaxis.range'][1] as number];
    }
    if (newYAxisRange[1] > (isEfficiency ? MAX_EFFICIENCY : MAX_POWER)) {
      newYAxisRange[1] = isEfficiency ? MAX_EFFICIENCY : MAX_POWER;
    }
    setYAxisRange(newYAxisRange);
  };

  const handleLegendClick = (event: Readonly<LegendClickEvent>): boolean => {
    if (event.expandedIndex === undefined) return false;
    const curveNumber: number = event.curveNumber;
    const curveName: string | undefined = event.data[curveNumber].name;
    let filteredList: string[] = [...hiddenCurveList];
    if (curveName !== undefined) {
      if (hiddenCurveList.includes(curveName)) {
        filteredList = hiddenCurveList.filter(elem => elem !== curveName);
      } else {
        filteredList.push(curveName);
      }
      dispatch(setHiddenCurves(filteredList));
    }
    return false;
  };

  const getXAxisName = (): string => {
    if (gap.current === 168) return '1 ' + intl.formatMessage({ id: 'home.week' });
    else if (gap.current < 24) return gap.current + ' ' + intl.formatMessage({ id: 'home.hour' });
    else
      return (
        Math.round(gap.current / 24) +
        ' ' +
        intl.formatMessage({ id: 'home.day' }) +
        ' ' +
        (gap.current % 24) +
        ' ' +
        intl.formatMessage({ id: 'home.hour' })
      );
  };

  return (
    <div className={styles.graphAndDateContainer}>
      {!isDispatch && (
        <div className={styles.datePickerAndTitleContainer}>
          <div className={styles.datePickerAndTitle}>
            <div className={styles.datePickerTitle}>
              <FormattedMessage id="generalDashboard.weekStarts" />
            </div>
            <div onClick={resetGap} className={styles.datePickerContainer}>
              <CustomDatePicker setEndDate={setEndDate} />
            </div>
          </div>
        </div>
      )}
      {isLoading === false ? (
        <div className={styles.graphContainer}>
          <Plot
            onLegendClick={handleLegendClick}
            onRelayout={(e: Readonly<PlotRelayoutEvent>) => onDisplayAxis(e)}
            onClick={(e: Readonly<PlotMouseEvent>) => clickOnGraph(e)}
            config={{
              responsive: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                'toImage',
                'toggleSpikelines',
                'hoverCompareCartesian',
                'hoverClosestCartesian',
                'zoom2d',
                'lasso2d',
                'autoScale2d',
              ],
            }}
            data={dataPlotly}
            layout={{
              legend: {
                x: 1.02,
                y: 0.9,
                font: {
                  color: theme.palette.text.primary,
                },
              },
              showlegend: true,
              hovermode: 'x',
              height: height,
              width: width,
              font: {
                family: 'Roboto',
                size: 12,
                color: '#222B2D',
              },
              plot_bgcolor: theme.palette.background.default,
              paper_bgcolor: theme.palette.backgroundLayer1.main,
              xaxis: {
                range: isDispatch ? undefined : xAxisRange,
                rangeselector: isDispatch ? selectorOptions : undefined,
                rangeslider: isDispatch ? {} : undefined,
                type: 'date',
                title: {
                  text: getXAxisName(),
                  font: { size: 14, color: theme.palette.text.secondary },
                },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikecolor: '#222B2D',
                spikedash: 'solid',
                showline: true,
                showgrid: true,
                rangemode: 'normal',
                gridwidth: 1,
                zerolinecolor: '#8C9294',
                zerolinewidth: 2,
                linecolor: '#D0D3D5',
                linewidth: 1,
                mirror: true,
                color: theme.palette.text.primary,
              },
              yaxis: {
                range: yAxisRange,
                title: {
                  text: yAxisName,
                  font: { size: 14, color: theme.palette.text.secondary },
                  standoff: 20,
                },
                showgrid: true,
                zerolinecolor: '#8C9294',
                zerolinewidth: 2,
                gridwidth: 1,
                linecolor: '#D0D3D5',
                linewidth: 1,
                mirror: true,
                color: theme.palette.text.primary,
              },
              margin: margin,
              autosize: true,
              shapes: [
                ...rectangles,
                verticalLineDate
                  ? {
                      type: 'line',
                      x0: verticalLineDate,
                      y0: MIN_POWER,
                      yref: 'paper',
                      x1: verticalLineDate,
                      y1: MAX_POWER,
                      line: {
                        color: theme.palette.text.primary,
                        width: 2,
                      },
                    }
                  : {},
              ],
            }}
          />
          <div className={styles.arrowContainer}>
            <IconButton
              onClick={() => browseDate()}
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
            >
              <ChevronLeftIcon color="primary" />
              <Typography>
                <FormattedMessage id={'generalDashboard.previous'} />
              </Typography>
            </IconButton>
            <IconButton
              disabled={!canGoInFutur()}
              onClick={() => browseDate(true)}
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
            >
              <Typography>
                <FormattedMessage id={'generalDashboard.next'} />
              </Typography>
              <ChevronRightIcon color={canGoInFutur() ? 'primary' : 'disabled'} />
            </IconButton>
          </div>
        </div>
      ) : (
        <div>
          <CircularProgressWithLabel value={progress} />
        </div>
      )}
    </div>
  );
};

export default TimeseriesGraph;
