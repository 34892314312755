import { PayloadAction } from '@reduxjs/toolkit';
import { YearOfCurrentGainState } from '../states/yearOfCurrentGain/yearOfCurrentGain.state';

export const setRetrieveYearOfCurrentGainReducer = (
  state: YearOfCurrentGainState,
  action: PayloadAction<string>,
) => {
  state.year = action.payload;
  state.isLoading = true;
};
