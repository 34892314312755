import { Grid, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink, generatePath, useLocation } from 'react-router-dom';
import { PATHS } from '../../../../../constants/path';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { tabWithSubtab } from '../type';
import AccordionComponent from './component/accordion';
import {
  dashBoardSubTab,
  dashBoardSubTabSuperAdmin,
  exportSubTab,
  inputDataSubTab,
} from './constant';
import styles from './menu.module.scss';

const MenuComponent: FC = () => {
  const [activeTabName, setActiveTabName] = useState<string | null>(null);
  const [activeSubtabName, setActiveSubtabName] = useState<string | null>(null);
  const [activeLink, setActiveLink] = useState<string | null>(null);

  const location = useLocation().pathname;
  const intl = useIntl();
  const theme = useTheme();
  const { isSuperAdminMode } = useSelector((state: RootState) => state.isSuperAdminMode);

  useEffect(() => {
    const concatMenu = dashBoardSubTab.concat(inputDataSubTab);
    concatMenu.forEach((tab: tabWithSubtab) => {
      if (tab.path === location) {
        setActiveTabName(tab.tabName);
        setActiveSubtabName(tab.subTabName);
      }
    });
  }, [location]);

  const handleTabAndSubtabClick = (tabName: string, subtabName: string) => {
    setActiveTabName(tabName);
    setActiveSubtabName(subtabName);
    setActiveLink(null);
  };

  const handleLinkClick = (linkId: string) => {
    setActiveTabName(null);
    setActiveSubtabName(null);
    setActiveLink(linkId);
  };

  const isActiveLink = (linkId: string, isActive: boolean): string => {
    return linkId === activeLink && isActive ? styles.linkActive : styles.linkInactive;
  };

  return (
    <div>
      <AccordionComponent
        tabName={intl.formatMessage({ id: 'generalDashboard.menu.dashboards' })}
        icon={'icon.dashboard'}
        subtabList={isSuperAdminMode ? dashBoardSubTabSuperAdmin : dashBoardSubTab}
        activeTabName={activeTabName}
        activeSubtabName={activeSubtabName}
        onTabAndSubtabClick={handleTabAndSubtabClick}
      />
      <AccordionComponent
        tabName={intl.formatMessage({ id: 'generalDashboard.menu.export' })}
        icon={'icon.download'}
        subtabList={exportSubTab}
        activeTabName={activeTabName}
        activeSubtabName={activeSubtabName}
        onTabAndSubtabClick={handleTabAndSubtabClick}
      ></AccordionComponent>
      <AccordionComponent
        tabName={intl.formatMessage({ id: 'generalDashboard.menu.inputData' })}
        icon={'icon.factory'}
        subtabList={inputDataSubTab}
        activeTabName={activeTabName}
        activeSubtabName={activeSubtabName}
        onTabAndSubtabClick={handleTabAndSubtabClick}
      />
      <div className={styles.tabContainer}>
        <Grid container direction="column" wrap="nowrap">
          <NavLink
            onClick={() => handleLinkClick('admin')}
            className={({ isActive }) => isActiveLink('admin', isActive)}
            style={{
              textDecoration: 'none',
              width: 'inherit',
              color: theme.palette.text.primary,
            }}
            to={generatePath(PATHS.ADMIN)}
          >
            <Grid container alignItems="center" className={styles.linkTitle}>
              <div
                style={{
                  color: activeLink === 'admin' ? theme.palette.primary.main : undefined,
                }}
                className={styles.iconAndTabContainer}
              >
                <span className="material-icons-outlined">
                  <FormattedMessage id={'icon.admin'} />
                </span>
                <FormattedMessage id="home.admin" />
              </div>
            </Grid>
          </NavLink>
        </Grid>
      </div>
    </div>
  );
};

export default MenuComponent;
