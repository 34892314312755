import { CardContent, Grid } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../corelogic/redux/create-store';
import { setRetrieveGain } from '../../../../../../../corelogic/redux/currentTimeseries/gain/gain.slice';
import { setRetrievePmdKpiMean } from '../../../../../../../corelogic/redux/pmdKpiMean/pmd-kpi-mean.slice';
import { CustomCard } from '../../../../../../../util/functionUtil';
import CardComponent from '../../../cards/cardComponent/cardComponent';
import CardComponentStatus from '../../../cards/cardComponentStatus/cardComponentStatus';
import CardComponentWithIcon from '../../../cards/cardComponentWithIcon/cardComponentWithIcon';
import GainCounter from '../gainCounter/gainCounter';
import styles from './power.module.scss';

const Power: FC = () => {
  const { graphIndex } = useSelector((state: RootState) => state.graphIndex);
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const { kpiImbalance } = useSelector((state: RootState) => state.kpiImbalance);
  const intl = useIntl();
  const dispatch = useDispatch();
  const storedSiteId = localStorage.getItem('favouriteSite');

  const currentKpi = kpiImbalance[graphIndex];

  useEffect(() => {
    dispatch(setRetrieveGain(storedSiteId));
    dispatch(setRetrievePmdKpiMean(storedSiteId));
  }, []);

  return (
    <div className={clsx(styles.pageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined">
        <CardContent className={styles.parent_gap_container}>
          <div className={styles.manufacturer_information_title}>
            <h1>
              <FormattedMessage id="generalDashboard.power" />
            </h1>
          </div>
          <Grid container spacing={1.5}>
            <Grid item xs="auto">
              <GainCounter />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.runningProgram' })}
                icon={'icon.run'}
                iconColor="#FF5722"
                value={currentKpi?.power_production_running_program?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.runningProgram',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.netActivePower' })}
                icon={'icon.electric_bolt'}
                value={currentKpi?.net_power?.toFixed(2) ?? 'N/A'}
                iconColor="#2196F3"
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.netActivePower',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.pmaxModelForecast' })}
                icon={'icon.event'}
                iconColor="#E91E63"
                value={currentKpi?.net_power_p2m_forecast?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.power.powerPmaxModelForecast',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.pmaxModel' })}
                icon={'icon.modelTraining'}
                iconColor="#9C27B0"
                value={currentKpi?.net_power_p2m_estimate?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.power.powerPmaxModel',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.pmaxOemCurves' })}
                icon={'icon.lineAxis'}
                iconColor="#40A900"
                value={currentKpi?.net_power_oem_estimate?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.power.powerPmaxOEM',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.modelReliability' })}
                icon={'icon.handshake'}
                iconColor="#40A900"
                value={currentKpi?.net_power_model_reliability?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.power.modelReliabilityPower',
                })}
              />
            </Grid>
          </Grid>
          <CardComponent isCurves={true} isEfficiency={false} />
          <div className={styles.imbalanceAndStatus}>
            <div className={styles.status}>
              <CardComponentStatus title="generalDashboard.status.title" />
            </div>
            <CardComponent
              title="generalDashboard.imbalances.title"
              isImbalance={true}
              isEfficiency={false}
            />
          </div>
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default Power;
