import { PayloadAction } from '@reduxjs/toolkit';
import { StateObservable, ofType } from 'redux-observable';
import {
  Observable,
  catchError,
  from,
  map,
  mergeMap,
  of,
  startWith,
  endWith,
  switchMap,
} from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { splitDateIntoEqualIntervals } from '../../../../../helpers/dateFunction';
import { RootState } from '../../../../redux/create-store';
import {
  setLoadedKpiImbalance,
  setLoadingKpiImbalance,
  setResetKpiImbalance,
  setRetrieveKpiImbalanceFailure,
  setRetrieveKpiImbalanceSuccess,
} from '../../../../redux/kpiImbalance/kpi-imbalance.slice';
import { KpiImbalance } from './../../../models/kpiImbalance/kpiImbalance.model';

const CHUNK_DAY_SIZE = 7;

export const RetrieveKpiImbalanceEpic = (
  action$: Observable<
    PayloadAction<{ siteId: string; startDate: string; endDate: string; chunk?: number }>
  >,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('kpiImbalance/setRetrieveKpiImbalance'),
    switchMap(action => {
      const dateRanges = splitDateIntoEqualIntervals(
        new Date(action.payload.startDate),
        new Date(action.payload.endDate),
        action.payload.chunk ?? CHUNK_DAY_SIZE,
      );
      return from(dateRanges).pipe(
        mergeMap(dateRange =>
          from(
            dependencies.kpiImbalanceGateway.retrieveKpiImbalance(
              action.payload.siteId,
              dateRange.start.toISOString(),
              dateRange.end.toISOString(),
            ),
          ).pipe(
            map((kpiImbalance: KpiImbalance[]) =>
              setRetrieveKpiImbalanceSuccess(
                kpiImbalance.sort(
                  (kpiA: KpiImbalance, kpiB: KpiImbalance) =>
                    new Date(kpiA.timestamp).getTime() - new Date(kpiB.timestamp).getTime(),
                ),
              ),
            ),
            catchError(() =>
              of(
                setRetrieveKpiImbalanceFailure(
                  `Retrieving kpi imbalance for ${action.payload.siteId} has failed`,
                ),
              ),
            ),
          ),
        ),
        startWith(setLoadingKpiImbalance()),
        startWith(setResetKpiImbalance()),
        endWith(setLoadedKpiImbalance()),
      );
    }),
  );
