/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../configurations/dependencies.interface';
import { StateObservable, ofType } from 'redux-observable';
import { RootState } from '../../../redux/create-store';

import { SiteFortuitDTO } from '../../models/site/siteFortuit.dto';
import {
  setRetrieveSiteFortuitSuccess,
  setRetrieveSiteFortuitFailure,
} from '../../../redux/siteFortuit/siteFortuit.slice';
export const retrieveFortuitEpic = (
  action$: Observable<PayloadAction>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('siteFortuit/setRetrieveSiteFortuit'),
    switchMap(action => {
      return from(dependencies.kpiImbalanceGateway.retrieveFortuit()).pipe(
        map((fortuits: SiteFortuitDTO[]) => setRetrieveSiteFortuitSuccess(fortuits)),
        catchError(() => of(setRetrieveSiteFortuitFailure(`Retrieving fortuits has failed`))),
      );
    }),
  );
