import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import { DailyCapacityFileInputType } from '../../../../redux/dailyCapacityFile/dailyCapacityFile.reducer';
import {
  setRetrieveDailyCapacityFileFailure,
  setRetrieveDailyCapacityFileSuccess,
} from '../../../../redux/dailyCapacityFile/dailyCapacityFile.slice';

export const retrieveDailyCapacityFileEpic = (
  action$: Observable<PayloadAction<DailyCapacityFileInputType>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('dailyCapacityFile/setRetrieveDailyCapacityFile'),
    switchMap(action => {
      return from(
        dependencies.siteGateway.retrieveDailyCapacityFileFromS3(
          action.payload.creationDate,
          action.payload.siteId,
          action.payload.type,
        ),
      ).pipe(
        map((fileName: string) => setRetrieveDailyCapacityFileSuccess({ url: fileName || '' })),
        catchError(() =>
          of(
            setRetrieveDailyCapacityFileFailure(
              `Retrieving daily capacity file for ${action.payload.siteId} has failed`,
            ),
          ),
        ),
      );
    }),
  );
