import { createSlice } from '@reduxjs/toolkit';
import { initialSiteFortuitState } from '../states/fortuit/siteFortuit.state';
import {
  setRetrieveSiteFortuitReducer,
  setRetrieveSiteFortuitSuccessReducer,
  setRetrieveSiteFortuitFailureReducer,
} from './siteFortuit.reducer';

export const siteFortuitSlice = createSlice({
  name: 'siteFortuit',
  initialState: initialSiteFortuitState,
  reducers: {
    setRetrieveSiteFortuit: setRetrieveSiteFortuitReducer,
    setRetrieveSiteFortuitSuccess: setRetrieveSiteFortuitSuccessReducer,
    setRetrieveSiteFortuitFailure: setRetrieveSiteFortuitFailureReducer,
  },
});

export const {
  setRetrieveSiteFortuit,
  setRetrieveSiteFortuitFailure,
  setRetrieveSiteFortuitSuccess,
} = siteFortuitSlice.actions;

export const siteFortuitReducers = siteFortuitSlice.reducer;
