import { FormControl, OutlinedInput } from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { Reference } from '../../../../../corelogic/domain/models/reference/reference.model';
import { moreThan4Decimals } from '../popinCurve/function.util';
import styles from './form.module.scss';

interface InputDecimalValues {
  decimalValue: string;
}

type Props = {
  reference: Reference;
  referencesState: Reference[];
  setReferencesState: React.Dispatch<React.SetStateAction<Reference[] | undefined>>;
  disabled: boolean;
};

const ReferenceInput: React.FC<Props> = ({
  reference,
  referencesState,
  setReferencesState,
  disabled,
}) => {
  const intl = useIntl();
  const validateDecimal = (value: number): FormikErrors<InputDecimalValues> => {
    const errors: FormikErrors<InputDecimalValues> = {};
    if (reference.name === 'gt_power_limit' || reference.name === 'ccgt_power_limit') {
      if (moreThan4Decimals(value) && !isNaN(value)) {
        errors.decimalValue = intl.formatMessage({ id: 'errorMessage.numberOfDecimals' });
      }
    } else if (value !== 0 && (!value || isNaN(value))) {
      errors.decimalValue = intl.formatMessage({ id: 'errorMessage.noValue' });
    } else if (moreThan4Decimals(value)) {
      errors.decimalValue = intl.formatMessage({ id: 'errorMessage.numberOfDecimals' });
    }
    return errors;
  };

  const formik = useFormik<InputDecimalValues>({
    initialValues: {
      decimalValue: reference.value !== null ? reference.value.toString() : '-',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: ({ decimalValue }) => validateDecimal(parseFloat(decimalValue)),
    onSubmit: () => undefined,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('decimalValue', event.target.value);
    reference = { ...reference, value: parseFloat(event?.target.value) };
    referencesState = referencesState.map((referenceObj: Reference) =>
      referenceObj.name === reference.name ? reference : referenceObj,
    );
    setReferencesState(referencesState);
    setTimeout(() => {
      formik.validateForm();
    }, 100);
  };

  return (
    <div>
      <FormControl>
        <OutlinedInput
          endAdornment={reference.unit}
          id={reference.name}
          type="number"
          value={formik.values.decimalValue}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          placeholder=" -"
          className={styles.inputContainer}
          size="small"
          disabled={disabled}
        />
      </FormControl>
      <div>
        {formik.errors.decimalValue && !disabled ? (
          <div style={{ color: 'red' }}>{formik.errors.decimalValue}</div>
        ) : null}
      </div>
    </div>
  );
};

export default ReferenceInput;
