import { OverviewDTO } from '../../../domain/models/overview/overview.dto';

export interface OverviewState {
  overview?: OverviewDTO[];
  isLoading: boolean;
  error?: string;
}

export const initialOverviewState: OverviewState = {
  overview: [],
  isLoading: false,
};
