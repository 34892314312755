/* eslint-disable prettier/prettier */
import { GridCellParams, GridColTypeDef, GridFilterItem } from '@mui/x-data-grid';
import FilterSpecificDate from './filterSpecificDate/filterSpecificDate';

export const buildApplyDateFilterFn = (
    filterItem: GridFilterItem,
    compareFn: (value1: number, value2: number) => boolean,
) => {
    if (!filterItem.value) {
        return null;
    }

    // Make a copy of the date to not reset the hours in the original object
    const filterValueCopy = new Date(filterItem.value);
    filterValueCopy.setHours(0, 0, 0, 0);

    const filterValueMs = filterValueCopy.getTime();

    return ({ value }: GridCellParams<any, Date>): boolean => {
        if (!value) {
            return false;
        }

        // Make a copy of the date to not reset the hours in the original object
        const dateCopy = new Date(value);
        dateCopy.setHours(0, 0, 0, 0);
        const cellValueMs = dateCopy.getTime();

        return compareFn(cellValueMs, filterValueMs);
    };
};

export const getDateFilterOperators = (): GridColTypeDef['filterOperators'] => {
    return [
        {
            label: 'Is',
            value: 'is',
            getApplyFilterFn: filterItem => {
                return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 === value2);
            },
            InputComponent: FilterSpecificDate,
        },
    ];
}
