import { PayloadAction } from '@reduxjs/toolkit';
import {
  IsUpdatedSiteNameState,
  UpdatedSiteName,
} from '../states/updateSiteName/update-site-name-boolean.state';

export const setIsUpdatedSiteNameReducer = (
  state: IsUpdatedSiteNameState,
  action: PayloadAction<UpdatedSiteName>,
) => {
  state.isUpdatedSiteName = action.payload;
  state.isLoading = false;
};
