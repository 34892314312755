import { AccountInfo, AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../../../configurations/msal-client.configuration';
import { AuthenticationToken } from '../../../../corelogic/domain/models/authentification/authentication-token.model';
import { AuthenticationProvider } from '../../../../corelogic/domain/providers/authentication.provider';

export class MsalAuthenticationProvider implements AuthenticationProvider {
  private account: AccountInfo | null = null;
  private msalClient: PublicClientApplication;

  constructor() {
    this.msalClient = new PublicClientApplication(msalConfig);
    const currentAccount: AccountInfo | null = this.msalClient.getActiveAccount();
    this.account = currentAccount;
  }

  login = async (): Promise<AuthenticationToken> => {
    const authenticationResult: AuthenticationResult | null =
      await this.msalClient.handleRedirectPromise();

    this.handleRedirectResponse(authenticationResult);
    const accessToken = await this.retrieveToken();
    return { accessToken };
  };

  retrieveToken = async (): Promise<string> => {
    const request = {
      scopes: msalConfig.scopes,
    };
    const authenticationResult = await this.msalClient
      .acquireTokenSilent(request)
      .catch(async () => {
        this.msalClient.acquireTokenRedirect(request);
      });
    if (!authenticationResult) {
      return (await this.login()).accessToken;
    }
    return authenticationResult.accessToken;
  };

  handleRedirectResponse(authenticationResult: AuthenticationResult | null) {
    if (authenticationResult !== null) {
      this.account = authenticationResult.account;
      this.msalClient.setActiveAccount(this.account);
    } else {
      const currentAccounts = this.msalClient.getAllAccounts();
      if (
        currentAccounts === null ||
        currentAccounts === undefined ||
        currentAccounts.length === 0
      ) {
        this.msalClient.loginRedirect({
          scopes: msalConfig.scopes,
        });
      } else if (currentAccounts.length >= 1) {
        this.account = currentAccounts[0];
        this.msalClient.setActiveAccount(this.account);
      }
    }
  }
}
