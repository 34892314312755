export interface YearOfCurrentGainState {
  year: string;
  isLoading: boolean;
  error?: string;
}

export const initialYearOfCurrentGainState: YearOfCurrentGainState = {
  isLoading: false,
  year: new Date().getFullYear().toString(),
};
