import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setUpdateSiteParameter } from '../../../../../corelogic/redux/site/site.slice';
import styles from './exclusionPopin.module.scss';
import { DataExclusion } from '../../../../../corelogic/domain/models/site/dataExclusion.model';
import { getFormattedDateTimeISO } from '../../../../../helpers/dateFunction';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton, CustomTextField } from '../../../../../util/functionUtil';
import { DateTimePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';

type Props = {
  index?: number;
  exclusion?: DataExclusion;
  isOpen: boolean;
  isNewExclusion: boolean;
  handleClose: () => void;
};

const ExclusionPopin: React.FC<Props> = ({ exclusion, isOpen, isNewExclusion, handleClose }) => {
  const { site } = useSelector((state: RootState) => state.site);
  const { isSuperAdminMode } = useSelector((state: RootState) => state.isSuperAdminMode);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [descriptionState, setDescriptionState] = useState<string | null>(
    exclusion?.description ?? null,
  );
  const [startDateState, setStartDateState] = useState<Date | null>(exclusion?.startDate ?? null);
  const [endDateState, setEndDateState] = useState<Date | null>(exclusion?.endDate ?? null);
  const [applyToPowerState, setApplyToPowerState] = useState<boolean>(
    exclusion?.applyToPower ?? true,
  );
  const [applyToEfficiencyState, setApplyToEfficiencyState] = useState<boolean>(
    exclusion?.applyToEfficiency ?? true,
  );

  function updateSiteDataExclusion(): void {
    if (startDateState == null || descriptionState == null) return;
    const updatedExclusion: DataExclusion = {
      description: descriptionState,
      startDate: startDateState,
      endDate: endDateState ?? null,
      applyToEfficiency: applyToEfficiencyState,
      applyToPower: applyToPowerState,
    };

    const siteExclusion = [...site.dataExclusion];
    if (!isNewExclusion && exclusion) {
      const oldExclusionIndex = siteExclusion.findIndex(e => e == exclusion);
      siteExclusion[oldExclusionIndex] = updatedExclusion;
    } else siteExclusion.push(updatedExclusion);
    const updateBodyExclusion = { dataExclusion: siteExclusion };
    dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: updateBodyExclusion }));
    resetState();
    handleClose();
  }

  function resetState(): void {
    setDescriptionState(null);
    setStartDateState(null);
    setEndDateState(null);
  }

  return (
    <Dialog
      className={styles.dialogContainer}
      autoFocus
      id="exclusion"
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '90%', width: '50%' },
      }}
    >
      <DialogTitle>
        <div className={styles.titleAndButtonsPopin}>
          <div>
            <FormattedMessage id="dataExclusion.popin.title" />
          </div>
          <div className={styles.buttonContainerTags}>
            <div className={styles.buttonTags}>
              <CustomButton variant="outlined" type="button" onClick={handleClose} color="primary">
                <FormattedMessage id="button.cancel" />
              </CustomButton>
            </div>
            <div className={styles.buttonTags}>
              <CustomButton
                variant="contained"
                type="button"
                onClick={updateSiteDataExclusion}
                color="primary"
                disabled={
                  descriptionState == null ||
                  descriptionState == '' ||
                  startDateState == null ||
                  (applyToEfficiencyState === false &&
                    applyToPowerState === false &&
                    isSuperAdminMode)
                }
              >
                <FormattedMessage id="button.publish" />
              </CustomButton>
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.mandatoryTextContainer}>
          <div>
            <FormattedMessage id="popin.field" />
          </div>
          <div style={{ color: 'red' }}> * </div>
          <div>
            <FormattedMessage id="popin.mandatoryField" />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div>
            <div className={styles.infoTitleContainer}>
              <div style={{ color: 'red' }}> * </div>
              <FormattedMessage id="dataExclusion.description" />
            </div>
            <CustomTextField
              fullWidth
              multiline
              defaultValue={isNewExclusion ? undefined : exclusion?.description}
              onChange={e => setDescriptionState(e.target.value)}
            />
          </div>
          <div className={styles.dateContainer}>
            <div>
              <div className={styles.infoTitleContainer}>
                <div style={{ color: 'red' }}> * </div>
                <FormattedMessage id="dataExclusion.startDate" />
              </div>
              <DateTimePicker
                sx={{
                  '.MuiInputBase-root': {
                    borderRadius: '8px',
                  },
                }}
                onChange={e => setStartDateState(e ? new Date(e) : null)}
                slotProps={{
                  openPickerIcon: { color: 'primary' },
                }}
                defaultValue={
                  !isNewExclusion && exclusion
                    ? parseISO(getFormattedDateTimeISO(new Date(exclusion.startDate)))
                    : undefined
                }
              />
            </div>
            <div>
              <div className={styles.infoTitleContainer}>
                <FormattedMessage id="dataExclusion.endDate" />
              </div>
              <DateTimePicker
                sx={{
                  '.MuiInputBase-root': {
                    borderRadius: '8px',
                  },
                }}
                slotProps={{
                  openPickerIcon: { color: 'primary' },
                }}
                defaultValue={
                  !isNewExclusion && exclusion && exclusion.endDate
                    ? parseISO(getFormattedDateTimeISO(new Date(exclusion?.endDate)))
                    : undefined
                }
                onChange={e => setEndDateState(e ? new Date(e) : null)}
              />
            </div>
          </div>
          <div className={styles.checkbox}>
            <div className={styles.infoTitleContainer}>
              <div style={{ color: 'red' }}> * </div>
              <FormattedMessage id="dataExclusion.popin.apply" />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={applyToPowerState}
                  onChange={e => setApplyToPowerState(e.target.checked)}
                />
              }
              label={intl.formatMessage({ id: 'generalDashboard.power' })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={applyToEfficiencyState}
                  onChange={e => setApplyToEfficiencyState(e.target.checked)}
                />
              }
              label="Efficiency"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ExclusionPopin;
