import { useMsal } from '@azure/msal-react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CardContent, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Admin } from '../../../../../corelogic/domain/models/site/admins.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setUpdateSiteParameter } from '../../../../../corelogic/redux/site/site.slice';
import { CustomButton, StripedDataGrid, CustomCard } from '../../../../../util/functionUtil';
import AddAdmin from './addAdmin/addAdmin.module';
import styles from './admin.module.scss';

const AdminModule: FC = () => {
  const { site } = useSelector((state: RootState) => state.site);
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const { isAdmin } = useSelector((state: RootState) => state.isAdmin);
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { accounts } = useMsal();
  const mail: string = accounts[0].username;
  const dispatch = useDispatch();
  function handleDelete(valueToRemove: Admin): void {
    const confirmMessage = `Are you sure to remove ${valueToRemove.name} as admin ?`;
    if (confirm(confirmMessage)) {
      let updatedAdmins = [...site.admins];
      updatedAdmins = updatedAdmins.filter(item => item !== valueToRemove);
      dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: { admins: updatedAdmins } }));
    }
  }

  const handlePopinOpen = () => {
    setOpen(true);
  };

  const handlePopinClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'admin.name' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'mail',
      headerName: intl.formatMessage({ id: 'admin.mail' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'jobTitle',
      headerName: intl.formatMessage({ id: 'admin.jobTitle' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'mail_subscriber',
      headerName: intl.formatMessage({ id: 'admin.mail_subscriber' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'delete',
      headerName: intl.formatMessage({ id: 'admin.delete' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 0.5,
      renderCell: params => {
        return (
          isAdmin &&
          mail.toLowerCase() !== params.row.mail.toLowerCase() && (
            <IconButton
              type="button"
              color="actionButton"
              onClick={() => handleDelete(params.row)}
              disabled={site.name === ''}
            >
              <DeleteOutlineIcon />
            </IconButton>
          )
        );
      },
    },
  ];

  return (
    <div className={clsx(styles.adminPageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined">
        <CardContent className={styles.parent_gap_container}>
          <div className={styles.title_button}>
            <h1>
              <FormattedMessage id="admin.title" />
            </h1>
            {isAdmin && (
              <CustomButton
                type="button"
                variant="contained"
                onClick={handlePopinOpen}
                disabled={site.name === ''}
                startIcon={<AddOutlinedIcon />}
              >
                <FormattedMessage id="admin.add" />
              </CustomButton>
            )}
            {open === true && <AddAdmin isOpen={open} handleClose={handlePopinClose} />}
          </div>
          <div className={styles.information}>
            <FormattedMessage id="admin.law" />
          </div>
          <StripedDataGrid
            disableColumnMenu
            disableColumnFilter
            disableRowSelectionOnClick
            sortingOrder={['desc', 'asc']}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'desc' }],
              },
              pagination: {
                paginationModel: {
                  pageSize: 8,
                },
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: '-60px !important',
                  minWidth: '650px !important',
                },
              },
            }}
            sx={{
              borderRadius: '16px',
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
            }}
            rows={site.admins}
            columns={columns}
            getRowId={row => row.mail + row.name}
            getRowClassName={params =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            pageSizeOptions={[8]}
          />
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default AdminModule;
