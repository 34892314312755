import { createSlice } from '@reduxjs/toolkit';
import {
  setRetrieveMlFileFromS3Reducer,
  setRetrieveMlFileFromS3SuccessReducer,
  setRetrieveMlFileFromS3FailureReducer,
} from './retrieveMLFromS3.reducer';
import { initialDownloadUrlState } from '../states/downloadUrl/downloadUrl.state';

export const retrieveMLFromS3Slice = createSlice({
  name: 'retrieveMLFromS3',
  initialState: initialDownloadUrlState,
  reducers: {
    setRetrieveMLFromS3: setRetrieveMlFileFromS3Reducer,
    setRetrieveMLFromS3Success: setRetrieveMlFileFromS3SuccessReducer,
    setRetrieveMLFromS3Failure: setRetrieveMlFileFromS3FailureReducer,
  },
});

export const { setRetrieveMLFromS3, setRetrieveMLFromS3Success, setRetrieveMLFromS3Failure } =
  retrieveMLFromS3Slice.actions;

export const retrieveMLFromS3Reducers = retrieveMLFromS3Slice.reducer;
