import { Icon, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CustomCard } from '../../../../../../util/functionUtil';
import styles from './cardComponentWithIcon.module.scss';

type Props = {
  title: string;
  icon: string;
  iconColor: string;
  value?: string;
  unit: string;
  helper: string;
};

const CardComponentWithIcon: React.FC<Props> = ({
  title,
  icon,
  iconColor,
  value,
  unit,
  helper,
}) => {
  return (
    <Tooltip title={helper} placement="top">
      <CustomCard boxShadow="unset">
        <div className={styles.iconContainer}>
          {icon !== 'icon.handshake' ? (
            <Icon sx={{ fontSize: '40px !important', color: iconColor }} color="primary">
              <FormattedMessage id={icon} />
            </Icon>
          ) : (
            <span className={styles.material_icons_green}>
              <FormattedMessage id={icon} />
            </span>
          )}
        </div>
        <div className={styles.titleAndValueContainer}>
          <div className={styles.titleAndIcon}>
            <div className={styles.title}>{title}</div>
            <span className={styles.material_icons}>
              <FormattedMessage id="icon.info" />
            </span>
          </div>
          {value !== undefined && (
            <div className={styles.valueAndUnit}>
              <div className={styles.value}>{value}</div>
              <div className={styles.unit}>{unit}</div>
            </div>
          )}
        </div>
      </CustomCard>
    </Tooltip>
  );
};
export default CardComponentWithIcon;
