import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SiteGroupReference } from '../../../../../../corelogic/domain/models/reference/reference.model';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { setContextCurrentParameter } from '../../../../../../corelogic/redux/parameter/context-current-parameter.slice';
import { Spinner } from '../../../../../../ui/spinner/spinner.component';
import { parameterNameMapping } from '../../uploadPopinFiles/parameterNameMapping';
import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';

const DropDownParameterList: FC = () => {
  const dispatch = useDispatch();
  const { site, isLoading } = useSelector((state: RootState) => state.site);
  const { parameterName } = useSelector((state: RootState) => state.currentParameter);
  const theme = useTheme();

  useEffect(() => {
    if (site !== undefined && site.siteGroupReferences.length > 0 && parameterName === undefined) {
      dispatch(setContextCurrentParameter(site.siteGroupReferences[0].name));
    }
  }, [site, dispatch]);

  const handleOptionChange = (e: SelectChangeEvent<string>) => {
    dispatch(setContextCurrentParameter(e.target.value));
  };

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="form-group">
      <Select
        size="small"
        style={{
          backgroundColor: theme.palette.backgroundBackground.light,
          color: theme.palette.text.primary,
        }}
        value={parameterName ?? 'ambient_temperature'}
        onChange={handleOptionChange}
        disabled={site.name === ''}
      >
        {site.siteGroupReferences?.map((value: SiteGroupReference) => (
          <MenuItem key={value.name} value={value.name}>
            {parameterNameMapping[value.name as keyof typeof parameterNameMapping]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DropDownParameterList;
