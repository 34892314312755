import { createSlice } from '@reduxjs/toolkit';
import { initialAllGainsState } from '../states/allGains/allGains.state';
import {
  setRetrieveAllGainsFailureReducer,
  setRetrieveAllGainsReducer,
  setRetrieveAllGainsSuccessReducer,
} from './allGains.reducer';

export const allGainsSlice = createSlice({
  name: 'allGains',
  initialState: initialAllGainsState,
  reducers: {
    setRetrieveAllGains: setRetrieveAllGainsReducer,
    setRetrieveAllGainsSuccess: setRetrieveAllGainsSuccessReducer,
    setRetrieveAllGainsFailure: setRetrieveAllGainsFailureReducer,
  },
});

export const { setRetrieveAllGains, setRetrieveAllGainsSuccess, setRetrieveAllGainsFailure } =
  allGainsSlice.actions;

export const allGainsReducers = allGainsSlice.reducer;
