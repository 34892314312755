import { useTheme } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../../../../../../../../ui/spinner/spinner.component';
import styleSpinner from '../../../../../../../../../ui/spinner/spinner.component.module.scss';
import styles from './imbalance.module.scss';

type Props = {
  title: string;
  value: number | null;
  unit: string;
  subTitle: string;
  showLoader: boolean;
};

const Imbalance: React.FC<Props> = ({ title, value, unit, subTitle, showLoader }) => {
  const theme = useTheme();
  return (
    <div className={styles.realProductionAndForecast}>
      <div className={styles.imbalanceContainer}>
        <div className={styles.titleAndSubtitle}>
          <div className={styles.title}>{title}</div>
          <div className={styles.logoAndSubtitle}>
            <span className={styles.material_icons}>
              <FormattedMessage id="icon.info" />
            </span>
            <div className={styles.subTitle} style={{ color: theme.palette.text.secondary }}>
              {subTitle}
            </div>
          </div>
        </div>
        {showLoader === true ? (
          <Spinner className={styleSpinner.spinnerSmall} />
        ) : (
          <div className={styles.valueAndUnit}>
            {value !== null && value != undefined ? (
              <div className={styles.value}>
                {value > 0 ? '+' : ''}
                {value.toFixed(2)}
              </div>
            ) : (
              <div className={styles.value} style={{ color: theme.palette.text.primary }}>
                <FormattedMessage id="generalDashboard.NA" />
              </div>
            )}
            <div className={styles.unit} style={{ color: theme.palette.text.secondary }}>
              {unit}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Imbalance;
