import { Site } from '../../../domain/models/site/site.model';

export interface SiteState {
  isLoading: boolean;
  site: Site;
  error?: string;
}

export const initialSiteState: SiteState = {
  isLoading: false,
  site: {
    _id: '',
    correction_parameters: [],
    name: '',
    siteGroupReferences: [],
    identity: {
      manufacturerName: '',
      lastRevisionDate: '',
      powerOutput: null,
      netHeatRate: null,
      heatRate: null,
      igvPosition: null,
      lastMajorMaintenance: '',
      pMin: null,
      pMinPercentage: null,
      floor: null,
      country: null,
    },
    tags: [],
    failures: [],
    mlModel: [],
    admins: [],
    dataExclusion: [],
  },
};
