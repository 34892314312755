export interface SuperAdminModeState {
  isSuperAdminMode: boolean;
  isLoading: boolean;
  error?: string;
}

export const initialIsSuperAdminModeState: SuperAdminModeState = {
  isSuperAdminMode: false,
  isLoading: false,
};
