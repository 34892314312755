import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import {
  setRetrieveSiteNamesIdsFailure,
  setRetrieveSiteNamesIdsSuccess,
} from '../../../../redux/siteNamesIds/siteNamesIds.slice';
import { SiteNameIdDto } from '../../../models/site/siteNameId.dto';

export const retrieveSiteNamesIdsEpic = (
  action$: Observable<PayloadAction<SiteNameIdDto>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('siteNamesIds/setRetrieveSiteNamesIds'),
    switchMap(() => {
      return from(dependencies.siteNamesIdsGateway.retrieveSitesNameId()).pipe(
        map((siteNamesIds: SiteNameIdDto[]) => {
          const sortedSiteNamesIds = [...siteNamesIds].sort(
            (siteA: SiteNameIdDto, siteB: SiteNameIdDto) => siteA.name.localeCompare(siteB.name),
          );
          return setRetrieveSiteNamesIdsSuccess(sortedSiteNamesIds);
        }),
        catchError(() =>
          of(setRetrieveSiteNamesIdsFailure(`Retrieving site Names and Ids  has failed`)),
        ),
      );
    }),
  );
