import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { axiosP2MSchematicApiConfiguration } from '../../../configurations/axios.configuration';
import { KpiImbalanceGateway } from '../../../corelogic/domain/gateways/kpi-imbalance.gateways';
import { KpiImbalance } from '../../../corelogic/domain/models/kpiImbalance/kpiImbalance.model';
import { acquireAccessToken } from '../providers/authentication/tokenProvider';
import { SiteFortuitDTO } from '../../../corelogic/domain/models/site/siteFortuit.dto';

const axiosClient: AxiosInstance = axios.create(axiosP2MSchematicApiConfiguration);
export class ApiKpiImbalanceGateway implements KpiImbalanceGateway {
  retrieveKpiImbalance = async (
    siteId: string,
    startDate: string,
    endDate: string,
  ): Promise<KpiImbalance[]> => {
    const response: AxiosResponse = await axiosClient.get<KpiImbalance[]>(
      `/kpiImbalance/${siteId}`,
      {
        ...axiosP2MSchematicApiConfiguration,
        headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
        params: { startDate, endDate },
      },
    );
    return response.data;
  };

  retrieveFortuit = async (): Promise<SiteFortuitDTO[]> => {
    const response: AxiosResponse = await axiosClient.get<SiteFortuitDTO[]>(
      '/kpiImbalance/siteFortuit',
      {
        ...axiosP2MSchematicApiConfiguration,
        headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
      },
    );
    return response.data;
  };
}
