import { DailyCapacitiesState } from './../states/dailyCapacities/dailyCapacities.state';
import { PayloadAction } from '@reduxjs/toolkit';
import { DailyCapacity } from '../../domain/models/dailyCapacity/dailyCapacity.model';

export const setDailyCapacitiesReducer = (
  state: DailyCapacitiesState,
  action: PayloadAction<DailyCapacity[] | null>,
) => {
  state.dailyCapacities = action.payload;
  state.isLoading = false;
};

export const setRetrieveDailyCapacitiesReducer = (
  state: DailyCapacitiesState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<string | null>,
) => {
  state.isLoading = true;
};

export const setRetrieveDailyCapacitiesSuccessReducer = (
  state: DailyCapacitiesState,
  action: PayloadAction<DailyCapacity[] | null>,
) => {
  state.isLoading = false;
  state.dailyCapacities = action.payload;
};

export const setRetrieveDailyCapacitiesFailureReducer = (
  state: DailyCapacitiesState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
};
