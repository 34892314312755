import { createSlice } from '@reduxjs/toolkit';
import {
  setDailyCapacitiesReducer,
  setRetrieveDailyCapacitiesFailureReducer,
  setRetrieveDailyCapacitiesReducer,
  setRetrieveDailyCapacitiesSuccessReducer,
} from './dailyCapacities.reducer';
import { initialDailyCapacitiesState } from '../states/dailyCapacities/dailyCapacities.state';

export const dailyCapacitiesSlice = createSlice({
  name: 'dailyCapacities',
  initialState: initialDailyCapacitiesState,
  reducers: {
    setDailyCapacities: setDailyCapacitiesReducer,
    setRetrieveDailyCapacities: setRetrieveDailyCapacitiesReducer,
    setRetrieveDailyCapacitiesSuccess: setRetrieveDailyCapacitiesSuccessReducer,
    setRetrieveDailyCapacitiesFailure: setRetrieveDailyCapacitiesFailureReducer,
  },
});

export const {
  setDailyCapacities,
  setRetrieveDailyCapacities,
  setRetrieveDailyCapacitiesSuccess,
  setRetrieveDailyCapacitiesFailure,
} = dailyCapacitiesSlice.actions;

export const dailyCapacitiesReducers = dailyCapacitiesSlice.reducer;
