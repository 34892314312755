import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import { FormattedMessage } from 'react-intl';
import styles from './status.module.scss';
import { Icon, useTheme } from '@mui/material';

type Props = { icon: string; status: string; value: string };

const Status: React.FC<Props> = ({ icon, status, value }) => {
  const theme = useTheme();
  return (
    <div className={styles.statusAndValue}>
      <div className={styles.iconAndText}>
        <span
          className={styles.material_icons}
          style={{ backgroundColor: '#' + theme.palette.background.default }}
        >
          <Icon color="primary">
            <FormattedMessage id={icon} />
          </Icon>
        </span>
        <div className={styles.statusName}>
          <FormattedMessage id={status} />
        </div>
      </div>
      <div className={styles.statusValue}>{value}</div>
    </div>
  );
};

export default Status;
