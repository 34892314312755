import { Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setCreateSite } from '../../../../../corelogic/redux/site/site.slice';

import styles from './newSitePopin.module.scss';
import { CustomButton, CustomTextField } from '../../../../../util/functionUtil';

type Props = { isOpen: boolean; handleClose: () => void };

const NewSitePopin: React.FC<Props> = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();

  let newSiteName: string;

  const addSite = () => {
    dispatch(setCreateSite({ siteName: newSiteName }));
    handleClose();
  };

  function setNewName(event: ChangeEvent<HTMLInputElement>): void {
    newSiteName = event.target.value;
  }

  return (
    <Dialog
      className={styles.dialogContainer}
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <DialogTitle>
        <FormattedMessage id="addNewPopin.title" />
      </DialogTitle>
      <DialogContent sx={{ pt: `${theme.spacing(2)} !important` }}>
        <div>
          <CustomTextField
            sx={{ whiteSpace: 'pre-wrap', p: 0, m: 0 }}
            size="small"
            label={intl.formatMessage({ id: 'addNewPopin.name' })}
            type="text"
            placeholder="Eg. Bayet"
            onChange={setNewName}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton
          variant="outlined"
          className="btn btn-secondary "
          onClick={handleClose}
          color="primary"
        >
          <FormattedMessage id="button.cancel" />
        </CustomButton>
        <CustomButton
          variant="contained"
          className="btn btn-primary "
          onClick={addSite}
          color="primary"
        >
          <FormattedMessage id="button.publish" />
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewSitePopin;
