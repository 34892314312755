/* eslint-disable prettier/prettier */
import { PayloadAction } from '@reduxjs/toolkit';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../configurations/dependencies.interface';
import { RootState } from '../../../redux/create-store';
import { OverviewDTO } from '../../models/overview/overview.dto';
import {
  setRetrieveOverviewFailure,
  setRetrieveOverviewSuccess,
} from '../../../redux/overview/overview.slice';

export const retrieveOverviewEpic = (
  action$: Observable<PayloadAction<string[]>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('overview/setRetrieveOverview'),
    switchMap(action => {
      return from(dependencies.overviewGateway.retrieveOverview()).pipe(
        map((overview: OverviewDTO[]) => setRetrieveOverviewSuccess(overview)),
        catchError(() =>
          of(setRetrieveOverviewFailure(`Retrieve overview ${action.payload} has failed`)),
        ),
      );
    }),
  );
