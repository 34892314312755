export interface IsUpdatedSiteNameState {
  isUpdatedSiteName: UpdatedSiteName;
  isLoading: boolean;
  error?: string;
}

export const initialSiteNameBooleanState: IsUpdatedSiteNameState = {
  isUpdatedSiteName: { isUpdatedSiteName: false, errorTitleMessage: '' },
  isLoading: false,
};

export type UpdatedSiteName = {
  isUpdatedSiteName: boolean;
  errorTitleMessage: string;
};
