import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { ReactElement, ReactNode, useEffect } from 'react';
import { msalConfig } from '../../../../configurations/msal-client.configuration';

function AuthenticationProvider({ children }: WithChildren): ReactElement {
  //check if needed
  useMsal();
  const { login, error } = useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    const request = {
      scopes: msalConfig.scopes,
    };
    error && login(InteractionType.Redirect, request);
  }, [error, login]);

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>Invalid</div>
      </UnauthenticatedTemplate>
    </>
  );
}

export type WithChildren<T = Record<string, unknown>> = T & { children?: ReactNode };

export default AuthenticationProvider;
