import { Gain } from '../../../domain/models/gain/gain.model';

export interface GainState {
  gain: Gain | null;
  isLoading: boolean;
  error?: string;
}

export const initialGainState: GainState = {
  gain: null,
  isLoading: false,
};
