import { createSlice } from '@reduxjs/toolkit';
import { initialPmdKpiMeanState } from '../states/pmdKpiMean/pmdKpiMean.state';
import {
  setResetPmdKpiMeanReducer,
  setRetrievePmdKpiMeanFailureReducer,
  setRetrievePmdKpiMeanReducer,
  setRetrievePmdKpiMeanSuccessReducer,
} from './pmd-kpi-mean.reducer';

export const pmdKpiMeanDtoSlice = createSlice({
  name: 'pmdKpiMean',
  initialState: initialPmdKpiMeanState,
  reducers: {
    setRetrievePmdKpiMean: setRetrievePmdKpiMeanReducer,
    setRetrievePmdKpiMeanSuccess: setRetrievePmdKpiMeanSuccessReducer,
    setRetrievePmdKpiMeanFailure: setRetrievePmdKpiMeanFailureReducer,
    setResetPmdKpiMean: setResetPmdKpiMeanReducer,
  },
});

export const {
  setRetrievePmdKpiMean,
  setRetrievePmdKpiMeanSuccess,
  setRetrievePmdKpiMeanFailure,
  setResetPmdKpiMean,
} = pmdKpiMeanDtoSlice.actions;

export const pmdKpiMeanDtoReducers = pmdKpiMeanDtoSlice.reducer;
