import { Reference } from '../../../../../corelogic/domain/models/reference/reference.model';
import ReferenceInput from '../formik/form';
import { referencesMapping } from '../uploadPopinFiles/parameterNameMapping';
import styles from './referenceField.module.scss';

type Props = {
  reference: Reference;
  referencesState: Reference[];
  setReferencesState: React.Dispatch<React.SetStateAction<Reference[] | undefined>>;
  disabled: boolean;
  parameterName: string;
};

const ReferenceField: React.FC<Props> = ({
  reference,
  referencesState,
  setReferencesState,
  disabled,
  parameterName,
}) => {
  return (
    <div className={styles.referenceValueAndUnit}>
      <div className={styles.parameterWithReferenceValueContainer}>
        {parameterName !== 'anti_icing_dew_point_temperature' && parameterName !== 'load_limit' && (
          <span style={{ color: 'red' }}> * </span>
        )}
        {referencesMapping[reference.name as keyof typeof referencesMapping]}
      </div>
      <ReferenceInput
        reference={reference}
        referencesState={referencesState}
        setReferencesState={setReferencesState}
        disabled={disabled}
      />
    </div>
  );
};

export default ReferenceField;
