import { createSlice } from '@reduxjs/toolkit';
import { initialSiteNamesIdsState } from '../states/siteNamesIds/siteNamesIds.states';
import {
  setRetrieveSiteNamesIdsFailureReducer,
  setRetrieveSiteNamesIdsReducer,
  setRetrieveSiteNamesIdsSuccessReducer,
} from './siteNamesIds.reducer';

export const siteNamesIdsSlice = createSlice({
  name: 'siteNamesIds',
  initialState: initialSiteNamesIdsState,
  reducers: {
    setRetrieveSiteNamesIds: setRetrieveSiteNamesIdsReducer,
    setRetrieveSiteNamesIdsSuccess: setRetrieveSiteNamesIdsSuccessReducer,
    setRetrieveSiteNamesIdsFailure: setRetrieveSiteNamesIdsFailureReducer,
  },
});

// by name not defined TBD

export const {
  setRetrieveSiteNamesIds,
  setRetrieveSiteNamesIdsFailure,
  setRetrieveSiteNamesIdsSuccess,
} = siteNamesIdsSlice.actions;

export const siteNamesIdsReducers = siteNamesIdsSlice.reducer;
