import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import {
  setUpdateSiteModelFailure,
  setUpdateSiteModelSuccess,
} from '../../../../redux/site/site.slice';
import { Site } from '../../../models/site/site.model';

export const updateSiteModelEpic = (
  action$: Observable<
    PayloadAction<{ siteId: string; modelId: string; status: boolean; effectiveDate: string }>
  >,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('site/setUpdateSiteModel'),
    switchMap(action => {
      return from(
        dependencies.siteGateway.updateSiteModel(
          action.payload.siteId,
          action.payload.modelId,
          action.payload.status,
          action.payload.effectiveDate,
        ),
      ).pipe(
        map((site: Site) => setUpdateSiteModelSuccess(site)),
        catchError(() =>
          of(
            setUpdateSiteModelFailure(
              `update site ${action.payload.siteId} model ${action.payload.modelId} has failed`,
            ),
          ),
        ),
      );
    }),
  );
