import { createSlice } from '@reduxjs/toolkit';
import { initialHiddenCurvesState } from '../states/hiddenCurve/hiddenCurve.state';
import { setHiddenCurvesReducer } from './hiddenCurve.reducers';

export const hiddenCurvesSlice = createSlice({
  name: 'hiddenCurves',
  initialState: initialHiddenCurvesState,
  reducers: {
    setHiddenCurves: setHiddenCurvesReducer,
  },
});

export const { setHiddenCurves } = hiddenCurvesSlice.actions;

export const hiddenCurvesReducers = hiddenCurvesSlice.reducer;
