import { createSlice } from '@reduxjs/toolkit';
import { initialStartDateState } from '../../states/currentTimeseries/startDate/startDate.state';
import { setStartDateReducer } from './startDate.reducer';

export const StartDateSlice = createSlice({
  name: 'startDate',
  initialState: initialStartDateState,
  reducers: {
    setStartDate: setStartDateReducer,
  },
});

export const { setStartDate } = StartDateSlice.actions;

export const StartDateReducers = StartDateSlice.reducer;
