/* eslint-disable prettier/prettier */
import { Card, CardContent, CardHeader, Divider, Grid, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../../constants/path';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setRetrieveSite } from '../../../../../corelogic/redux/site/site.slice';
import { CustomCard, TransparentTooltip, getLocalHours } from '../../../../../util/functionUtil';
import styles from './overview.module.scss';
import OverviewToolTip from './overviewTooltip/overviewTooltip';
import { setRetrieveOverview } from '../../../../../corelogic/redux/overview/overview.slice';
import { OverviewDTO } from '../../../../../corelogic/domain/models/overview/overview.dto';
import FortuitAlert from './fortuitAlert/fortuitAlert';
import { setRetrieveSiteFortuit } from '../../../../../corelogic/redux/siteFortuit/siteFortuit.slice';
import TabComponent from '../../components/tabComponent/tabComponent';

const Overview: FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDarkMode } = useSelector((state: RootState) => state.isDarkMode);
  const { siteNamesIds } = useSelector((state: RootState) => state.siteNamesIds);
  const { overview } = useSelector((state: RootState) => state.overview);
  const { fortuit } = useSelector((state: RootState) => state.fortuit);

  const ALERT_THRESHOLD = 8; //MW
  const [siteInFortuit, setSiteInFortuit] = useState<string[]>();
  const [openFortuit, setOpenFortuit] = useState<boolean>(false);

  const [selectedCountry, setSelectedCountry] = useState<string>('france');
  const fieldInTab: Record<string, string> = {
    france: intl.formatMessage({ id: 'country.france' }),
    belgium: intl.formatMessage({ id: 'country.belgium' }),
    spain: intl.formatMessage({ id: 'country.spain' }),
  };

  const onClickButton = async (siteId: string) => {
    dispatch(setRetrieveSite(siteId));
    navigate(PATHS.POWER_DISPATCH);
    localStorage.setItem('favouriteSite', siteId);
  };

  useEffect(() => {
    dispatch(setRetrieveOverview());
    dispatch(setRetrieveSiteFortuit());
    // Then dispatch every 5 minutes
    const intervalId = setInterval(() => {
      dispatch(setRetrieveOverview());
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Clear interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (fortuit) {
      let result: string[] = [];
      fortuit.forEach(fortuit => {
        if (fortuit.isFortuit) {
          result.push(siteNamesIds.find(s => s._id === fortuit.siteId)?.name ?? '');
        }
      });
      result = result.filter(site => !siteInFortuit?.includes(site));
      if (result.length > 0) {
        setSiteInFortuit(result);
      }
    }
  }, [fortuit]);

  useEffect(() => {
    if (siteInFortuit && siteInFortuit.length > 0) {
      setOpenFortuit(true);
    } else {
      setOpenFortuit(false);
    }
  }, [siteInFortuit]);

  const handleClose = () => {
    setOpenFortuit(false);
  };

  function getHeaderColor(gap: number | null): string {
    if (gap && Math.abs(gap) > ALERT_THRESHOLD) {
      return theme.palette.primary.main;
    } else {
      return theme.palette.backgroundLayer1active.main;
    }
  }

  function getTitleColor(gap: number | null): string {
    if (gap && Math.abs(gap) > ALERT_THRESHOLD) {
      return theme.palette.background.paper;
    } else {
      return theme.palette.text.primary;
    }
  }

  return (
    <CustomCard sx={{ margin: 4, padding: 4, alignItems: 'flex-start' }}>
      <CardHeader
        titleTypographyProps={{
          fontSize: 22,
          fontWeight: 'bold',
          fontFamily: 'Roboto',
        }}
        title={intl.formatMessage({ id: 'home.overview' })}
      />

      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 32,
          cursor: 'pointer',
          width: '100%',
        }}
      >
        <TabComponent
          selectedTab={selectedCountry}
          setSelectedTab={setSelectedCountry}
          fieldInTab={fieldInTab}
          buttonMessage={['france', 'belgium', 'spain']}
        ></TabComponent>
        <div className={styles.slotInfoContainer}>
          {openFortuit && (
            <FortuitAlert
              open={openFortuit}
              handleClose={handleClose}
              fortuitSites={siteInFortuit ?? []}
            />
          )}
          <Typography variant="body2">
            <FormattedMessage id="overview.info" />
          </Typography>
          <div className={styles.nextSlot}>
            <Typography variant="body2">
              <FormattedMessage id="overview.nextSlot" />
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {overview != undefined &&
                overview.length > 0 &&
                getLocalHours(overview[0]?.overviewData[0].timestamp)}
            </Typography>
          </div>
        </div>
        <Grid container columnSpacing={5} rowSpacing={7}>
          {overview
            ?.filter(overview => siteNamesIds.find(s => s._id == overview.siteId))
            .filter(overview => overview.group === selectedCountry)
            .sort((a: OverviewDTO, b: OverviewDTO): number => {
              return (
                siteNamesIds
                  .find(s => s._id === a.siteId)
                  ?.name.localeCompare(siteNamesIds.find(s => s._id === b.siteId)?.name ?? '') ?? 0
              );
            })
            .map(overview => (
              <Grid xs={4} key={overview.siteId} item>
                <TransparentTooltip
                  followCursor
                  title={<OverviewToolTip overviewData={overview.overviewData} />}
                >
                  <Card
                    onClick={() => onClickButton(overview.siteId)}
                    sx={{
                      borderRadius: '16px',
                      boxShadow: '0px 2px 4px 0px #00000010',
                      height: 'auto',
                      border: isDarkMode ? 'solid 1px #C3C5C550' : undefined,
                      ':hover': {
                        backgroundColor: theme.palette.backgroundBackground.main,
                      },
                    }}
                  >
                    <CardHeader
                      sx={{
                        backgroundColor: getHeaderColor(overview.overviewData[0]?.estimated_gap),
                        textAlign: 'left',
                      }}
                      titleTypographyProps={{
                        fontSize: 22,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        color: getTitleColor(overview.overviewData[0]?.estimated_gap),
                      }}
                      title={siteNamesIds.find(site => site._id === overview.siteId)?.name}
                    ></CardHeader>
                    <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                      <div className={styles.primaryValueContainer}>
                        <Typography fontWeight={'bold'} variant="subtitle2">
                          <FormattedMessage id="overview.gap" />
                        </Typography>
                        <div className={styles.primaryValueAndUnit}>
                          <Typography variant="h5" fontWeight={'bold'}>
                            {overview.overviewData[0]?.estimated_gap ?? 'N/A'}
                          </Typography>
                          <Typography color={theme.palette.text.secondary}>
                            <FormattedMessage id="unit.mw" />
                          </Typography>
                        </div>
                      </div>
                      <Divider
                        sx={{ margin: '8px' }}
                        variant="fullWidth"
                        orientation="vertical"
                        flexItem
                      />
                      <div className={styles.secondaryValueContainer}>
                        <div className={styles.SecondaryValueAndUnit}>
                          <FormattedMessage id="overview.forecast" />
                          <div className={styles.valueAndUnit}>
                            <Typography fontWeight={'bold'}>
                              {overview.overviewData[0]?.net_power_p2m_forecast ?? 'N/A'}
                            </Typography>
                            <Typography color={theme.palette.text.secondary}>
                              <FormattedMessage id="unit.mw" />
                            </Typography>
                          </div>
                        </div>
                        <div className={styles.SecondaryValueAndUnit}>
                          <FormattedMessage id="overview.running" />
                          <div className={styles.valueAndUnit}>
                            <Typography fontWeight={'bold'}>
                              {overview.overviewData[0]?.running_program ?? 'N/A'}
                            </Typography>
                            <Typography color={theme.palette.text.secondary}>
                              <FormattedMessage id="unit.mw" />
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </TransparentTooltip>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </CustomCard>
  );
};

export default Overview;
