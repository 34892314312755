import { DailyCapacity } from '../../../../corelogic/domain/models/dailyCapacity/dailyCapacity.model';

export const DailyCapacitiesFixture: DailyCapacity[] = [
  {
    fileName: '10_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-10T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '11_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-11T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '12_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-12T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '13_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-13T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '14_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-14T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '15_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-15T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '16_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-16T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '17_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-17T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '18_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-18T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '19_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-19T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '20_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-20T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '21_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-21T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '22_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-22T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '23_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-23T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '24_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-24T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '25_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-25T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '26_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-26T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '27_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-27T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '28_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-28T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '29_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-29T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '30_11_2023_daily_capacity.json',
    creationDate: new Date('2023-11-30T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '01_12_2023_daily_capacity.json',
    creationDate: new Date('2023-12-01T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '02_12_2023_daily_capacity.json',
    creationDate: new Date('2023-12-02T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
  {
    fileName: '03_12_2023_daily_capacity.json',
    creationDate: new Date('2023-12-03T22:00:00.000Z'),
    path: '/path/to/file',
    siteId: '1',
    type: 'json',
    fileSiteName: 'testsite_10_11_2023_daily_capacity.json',
    modificationDate: new Date('2023-11-10T22:00:00.000Z'),
    modificationListAuthor: ['lambda'],
  },
];
