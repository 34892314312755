import { createSlice } from '@reduxjs/toolkit';
import { initialSiteNameBooleanState } from '../states/updateSiteName/update-site-name-boolean.state';

import { setIsUpdatedSiteNameReducer } from './is-updated-site-name.reducers';

export const isUpdatedSiteNameSlice = createSlice({
  name: 'isUpdatedSiteName',
  initialState: initialSiteNameBooleanState,
  reducers: {
    setIsUpdatedSiteName: setIsUpdatedSiteNameReducer,
  },
});

export const { setIsUpdatedSiteName } = isUpdatedSiteNameSlice.actions;

export const isUpdatedSiteNameReducers = isUpdatedSiteNameSlice.reducer;
