/* eslint-disable prettier/prettier */
import { addHours, isValid } from 'date-fns';
import format from 'date-fns/format';

export const getFormattedDate = (date: Date): string => {
  if (isValid(date)) {
    return format(date, 'dd.MM.yyyy');
  } else {
    return 'Invalid Date';
  }
};

export const getFormattedDateWithMinute = (date: Date): string => {
  if (isValid(date)) {
    return format(date, 'dd/MM/yyyy HH:mm');
  } else {
    return 'Invalid Date';
  }
};

export const getFormattedDateISO = (date: Date): string => {
  if (isValid(date)) {
    return format(date, 'yyyy-MM-dd');
  } else {
    return 'Invalid Date';
  }
};

export const getFormattedDateTimeISO = (date: Date): string => {
  if (isValid(date)) {
    return format(date, 'yyyy-MM-dd HH:mm');
  } else {
    return 'Invalid Date';
  }
};

export const getDateWithCurrentTimezone = (date: string, currentTimeZone: number): string => {
  return addHours(new Date(date), currentTimeZone).toISOString();
};

export const splitDateIntoEqualIntervals = (
  startDate: Date,
  endDate: Date,
  numberOfIntervals: number,
): { start: Date; end: Date }[] => {
  const intervalLength = (endDate.getTime() - startDate.getTime()) / numberOfIntervals;
  const dateRange = [];
  let i = numberOfIntervals;
  while (i-- > 0) {
    dateRange.push({
      start: new Date(startDate.getTime() + i * intervalLength),
      end: new Date(startDate.getTime() + (i + 1) * intervalLength),
    });
  }
  return dateRange;
};


export const convertMinutesToTime = (minutes: number | null) => {
  if (minutes === null) { return null; }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours.toString().padStart(2, '0')}h${remainingMinutes.toString().padStart(2, '0')}`;
}
