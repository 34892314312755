import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import {
  setUpdateSiteCurveFailure,
  setUpdateSiteCurveSuccess,
} from '../../../../redux/site/site.slice';
import { Site } from '../../../models/site/site.model';

export const updateSiteCurveEpic = (
  action$: Observable<PayloadAction<{ siteId: string; updateBody: FormData }>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('site/setUpdateSiteCurve'),
    switchMap(action => {
      return from(
        dependencies.siteGateway.updateSiteCurve(action.payload.siteId, action.payload.updateBody),
      ).pipe(
        map((site: Site) => setUpdateSiteCurveSuccess(site)),
        catchError(() =>
          of(setUpdateSiteCurveFailure(`Update site ${action.payload.siteId} has failed`)),
        ),
      );
    }),
  );
