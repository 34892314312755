import { SiteFortuitDTO } from '../../../domain/models/site/siteFortuit.dto';

export interface SiteFortuitState {
  fortuit: SiteFortuitDTO[] | null;
  isLoading: boolean;
  error?: string;
}

export const initialSiteFortuitState: SiteFortuitState = {
  fortuit: [],
  isLoading: false,
};
