import { PayloadAction } from '@reduxjs/toolkit';
import { AllGainsState } from '../states/allGains/allGains.state';
import { Gain } from '../../domain/models/gain/gain.model';

export const setRetrieveAllGainsReducer = (
  state: AllGainsState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
) => {
  state.isLoading = true;
};

export const setRetrieveAllGainsSuccessReducer = (
  state: AllGainsState,
  action: PayloadAction<Gain[]>,
) => {
  state.isLoading = false;
  state.gains = action.payload;
};

export const setRetrieveAllGainsFailureReducer = (
  state: AllGainsState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
  state.gains = [];
};
