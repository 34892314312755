import { OverviewDTO } from '../../../corelogic/domain/models/overview/overview.dto';
import { ApiOverviewGateway } from './api-overview.gateways';

import MOCKED_OVERVIEW from '../../../test/fixture/overview.json';
import MOCKED_FORECAST_INFORMATION from '../../../test/fixture/forecastInformation.json';
import { ForecastInformationDTO } from '../../../corelogic/domain/models/overview/forecastInformation.dto';

export class InMemoryOverviewGateway implements ApiOverviewGateway {
  expectedOverview: OverviewDTO[] = MOCKED_OVERVIEW.map((overview: any) => ({
    ...overview,
    timestamp: new Date(overview.timestamp),
  })) as OverviewDTO[];

  expectedForecastInformation: ForecastInformationDTO[] = MOCKED_FORECAST_INFORMATION;

  retrieveOverview: () => Promise<OverviewDTO[]> = async (): Promise<OverviewDTO[]> => {
    return this.expectedOverview;
  };

  retrieveForecastInformation: (
    siteId: string,
    startDate: string,
    endDate: string,
  ) => Promise<ForecastInformationDTO[]> = async (): Promise<ForecastInformationDTO[]> => {
    return new Promise<ForecastInformationDTO[]>(() => {
      return this.expectedForecastInformation;
    });
  };
}
