export interface DarkModeState {
  isDarkMode: boolean;
  isLoading: boolean;
  error?: string;
}

export const initialIsDarkModeState: DarkModeState = {
  isDarkMode: localStorage.getItem('darkMode') === 'true',
  isLoading: false,
};
