import { SiteNameIdDto } from '../../../domain/models/site/siteNameId.dto';

export interface SiteNamesIdsState {
  isLoading: boolean;
  siteNamesIds: SiteNameIdDto[];
  error?: string;
}

export const initialSiteNamesIdsState: SiteNamesIdsState = {
  isLoading: false,
  siteNamesIds: [],
};
