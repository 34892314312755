import { createSlice } from '@reduxjs/toolkit';

import { initialIsAdminState } from '../states/isAdmin/is-admin.state';
import { setIsAdminReducer } from './is-admin.reducers';

export const isAdminSlice = createSlice({
  name: 'isAdmin',
  initialState: initialIsAdminState,
  reducers: {
    setIsAdmin: setIsAdminReducer,
  },
});

export const { setIsAdmin } = isAdminSlice.actions;

export const isAdminReducer = isAdminSlice.reducer;
