export interface AdminState {
  isAdmin?: boolean;
  isLoading: boolean;
  error?: string;
}

export const initialIsAdminState: AdminState = {
  isAdmin: undefined,
  isLoading: false,
};
