import { PayloadAction } from '@reduxjs/toolkit';
import { PmdKpiMeanDto } from '../../domain/models/pmdKpiMean/pmdKpiMeanDto.model';
import { PmdKpiMeanState } from '../states/pmdKpiMean/pmdKpiMean.state';

export const setRetrievePmdKpiMeanReducer = (
  state: PmdKpiMeanState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<string | null>,
) => {
  state.isLoadingPmdKpiMean = true;
};

export const setRetrievePmdKpiMeanSuccessReducer = (
  state: PmdKpiMeanState,
  action: PayloadAction<PmdKpiMeanDto[]>,
) => {
  state.isLoadingPmdKpiMean = false;
  state.pmdKpiMeanDto = action.payload;
};

export const setRetrievePmdKpiMeanFailureReducer = (
  state: PmdKpiMeanState,
  action: PayloadAction<string>,
) => {
  state.isLoadingPmdKpiMean = false;
  state.error = action.payload;
  state.pmdKpiMeanDto = [];
};

export const setResetPmdKpiMeanReducer = (state: PmdKpiMeanState) => {
  state.pmdKpiMeanDto = [];
  state.isLoadingPmdKpiMean = false;
};
