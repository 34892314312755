import { InMemoryUserAuthenticationGateway } from '../adapters/secondaries/gateways/authentification/in-memory-authentication.gateway';
import { MsalAuthenticationGateway } from '../adapters/secondaries/gateways/authentification/msal-authentication.gateway';
import { InMemoryStorageGateway } from '../adapters/secondaries/gateways/browser-storage/in-memory-local-storage.gateway';
import { LocalStorageGateway } from '../adapters/secondaries/gateways/browser-storage/local-storage.gateway';
import { MsalAuthenticationProvider } from '../adapters/secondaries/providers/authentication/msal-authentication.provider';
import { SuccessAuthenticationProvider } from '../adapters/secondaries/providers/authentication/success-authentication.provider';
import { AuthenticationGateway } from '../corelogic/domain/gateways/authentification.gateways';
import { BrowserStorageGateway } from '../corelogic/domain/gateways/browser-storage.gateway';
import { Dependencies } from './dependencies.interface';
import { inMemoryDependencies } from './in-memory.dependencies';
import { productionDependencies } from './production.dependencies';

export let dependencies: Dependencies;
export let authenticationConfiguration: AuthenticationGateway;
export let browserStorageConfiguration: BrowserStorageGateway;

if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'development') {
  dependencies = productionDependencies;
  authenticationConfiguration = new MsalAuthenticationGateway(new MsalAuthenticationProvider());
  browserStorageConfiguration = new LocalStorageGateway();
} else {
  dependencies = inMemoryDependencies;
  authenticationConfiguration = new InMemoryUserAuthenticationGateway(
    new SuccessAuthenticationProvider(),
  );
  browserStorageConfiguration = new InMemoryStorageGateway();
}
