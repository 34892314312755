import { createTheme } from '@mui/material';

const { palette } = createTheme();

declare module '@mui/material/styles' {
  interface Palette {
    backgroundLayer1active: Palette['primary'];
    backgroundLayer1: Palette['primary'];
    borderStrong02: Palette['primary'];
    backgroundBackground: Palette['primary'];
    pmd: Palette['primary'];
    actionButton: Palette['primary'];
  }

  interface PaletteOptions {
    backgroundLayer1active?: PaletteOptions['primary'];
    backgroundLayer1: PaletteOptions['primary'];
    borderStrong02: Palette['primary'];
    backgroundBackground: Palette['primary'];
    pmd: Palette['primary'];
    actionButton: Palette['primary'];
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    actionButton: true;
  }
}

const typography = {
  fontFamily: ['Roboto-bold', 'Roboto', 'Arial', 'Helvetica', 'sans-serif'].join(','),
};

export const lightTheme = createTheme({
  typography: typography,
  palette: {
    primary: { main: '#ED0000', dark: '#ED0000' },
    actionButton: palette.augmentColor({ color: { main: '#222B2D' } }),
    background: { default: '#F7F9FA' },
    mode: 'light',
    backgroundLayer1active: palette.augmentColor({ color: { main: '#EBEEEF' } }),
    backgroundLayer1: palette.augmentColor({ color: { main: '#FFFFFF' } }),
    borderStrong02: palette.augmentColor({ color: { main: '#C3C5C5' } }),
    backgroundBackground: palette.augmentColor({ color: { main: '#F7F9FA' } }),
    pmd: palette.augmentColor({ color: { main: '#D2E9E7' } }),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        outlined: {
          '&:hover': { backgroundColor: '#F7F9FA', color: '#ED0000', border: '1px solid #ED0000' },
        },
        root: {
          '&:hover': { color: '#ED0000', backgroundColor: 'white' },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'red' },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
        region: {
          backgroundColor: '#F7F9FA',
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  typography: typography,
  palette: {
    primary: { main: '#80CDFF', dark: '#80CDFF' },
    actionButton: palette.augmentColor({ color: { main: '#80CDFF' } }),
    background: { default: '#12121C', paper: '#2C2C35' },
    mode: 'dark',
    backgroundLayer1active: palette.augmentColor({ color: { main: '#32323A' } }),
    backgroundLayer1: palette.augmentColor({ color: { main: '#37373f' } }),
    borderStrong02: palette.augmentColor({ color: { main: '#FFFFFF' } }),
    backgroundBackground: palette.augmentColor({ color: { main: '#12121C' } }),
    pmd: palette.augmentColor({ color: { main: '#8395A7' } }),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          '&:hover': { backgroundColor: '#2C2C35', color: '#80CDFF' },
        },
        outlined: {
          '&:hover': { backgroundColor: '#717374', color: '#80CDFF', border: '1px solid #80CDFF' },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { color: 'white', fontWeight: 'bold' },
        asterisk: { color: 'red' },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#2C2C35',
        },
        region: {
          backgroundColor: '#12121C',
        },
      },
    },
  },
});
