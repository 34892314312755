import { addDays, startOfDay, subDays } from 'date-fns';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setStartDate } from '../../../../../corelogic/redux/currentTimeseries/startDate/startDate.slice';
import { setRetrieveKpiImbalance } from '../../../../../corelogic/redux/kpiImbalance/kpi-imbalance.slice';
import { NUMBER_OF_DAYS } from '../graph/timeseriesGraph/constant';
import { DatePicker, PickersActionBarProps } from '@mui/x-date-pickers';
import { DialogActions, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type Props = {
  setEndDate: React.Dispatch<React.SetStateAction<Date | null | undefined>>;
};
const CustomDatePicker: React.FC<Props> = ({ setEndDate }) => {
  const { startDate } = useSelector((state: RootState) => state.startDate);
  const dispatch = useDispatch();

  const handleDateChange = (date: Date | null) => {
    date = date ? startOfDay(date) : null;
    if (date !== null) {
      dispatch(setStartDate(date.toISOString()));
      setEndDate(addDays(date, NUMBER_OF_DAYS));
      const storedSiteId = localStorage.getItem('favouriteSite');
      if (storedSiteId) {
        dispatch(
          setRetrieveKpiImbalance({
            siteId: storedSiteId,
            startDate: date.toISOString(),
            endDate: addDays(date, NUMBER_OF_DAYS).toISOString(),
          }),
        );
      }
    }
  };

  function CustomActionBar(props: PickersActionBarProps) {
    const { className } = props;

    return (
      <DialogActions className={className}>
        <Button onClick={() => handleDateChange(subDays(startOfDay(new Date()), 6))}>
          <FormattedMessage id="button.today" />
        </Button>
      </DialogActions>
    );
  }

  return (
    <DatePicker
      value={new Date(startDate)}
      onChange={newValue => handleDateChange(newValue)}
      slots={{
        actionBar: CustomActionBar,
      }}
      slotProps={{
        textField: { size: 'small' },
        openPickerButton: { color: 'primary' },
        actionBar: {
          actions: ['today'],
        },
      }}
      maxDate={new Date()}
      sx={{
        '.MuiInputBase-root': {
          borderRadius: '8px',
        },
      }}
    />
  );
};

export default CustomDatePicker;
