import { createSlice } from '@reduxjs/toolkit';
import { setIsDarkModeReducer } from './is-dark-mode.reducers';
import { initialIsDarkModeState } from '../states/isDarkMode/is-dark-mode.state';

export const isDarkModeSlice = createSlice({
  name: 'isDarkMode',
  initialState: initialIsDarkModeState,
  reducers: {
    setIsDarkMode: setIsDarkModeReducer,
  },
});

export const { setIsDarkMode } = isDarkModeSlice.actions;

export const isDarkModeReducer = isDarkModeSlice.reducer;
