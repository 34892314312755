import { createSlice } from '@reduxjs/toolkit';
import { initialIsSuperAdminModeState } from '../states/isSuperAdminMode/is-super-admin-mode.state';
import { setIsSuperAdminModeReducer } from './is-super-admin-mode.reducers';

export const isSuperAdminModeSlice = createSlice({
  name: 'isSuperAdminMode',
  initialState: initialIsSuperAdminModeState,
  reducers: {
    setIsSuperAdminMode: setIsSuperAdminModeReducer,
  },
});

export const { setIsSuperAdminMode } = isSuperAdminModeSlice.actions;

export const isSuperAdminModeReducer = isSuperAdminModeSlice.reducer;
