/* eslint-disable prettier/prettier */
import { SiteNameIdDto } from '../../../../corelogic/domain/models/site/siteNameId.dto';

export const siteNamesIdsFixture: SiteNameIdDto[] = [
  {
    _id: 'a67a1035-12a6-4a87-8922-df1fa8475720',
    name: 'Bayet',
  },
  {
    _id: '23745',
    name: 'siteName1',
  },
  {
    _id: '4876243',
    name: 'siteName2',
  },
  {
    _id: '786528',
    name: 'siteName3',
  },
];

export const siteNamesIdsFixtureFailure: SiteNameIdDto[] = [];
