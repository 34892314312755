import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import {
  setRetrieveMLFromS3Failure,
  setRetrieveMLFromS3Success,
} from '../../../../redux/retrieveMLS3/retrieveMLFromS3.slice';

export const retrieveMlModelEpic = (
  action$: Observable<PayloadAction<string>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('retrieveMLFromS3/setRetrieveMLFromS3'),
    switchMap(action => {
      return from(dependencies.siteGateway.retrieveMlFileFromS3(action.payload)).pipe(
        map((fileName: string) => setRetrieveMLFromS3Success({ url: fileName || '' })),
        catchError(() =>
          of(setRetrieveMLFromS3Failure(`Retrieving mlModel for ${action.payload} has failed`)),
        ),
      );
    }),
  );
