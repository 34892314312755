import { PayloadAction } from '@reduxjs/toolkit';
import { Site, SiteDeletion } from '../../domain/models/site/site.model';
import { SiteState, initialSiteState } from '../states/site/site.states';
import { PiTag } from '../../domain/models/pitag/pitag.model';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setRetrieveSiteReducer = (state: SiteState, action: PayloadAction<string>) => {
  state.isLoading = true;
};

export const setRetrieveSiteSuccessReducer = (state: SiteState, action: PayloadAction<Site>) => {
  state.isLoading = false;
  state.site = {
    ...action.payload,
    tags: action.payload.tags.sort((a: PiTag, b: PiTag) => a.name.localeCompare(b.name)),
  };
};

export const setRetrieveSiteFailureReducer = (state: SiteState, action: PayloadAction<string>) => {
  state.isLoading = false;
  state.error = action.payload;
  state.site = {
    _id: '',
    correction_parameters: [],
    name: '',
    siteGroupReferences: [],
    identity: {
      manufacturerName: '',
      lastRevisionDate: '',
      powerOutput: 0,
      heatRate: 0,
      netHeatRate: 0,
      igvPosition: 0,
      lastMajorMaintenance: '',
      pMin: null,
      pMinPercentage: null,
      floor: null,
      country: null,
    },
    tags: [],
    failures: [],
    mlModel: [],
    admins: [],
    dataExclusion: [],
  };
};

export const setCurrentSiteReducer = (state: SiteState, action: PayloadAction<Site>) => {
  state.isLoading = false;
  state.site = action.payload;
};

export const setUpdateSiteCurveReducer = (
  state: SiteState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<{ siteId: string; updateBody: FormData }>,
) => {
  state.isLoading = true;
};

export const setUpdateSiteCurveSuccessReducer = (state: SiteState, action: PayloadAction<Site>) => {
  state.isLoading = false;
  state.site = action.payload;
};

export const setUpdateSiteCurveFailureReducer = (
  state: SiteState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const setUpdateSiteParameterReducer = (
  state: SiteState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<{ siteId: string; updateBody: Partial<Site> }>,
) => {
  state.isLoading = true;
};

export const setUpdateSiteParameterSuccessReducer = (
  state: SiteState,
  action: PayloadAction<Site>,
) => {
  state.isLoading = false;
  state.site = action.payload;
};

export const setUpdateSiteParameterFailureReducer = (
  state: SiteState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const setUpdateSiteModelReducer = (
  state: SiteState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<{
    siteId: string;
    modelId: string;
    status: boolean;
    effectiveDate: string;
  }>,
) => {
  state.isLoading = true;
};

export const setUpdateSiteModelSuccessReducer = (state: SiteState, action: PayloadAction<Site>) => {
  state.isLoading = false;
  state.site = action.payload;
};

export const setUpdateSiteModelFailureReducer = (
  state: SiteState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const setCreateSiteReducer = (
  state: SiteState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<{ siteName: string }>,
) => {
  state.isLoading = true;
};

export const setCreateSiteSuccessReducer = (state: SiteState, action: PayloadAction<Site>) => {
  state.isLoading = false;
  state.site = action.payload;
};

export const setCreateSiteFailureReducer = (state: SiteState, action: PayloadAction<string>) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const setDeleteSiteReducer = (
  state: SiteState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<{ siteId: string }>,
) => {
  state.isLoading = true;
};

export const setDeleteSiteSuccessReducer = (
  state: SiteState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<SiteDeletion>,
) => {
  state.isLoading = false;
  state.site = initialSiteState.site;
};

export const setDeleteSiteFailureReducer = (
  state: SiteState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
};
