import { subDays } from 'date-fns';

export interface StartDateState {
  startDate: string;
  isLoading: boolean;
  error?: string;
}

export const initialStartDateState: StartDateState = {
  startDate: subDays(new Date().setHours(0, 0, 0, 0), 6).toISOString(),
  isLoading: false,
};
