import { InputBase, InputBaseProps, TextFieldProps, styled } from '@mui/material';
import {
  GRID_DATETIME_COL_DEF,
  GridColTypeDef,
  GridRenderEditCellParams,
  GridValueFormatterParams,
  useGridApiContext,
} from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { getFormattedDateWithMinute } from '../../../../../../../../../helpers/dateFunction';
import { getDateFilterOperators } from '../filterFunction';

const GridEditDateInput = styled(InputBase)({
  fontSize: 'inherit',
  padding: '0 9px',
});

function WrappedGridEditDateInput(props: TextFieldProps) {
  const { InputProps, ...other } = props;
  return <GridEditDateInput fullWidth {...InputProps} {...(other as InputBaseProps)} />;
}

const GridEditDateCell = ({
  id,
  field,
  value,
}: GridRenderEditCellParams<any, Date | string | null>) => {
  const apiRef = useGridApiContext();

  const handleChange = (newValue: unknown) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <DatePicker
      value={value}
      autoFocus
      onChange={handleChange}
      slots={{ textField: WrappedGridEditDateInput }}
    />
  );
};

export const dateTimeColumnType: GridColTypeDef<Date, string> = {
  ...GRID_DATETIME_COL_DEF,
  resizable: false,
  renderEditCell: params => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getDateFilterOperators(),
  valueFormatter: (params: GridValueFormatterParams<Date>) => {
    if (params.value instanceof Date) {
      return getFormattedDateWithMinute(params.value);
    }
    return '';
  },
};
