import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { List, ListItem, ListItemButton, ListItemText, Tooltip, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { tabWithSubtab } from '../../type';
import styles from './accordion.module.scss';
import { subDays } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
type Props = {
  tabName: string;
  icon: string;
  subtabList: tabWithSubtab[];
  activeTabName: string | null;
  activeSubtabName: string | null;
  onTabAndSubtabClick: (tabName: string, subtabName: string) => void;
};

const AccordionComponent: FC<Props> = ({
  tabName,
  icon,
  subtabList,
  activeTabName,
  activeSubtabName,
  onTabAndSubtabClick,
}) => {
  const theme = useTheme();
  const [mlAlert, setMlAlert] = useState(false);
  const [exclusionAlert, setExclusionAlert] = useState(false);
  const { site } = useSelector((state: RootState) => state.site);
  const intl = useIntl();

  useEffect(() => {
    let exclusionAlert = false;
    let mlAlert = false;
    site.dataExclusion.forEach(data => {
      if (!data.endDate && subDays(new Date(), 30).getTime() > new Date(data.startDate).getTime()) {
        exclusionAlert = true;
      }
    });
    site.mlModel.forEach(data => {
      if (data.status === null) {
        mlAlert = true;
      }
    });
    setExclusionAlert(exclusionAlert);
    setMlAlert(mlAlert);
  }, [site]);

  return (
    <div>
      <Accordion elevation={0} defaultExpanded={true}>
        <AccordionSummary
          classes={{ root: styles.muiAccordionSummaryroot }}
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <div
            style={{ color: activeTabName === tabName ? theme.palette.primary.main : undefined }}
            className={clsx(
              styles.iconAndTabContainer,
              activeTabName === tabName ? styles.tabContainer : undefined,
            )}
          >
            <span className="material-icons-outlined">
              <FormattedMessage id={icon} />
            </span>
            <Typography style={{ fontWeight: 700, fontSize: '14px' }}>{tabName}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: styles.muiAccordionDetailsroot }}>
          <Typography component="span" style={{ width: '100%' }}>
            <List classes={{ root: styles.muiListRoot }}>
              {subtabList.map(subtab => (
                <ListItem
                  style={{
                    borderLeftColor:
                      activeTabName === tabName && activeSubtabName === subtab.subTabName
                        ? theme.palette.primary.main
                        : theme.palette.background.default,
                    borderLeftWidth: '4px',
                    borderLeftStyle: 'solid',
                  }}
                  key={subtab.subTabName}
                  disablePadding
                  onClick={() => onTabAndSubtabClick(tabName, subtab.subTabName)}
                >
                  <Link
                    style={{ textDecoration: 'none', color: 'inherit', width: 'inherit' }}
                    to={subtab.path}
                  >
                    <ListItemButton>
                      <ListItemText
                        primaryTypographyProps={{
                          style: { fontSize: '14px', fontFamily: 'Roboto' },
                        }}
                        primary={intl.formatMessage({ id: 'menu.' + subtab.subTabName })}
                      />
                      {subtab.path == '/ml-model' && mlAlert && (
                        <Tooltip title={intl.formatMessage({ id: 'home.alertModel' })}>
                          <ErrorOutlineOutlinedIcon />
                        </Tooltip>
                      )}
                      {subtab.path == '/data-exclusion' && exclusionAlert && (
                        <Tooltip title={intl.formatMessage({ id: 'home.alertExclusion' })}>
                          <ErrorOutlineOutlinedIcon />
                        </Tooltip>
                      )}
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </List>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionComponent;
