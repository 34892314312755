import { createSlice } from '@reduxjs/toolkit';

import { initialContextCurrentMenuStatusState } from '../states/menuStatus/context-current-menu-status.state';
import { setContextCurrentMenuStatusReducer } from './current-menu-status.reducers';

export const contextMenuStatusSlice = createSlice({
  name: 'currentMenuStatus',
  initialState: initialContextCurrentMenuStatusState,
  reducers: {
    setContextCurrentMenuStatus: setContextCurrentMenuStatusReducer,
  },
});

export const { setContextCurrentMenuStatus } = contextMenuStatusSlice.actions;

export const contextCurrentMenuStatusReducers = contextMenuStatusSlice.reducer;
