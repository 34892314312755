enum BrowserCacheLocation {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
  MemoryStorage = 'memoryStorage',
}
interface AuthConfiguration {
  auth: {
    clientId: string;
    authority?: string;
  };
  cache?: {
    cacheLocation: BrowserCacheLocation;
    secureCookies: boolean;
  };
  scopes: string[];
}

export const msalConfig: AuthConfiguration = {
  auth: {
    clientId: process.env.REACT_APP_AUTHENTICATION_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_AUTHENTICATION_AUTHORITY,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    secureCookies: true,
  },
  scopes: [process.env.REACT_APP_AUTHENTICATION_SCOPE_URL ?? ''],
};
