import { PayloadAction } from '@reduxjs/toolkit';
import { ForecastInformationDTO } from '../../domain/models/overview/forecastInformation.dto';
import { ForecastInformationState } from '../states/forecastInformation/forecastInformation.state';

export const setRetrieveForecastInformationReducer = (
  state: ForecastInformationState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<{ siteId: string; startDate: string; endDate: string }>,
) => {
  state.isLoading = true;
};

export const setRetrieveForecastInformationSuccessReducer = (
  state: ForecastInformationState,
  action: PayloadAction<ForecastInformationDTO[]>,
) => {
  state.isLoading = false;
  state.forecastInformation = action.payload;
};

export const setRetrieveForecastInformationFailureReducer = (
  state: ForecastInformationState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
  state.forecastInformation = [];
};

export const setResetForecastInformationReducer = (state: ForecastInformationState) => {
  state.forecastInformation = [];
  state.isLoading = false;
};
