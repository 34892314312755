export const GENERAL_DASHBOARD_PATHS = {
  POWER: '/power',
  EFFICIENCY: '/efficiency',
} as const;

const POWER2MARKET_PATHS = {
  HOME: '/',
  MANUFACTURER_INFORMATION: '/manufacturer-information',
  METROSCOPE_FAILURE: '/metroscope-failure',
  TAG_MAPPING: '/tag-mapping',
  DATA_EXCLUSION: '/data-exclusion',
  ADMIN: '/admin',
  ML_MODEL: '/ml-model',
  GENERAL_DASHBOARD: '/general-dashboard',
  POWER: `/general-dashboard${GENERAL_DASHBOARD_PATHS.POWER}`,
  EFFICIENCY: `/general-dashboard${GENERAL_DASHBOARD_PATHS.EFFICIENCY}`,
  DAILY_CAPACITY: `/daily-capacity`,
  GAINS: `/gains`,
  OVERVIEW: `/overview`,
  POWER_DISPATCH: `/overview/dispatch`,
} as const;

export const PATHS = {
  ...POWER2MARKET_PATHS,
};

export type PATH = (typeof PATHS)[keyof typeof PATHS];

export const METROSCOPE_PATH = 'https://prod.total.metroscope.tech';
