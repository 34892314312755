export interface ContextCurrentMenuStatusState {
  menuStatus: boolean;
  isLoading: boolean;
  error?: string;
}

export const initialContextCurrentMenuStatusState: ContextCurrentMenuStatusState = {
  menuStatus: true,
  isLoading: false,
};
