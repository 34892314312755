import {
  Button,
  ButtonProps,
  Card,
  CardProps,
  Switch,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
  TooltipProps,
  alpha,
  styled,
  tooltipClasses,
} from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { isNull } from 'lodash';

export const findMinMax = (
  numbers?: number[],
): { minXaxisValue: number; maxXaxisValue: number } => {
  if (!numbers || numbers.length === 0) {
    return { minXaxisValue: 0, maxXaxisValue: 0 };
  }
  const minXaxisValue = Math.min(...numbers);
  const maxXaxisValue = Math.max(...numbers);
  return { minXaxisValue, maxXaxisValue };
};

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const CustomTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
}));

export const CustomCard = styled(Card, {
  shouldForwardProp: prop => prop !== 'boxShadow',
})<CardProps & { boxShadow?: string; border?: string }>(({ boxShadow, border }) => ({
  borderRadius: '16px',
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  alignItems: 'center',
  border: border ?? '1px solid',
  borderColor: '#C3C5C5',
  boxShadow: boxShadow === 'unset' ? 'unset' : undefined,
}));

export const getLocalHours = (date: Date): string => {
  const language = window.localStorage.getItem('language');
  let timestamp;

  if (language === 'en')
    timestamp = date
      .toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true,
      })
      .toLocaleLowerCase();
  else timestamp = date.getHours().toString() + 'h';
  return timestamp;
};

export const isNullOrUndefined = (value: any): boolean => {
  return isNull(value) || value === undefined;
};

export const CustomButton = styled(Button)<ButtonProps>(() => ({
  textTransform: 'none',
  borderRadius: '32px',
  boxShadow: 'none',
}));

export const TransparentTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0,0,0,0)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
  },
}));

const ODD_OPACITY = 0.2;
export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.backgroundBackground.main,
    '&:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.backgroundBackground.dark,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: theme.palette.backgroundBackground.main,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.backgroundBackground.dark,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#12121c' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));
