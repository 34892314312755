import { KpiImbalance } from '../../../domain/models/kpiImbalance/kpiImbalance.model';

export interface KpiImbalanceState {
  progress: number;
  kpiImbalance: KpiImbalance[];
  isLoading: boolean;
  error?: string;
}

export const initialKpiImbalanceState: KpiImbalanceState = {
  isLoading: false,
  progress: 0,
  kpiImbalance: [],
};
