/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';
import { initialCurrentProductionKpiCurveNameState } from '../../states/currentTimeseries/currentKpiCurveName/currentKpiCurveName.state';
import { setCurrentProductionKpiCurveNameReducer } from './currentKpiCurveName.reducers';

export const currentProductionKpiCurveNameSlice = createSlice({
    name: 'currentProductionKpiCurveName',
    initialState: initialCurrentProductionKpiCurveNameState,
    reducers: {
        setCurrentProductionKpiCurveName: setCurrentProductionKpiCurveNameReducer,
    },
});

export const { setCurrentProductionKpiCurveName } = currentProductionKpiCurveNameSlice.actions;

export const currentProductionKpiCurveNameReducers = currentProductionKpiCurveNameSlice.reducer;
