import { CardContent } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setRetrieveDailyCapacities } from '../../../../../corelogic/redux/dailyCapacities/dailyCapacities.slice';
import { CustomCard } from '../../../../../util/functionUtil';
import DailyCapacityTable from './component/MlModelTable/dailyCapacityTable';
import styles from './dailyCapacity.module.scss';

const DailyCapacity: FC = () => {
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const { site } = useSelector((state: RootState) => state.site);
  const dispatch = useDispatch();
  dispatch(setRetrieveDailyCapacities(site._id));

  return (
    <div className={clsx(styles.dailyCapacityPageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined">
        <CardContent className={styles.parent_gap_container}>
          <div className={styles.daily_capacity_title}>
            <h1>
              <FormattedMessage id="home.dailyCapacity" />
            </h1>
          </div>
          <div className={styles.text}>
            <FormattedMessage id="dailyCapacity.explanation" />
          </div>
          <DailyCapacityTable />
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default DailyCapacity;
