/* eslint-disable prettier/prettier */
import clsx from 'clsx';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Curve } from '../../../../../corelogic/domain/models/curve/curve.model';
import { setRetrieveMLFromS3 } from '../../../../../corelogic/redux/retrieveMLS3/retrieveMLFromS3.slice';
import { generateCurveExcel } from '../uploadPopinFiles/function.util';
import styles from './exportExcel.module.scss';
import { IconButton } from '@mui/material';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';

interface Props {
  fileName?: string;
  curve?: Curve;
  parameterName?: string;
}

const ExportExcel: FC<Props> = ({ fileName, curve, parameterName }) => {
  const dispatch = useDispatch();
  const downloadedModel = localStorage.getItem('downloadedModel')?.split('#');

  let isDownloaded = false;
  if (fileName && downloadedModel?.includes(fileName)) {
    isDownloaded = true;
  }

  function handleButtonClick() {
    if (fileName != undefined) {
      dispatch(setRetrieveMLFromS3(fileName));
      localStorage.setItem(
        'downloadedModel',
        localStorage.getItem('downloadedModel') + '#' + fileName,
      );
      window.dispatchEvent(new Event('storage'));
    } else if (curve !== undefined && parameterName !== undefined) {
      generateCurveExcel(curve, parameterName);
    }
  }

  const iconClassName = fileName
    ? styles.icon_container
    : clsx(styles.icon_container, styles.icon_container_margin);

  return (
    <div className={iconClassName}>
      <IconButton
        color={isDownloaded ? 'default' : 'primary'}
        onClick={handleButtonClick}
        className={styles.download_button_style}
      >
        <GetAppOutlinedIcon />
      </IconButton>
    </div>
  );
};

export default ExportExcel;
