import { CardContent, Grid, Icon } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../../../constants/path';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { CustomCard } from '../../../../../../util/functionUtil';
import CardComponent from '../../cards/cardComponent/cardComponent';
import CardComponentWithIcon from '../../cards/cardComponentWithIcon/cardComponentWithIcon';
import styles from './powerDispatch.module.scss';
import { setRetrieveForecastInformation } from '../../../../../../corelogic/redux/forecastInformation/forecastInformation.slice';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import { setRetrieveKpiImbalance } from '../../../../../../corelogic/redux/kpiImbalance/kpi-imbalance.slice';

const PowerDispatch: FC = () => {
  const { graphIndex } = useSelector((state: RootState) => state.graphIndex);
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const { kpiImbalance } = useSelector((state: RootState) => state.kpiImbalance);
  const { site, isLoading } = useSelector((state: RootState) => state.site);
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentKpi = kpiImbalance[graphIndex];
  const siteId = localStorage.getItem('favouriteSite');

  useEffect(() => {
    if (!siteId) return;
    dispatch(
      setRetrieveForecastInformation({
        siteId,
        startDate: new Date().toISOString(),
        endDate: endOfDay(addDays(new Date(), 1)).toISOString(),
      }),
    );
    dispatch(
      setRetrieveKpiImbalance({
        siteId,
        startDate: startOfDay(subDays(new Date(), 1)).toISOString(),
        endDate: new Date().toISOString(),
        chunk: 1,
      }),
    );
    // Then dispatch every 5 minutes
    const intervalId = setInterval(() => {
      dispatch(
        setRetrieveForecastInformation({
          siteId,
          startDate: new Date().toISOString(),
          endDate: endOfDay(addDays(new Date(), 1)).toISOString(),
        }),
      );
      dispatch(
        setRetrieveKpiImbalance({
          siteId,
          startDate: startOfDay(subDays(new Date(), 1)).toISOString(),
          endDate: new Date().toISOString(),
          chunk: 1,
        }),
      );
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Clear interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={clsx(styles.pageContainer, menuStatus && styles.menuOn)}>
      <CustomCard>
        <CardContent className={styles.parent_gap_container}>
          <div className={styles.manufacturer_information_title}>
            {isLoading === false && (
              <div className={styles.chevronAndSiteName}>
                <Icon
                  className={styles.backButton}
                  onClick={() => navigate(PATHS.OVERVIEW)}
                  sx={{ fontSize: '30px !important' }}
                  color="primary"
                >
                  <FormattedMessage id={'icon.chevron_left'} />
                </Icon>
                <h1>{site.name}</h1>
              </div>
            )}
          </div>
          <Grid container spacing={1.5}>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.runningProgram' })}
                icon={'icon.run'}
                iconColor="#FF5722"
                value={currentKpi?.power_production_running_program?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.runningProgram',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.netActivePower' })}
                icon={'icon.electric_bolt'}
                iconColor="#2196F3"
                value={currentKpi?.net_power?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.netActivePower',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.pmaxModelForecast' })}
                icon={'icon.event'}
                iconColor="#E91E63"
                value={currentKpi?.net_power_p2m_forecast?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.power.powerPmaxModelForecast',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.pmaxModel' })}
                icon={'icon.modelTraining'}
                iconColor="#9C27B0"
                value={currentKpi?.net_power_p2m_estimate?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.power.powerPmaxModel',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.pmaxOemCurves' })}
                icon={'icon.lineAxis'}
                iconColor="#40A900"
                value={currentKpi?.net_power_oem_estimate?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.power.powerPmaxOEM',
                })}
              />
            </Grid>
            <Grid item xs>
              <CardComponentWithIcon
                title={intl.formatMessage({ id: 'generalDashboard.modelReliability' })}
                icon={'icon.handshake'}
                iconColor="#40A900"
                value={currentKpi?.net_power_model_reliability?.toFixed(2) ?? 'N/A'}
                unit={intl.formatMessage({ id: 'unit.mw' })}
                helper={intl.formatMessage({
                  id: 'generalDashboard.helper.power.modelReliabilityPower',
                })}
              />
            </Grid>
          </Grid>
          <CardComponent isCurves={true} isDispatch={true} isEfficiency={false} />
          <div className={styles.imbalanceAndStatus}>
            <CardComponent
              title="generalDashboard.imbalances.title"
              isImbalance={true}
              isEfficiency={false}
            />
          </div>
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default PowerDispatch;
