import { PATHS } from '../../../../../constants/path';
import { tabWithSubtab } from '../type';

export const dashBoardSubTabSuperAdmin: tabWithSubtab[] = [
  { tabName: 'Dashboards', subTabName: 'power', path: PATHS.POWER },
  { tabName: 'Dashboards', subTabName: 'efficiency', path: PATHS.EFFICIENCY },
];

export const dashBoardSubTab: tabWithSubtab[] = [
  { tabName: 'Dashboards', subTabName: 'power', path: PATHS.POWER },
  { tabName: 'Dashboards', subTabName: 'efficiency', path: PATHS.EFFICIENCY },
];

export const inputDataSubTab: tabWithSubtab[] = [
  { tabName: 'Input Data', subTabName: 'OEMInformation', path: PATHS.MANUFACTURER_INFORMATION },
  { tabName: 'Input Data', subTabName: 'metroscopeFailure', path: PATHS.METROSCOPE_FAILURE },
  { tabName: 'Input Data', subTabName: 'dataExclusion', path: PATHS.DATA_EXCLUSION },
  { tabName: 'Input Data', subTabName: 'tagMapping', path: PATHS.TAG_MAPPING },
];

export const exportSubTab: tabWithSubtab[] = [
  { tabName: 'export', subTabName: 'modelML', path: PATHS.ML_MODEL },
  { tabName: 'export', subTabName: 'dailyCapacitiesForecast', path: PATHS.DAILY_CAPACITY },
];
