import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import AuthenticationProvider from './adapters/secondaries/providers/authentication/authenticationProvider';
import { msalConfig } from './configurations/msal-client.configuration';
import { store } from './corelogic/redux/create-store';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Hotjar from '@hotjar/browser';

export const pca = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const siteId = 3686793;
const hotjarVersion = 6;
if (process.env.REACT_APP_ENV == 'production') Hotjar.init(siteId, hotjarVersion);

root.render(
  <MsalProvider instance={pca}>
    <Provider store={store}>
      <AuthenticationProvider>
        <link
          href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined"
          rel="stylesheet"
        ></link>
        <App />
      </AuthenticationProvider>
    </Provider>
  </MsalProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
