import { configureStore } from '@reduxjs/toolkit';
import { dependencies } from '../../configurations/dependencies';
import { Dependencies } from '../../configurations/dependencies.interface';
import { retrieveSiteEpic } from '../domain/usecases/site/retrieveSite/retrieve-site.usecase';
import { RetrieveKpiImbalanceEpic } from './../domain/usecases/kpiImbalance/retrieveKpiImbalance/retrieveKpiImbalance.usecase';
import { retrieveMlModelEpic } from './../domain/usecases/site/retrieveMlModel/retrieve-mlModel.usecase';
import { siteReducers } from './site/site.slice';
// Check if original redux observable is suitable
import { combineEpics, createEpicMiddleware } from 'redux-observable-es6-compat';
import { retrievePmdKpiMeanEpic } from '../domain/usecases/pmdKpiMean/retrievePmdKpiMean.usecase';
import { RetrieveAdminRightEpic } from '../domain/usecases/site/adminRight/adminRight.usecase';
import { createSiteEpic } from '../domain/usecases/site/createSite/create-site.usecase';
import { retrieveDailyCapacitiesEpic } from '../domain/usecases/site/dailyCapacities/retrieve-daily-capacities.usecase';
import { deleteSiteEpic } from '../domain/usecases/site/deleteSite/delete-site.usecase';
import { retrieveAllGainEpic } from '../domain/usecases/site/retrieveAllGain/retrieve-all-gain.usecase';
import { retrieveDailyCapacityFileEpic } from '../domain/usecases/site/retrieveDailyCapacityFile/retrieve-daily-capacity-file.usecase';
import { retrieveGainEpic } from '../domain/usecases/site/retrieveGain/retrieve-gain.usecase';
import { retrieveSiteNamesIdsEpic } from '../domain/usecases/site/retrieveSiteNamesIds/retrieve-site-names.usecase';
import { updateSiteCurveEpic } from '../domain/usecases/site/updateSite/update-site-curve.usecase';
import { updateSiteModelEpic } from '../domain/usecases/site/updateSiteModel/update-site-model.usecase';
import { updateSiteParameterEpic } from '../domain/usecases/site/updateSiteParameter/update-site-parameter.usecase';
import { adminRightReducers } from './adminRight/adminRight.slice';
import { allGainsReducers } from './allGains/allGains.slice';
import { currentProductionKpiCurveNameReducers } from './currentTimeseries/currentKpiCurveName/currentKpiCurveName.slice';
import { gainReducers } from './currentTimeseries/gain/gain.slice';
import { graphIndexReducers } from './currentTimeseries/graphIndex/graphDate/graphIndex.slice';
import { StartDateReducers } from './currentTimeseries/startDate/startDate.slice';
import { dailyCapacitiesReducers } from './dailyCapacities/dailyCapacities.slice';
import { dailyCapacityFileReducers } from './dailyCapacityFile/dailyCapacityFile.slice';
import { hiddenCurvesReducers } from './hiddenCurve/hiddenCurve.slice';
import { isAdminReducer } from './isAdmin/is-admin.slice';
import { isDarkModeReducer } from './isDarkMode/is-dark-mode.slice';
import { contextCurrentMenuStatusReducers } from './isMenuOpened/current-menu-status.slice';
import { isSuperAdminModeReducer } from './isSuperAdminMode/is-super-admin-mode.slice';
import { kpiImbalanceReducers } from './kpiImbalance/kpi-imbalance.slice';
import { contextCurrentParameterReducers } from './parameter/context-current-parameter.slice';
import { pmdKpiMeanDtoReducers } from './pmdKpiMean/pmd-kpi-mean.slice';
import { retrieveMLFromS3Reducers } from './retrieveMLS3/retrieveMLFromS3.slice';
import { siteNamesIdsReducers } from './siteNamesIds/siteNamesIds.slice';
import { isUpdatedSiteNameReducers } from './updatedSiteNameBoolean/is-updated-site-name.slice';
import { yearOfCurrentGainReducers } from './yearOfCurrentGain/yearOfCurrentGain.slice';
import { retrieveOverviewEpic } from '../domain/usecases/overview/retrieveOverview.usecase';
import { overviewReducers } from './overview/overview.slice';
import { forecastInformationReducers } from './forecastInformation/forecastInformation.slice';
import { retrieveForecastInformationEpic } from '../domain/usecases/overview/retrieveForecastInformation.usecase';
import { siteFortuitReducers } from './siteFortuit/siteFortuit.slice';
import { retrieveFortuitEpic } from '../domain/usecases/fortuit/retrieveSiteFortuit.usecase';

export const createStore = (dependencies: Dependencies) => {
  const rootEpics = combineEpics(
    retrieveSiteEpic,
    retrieveSiteNamesIdsEpic,
    updateSiteCurveEpic,
    createSiteEpic,
    updateSiteParameterEpic,
    retrieveMlModelEpic,
    retrieveGainEpic,
    RetrieveAdminRightEpic,
    updateSiteModelEpic,
    deleteSiteEpic,
    RetrieveKpiImbalanceEpic,
    retrievePmdKpiMeanEpic,
    retrieveDailyCapacitiesEpic,
    retrieveDailyCapacityFileEpic,
    retrieveAllGainEpic,
    retrieveOverviewEpic,
    retrieveForecastInformationEpic,
    retrieveFortuitEpic,
  );
  const epicMiddleware = createEpicMiddleware({ dependencies });
  const store = configureStore({
    reducer: {
      site: siteReducers,
      siteNamesIds: siteNamesIdsReducers,
      currentParameter: contextCurrentParameterReducers,
      isUpdatedSiteName: isUpdatedSiteNameReducers,
      graphIndex: graphIndexReducers,
      startDate: StartDateReducers,
      gain: gainReducers,
      admin: adminRightReducers,
      contextCurrentMenuStatus: contextCurrentMenuStatusReducers,
      downloadUrl: retrieveMLFromS3Reducers,
      hiddenCurves: hiddenCurvesReducers,
      kpiImbalance: kpiImbalanceReducers,
      currentProductionKpiCurveName: currentProductionKpiCurveNameReducers,
      isAdmin: isAdminReducer,
      isSuperAdminMode: isSuperAdminModeReducer,
      isDarkMode: isDarkModeReducer,
      pmdKpiMeanDto: pmdKpiMeanDtoReducers,
      dailyCapacities: dailyCapacitiesReducers,
      dailyCapacityFile: dailyCapacityFileReducers,
      allGains: allGainsReducers,
      yearOfCurrentGain: yearOfCurrentGainReducers,
      overview: overviewReducers,
      forecastInformation: forecastInformationReducers,
      fortuit: siteFortuitReducers,
    },
    middleware: [epicMiddleware],
  });
  epicMiddleware.run(rootEpics);
  return store;
};

export const store = createStore(dependencies);

export type RootState = ReturnType<typeof store.getState>;
