import { PayloadAction } from '@reduxjs/toolkit';
import { StateObservable, ofType } from 'redux-observable';
import { switchMap, from, map, Observable, catchError, of } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';

import { DailyCapacity } from '../../../models/dailyCapacity/dailyCapacity.model';
import {
  setRetrieveDailyCapacitiesFailure,
  setRetrieveDailyCapacitiesSuccess,
} from '../../../../redux/dailyCapacities/dailyCapacities.slice';

export const retrieveDailyCapacitiesEpic = (
  action$: Observable<PayloadAction<string>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('dailyCapacities/setRetrieveDailyCapacities'),
    switchMap(action => {
      return from(dependencies.siteGateway.retrieveDailyCapacities(action.payload)).pipe(
        map((dailyCapacities: DailyCapacity[]) =>
          setRetrieveDailyCapacitiesSuccess(dailyCapacities),
        ),
        catchError(() =>
          of(
            setRetrieveDailyCapacitiesFailure(
              `Retrieving site daily capacities ${action.payload.toString()} has failed`,
            ),
          ),
        ),
      );
    }),
  );
