import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CorrectionMap } from '../../../../../corelogic/domain/models/correctionMap/correctionMap.model';
import { Curve } from '../../../../../corelogic/domain/models/curve/curve.model';
import {
  Reference,
  SiteGroupReference,
} from '../../../../../corelogic/domain/models/reference/reference.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import {
  setUpdateSiteCurve,
  setUpdateSiteParameter,
} from '../../../../../corelogic/redux/site/site.slice';
import ReferenceField from '../referenceField/referenceField';
import { parameterNameMapping } from '../uploadPopinFiles/parameterNameMapping';
import UploadPopinFiles from '../uploadPopinFiles/uploadPopinFiles';
import {
  isInputFieldDisabled,
  isReferenceValuesValid,
  operatorLogic,
  referenceValidationLogic,
} from './function.util';
import styles from './popin.module.scss';
import { CustomButton } from '../../../../../util/functionUtil';

type Props = { isOpen: boolean; handleClose: () => void };

const P2MPopin: React.FC<Props> = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const { parameterName } = useSelector((state: RootState) => state.currentParameter);
  const { site } = useSelector((state: RootState) => state.site);

  const siteGroupReference: SiteGroupReference | undefined = site.siteGroupReferences.find(
    (siteGroupReference: SiteGroupReference) => {
      return siteGroupReference.name === parameterName;
    },
  );

  const [referencesState, setReferencesState] = useState<Reference[] | undefined>(
    siteGroupReference?.references,
  );

  const [newCurvesData, setNewCurvesData] = useState<Curve[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  const isPublishButtonValid = isReferenceValuesValid(referencesState ?? [], parameterName ?? '');

  const getNewReferenceValues = (): SiteGroupReference[] => {
    const newReferences: SiteGroupReference[] = [...site.siteGroupReferences];
    const idReferenceToUpdate = newReferences.findIndex(
      reference => reference.name === parameterName,
    );
    let reference: SiteGroupReference = newReferences[idReferenceToUpdate];

    reference = {
      ...reference,
      last_modification: new Date().toISOString(),
      references: referencesState ?? [],
    };
    newReferences[idReferenceToUpdate] = reference;
    return newReferences;
  };

  const updateSiteCurves = (nameParam: string): CorrectionMap => {
    const currentCorrectionMapIndex = site.correction_parameters.findIndex(
      correctionMap => correctionMap.name === nameParam,
    );
    if (newCurvesData.length === 2) {
      return { name: nameParam, correction: newCurvesData };
    } else {
      const newCorrection = site.correction_parameters[currentCorrectionMapIndex].correction.map(
        correction => {
          if (correction.name === newCurvesData[0].name) {
            return newCurvesData[0];
          }
          return correction;
        },
      );
      return {
        name: nameParam,
        correction: newCorrection,
      };
    }
  };

  const editPopinPublish = async () => {
    if (parameterName !== undefined && newCurvesData.length != 0) {
      const siteCurves = updateSiteCurves(parameterName);
      const jsonString = JSON.stringify(siteCurves);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const formData = new FormData();
      formData.append('file', blob);
      dispatch(setUpdateSiteCurve({ siteId: site._id, updateBody: formData }));
    }

    const SiteGroupReference = getNewReferenceValues();
    if (SiteGroupReference !== site.siteGroupReferences) {
      const referencesToUpdate = { siteGroupReferences: SiteGroupReference };
      dispatch(
        setUpdateSiteParameter({
          siteId: site._id,
          updateBody: referencesToUpdate,
        }),
      );
    }
    handleClose();
  };

  const manageAndOrCase = () => {
    if (parameterName !== undefined) {
      if (
        Object.values(referenceValidationLogic)[
          Object.keys(referenceValidationLogic).indexOf(parameterName)
        ] == operatorLogic.ORAND
      ) {
        return (
          <div className={styles.andOrContainer}>
            <FormattedMessage id="logic.andOr" />
          </div>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };

  return (
    <Dialog
      PaperProps={{ sx: { borderRadius: '16px' } }}
      className={styles.dialogContainer}
      fullWidth
      maxWidth={'xl'}
      onClose={handleClose}
      open={isOpen}
    >
      <DialogTitle>
        <div className={styles.titleAndButtons}>
          <div className={styles.iconAndTextPopinContainer}>
            <span className={clsx('material-icons-outlined', styles.iconParameterContainer)}>
              <FormattedMessage id="icon.stacked_line_chart" />
            </span>
            {parameterName != undefined ? (
              <h2 className={styles.titlePopinContainer}>
                {parameterNameMapping[parameterName as keyof typeof parameterNameMapping]}
              </h2>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.buttonsContainer}>
            <CustomButton variant="outlined" type="button" onClick={handleClose}>
              <FormattedMessage id="button.cancel" />
            </CustomButton>
            <CustomButton
              type="button"
              variant="contained"
              disabled={!isPublishButtonValid || hasError}
              onClick={editPopinPublish}
              color="primary"
            >
              <FormattedMessage id="button.publish" />
            </CustomButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent style={{ padding: '16px 24px' }}>
        {parameterName !== 'anti_icing_dew_point_temperature' &&
          parameterName !== 'load_limit' &&
          referencesState !== undefined &&
          referencesState.length > 0 && (
            <div className={styles.mandatoryTextContainer}>
              <div>
                <FormattedMessage id="popin.field" />
              </div>
              <div style={{ color: 'red' }}> * </div>
              <div>
                <FormattedMessage id="popin.mandatoryField" />
              </div>
            </div>
          )}
        {referencesState !== undefined && referencesState.length > 0 && (
          <div className={styles.referenceValueTextContainer}>
            <FormattedMessage id="home.referenceValue" />
          </div>
        )}
        <div className={styles.referenceBoxesContainer}>
          {referencesState !== undefined &&
            referencesState.length > 0 &&
            parameterName !== undefined &&
            referencesState.map((reference: Reference, index: number) => (
              <Fragment key={reference.name}>
                <div>
                  <ReferenceField
                    reference={reference}
                    referencesState={referencesState}
                    setReferencesState={setReferencesState}
                    disabled={isInputFieldDisabled(referencesState, reference, parameterName)}
                    parameterName={parameterName}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    fontFamily: 'Roboto',
                  }}
                >
                  {index < referencesState.length - 1 &&
                    (Object.values(referenceValidationLogic)[
                      Object.keys(referenceValidationLogic).indexOf(parameterName)
                    ] === operatorLogic.OR ? (
                      <div className={styles.andOrContainer}>
                        <FormattedMessage id="logic.or" />
                      </div>
                    ) : (
                      manageAndOrCase()
                    ))}
                </div>
              </Fragment>
            ))}
        </div>
        <UploadPopinFiles
          newCurvesData={newCurvesData}
          setNewCurvesData={setNewCurvesData}
          setHasError={setHasError}
        />
      </DialogContent>
    </Dialog>
  );
};

export default P2MPopin;
