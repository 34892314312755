/* eslint-disable prettier/prettier */
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CardContent, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import clsx from 'clsx';
import { FC, Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DataExclusion } from '../../../../../corelogic/domain/models/site/dataExclusion.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setUpdateSiteParameter } from '../../../../../corelogic/redux/site/site.slice';
import { getFormattedDateWithMinute } from '../../../../../helpers/dateFunction';
import { CustomButton, CustomCard, StripedDataGrid } from '../../../../../util/functionUtil';
import ExclusionPopin from '../exclusionPopin/exclusionPopin';
import styles from './exclusion.module.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Exclusion: FC = () => {
  const { site } = useSelector((state: RootState) => state.site);
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const { isAdmin } = useSelector((state: RootState) => state.isAdmin);

  const intl = useIntl();
  const dispatch = useDispatch();

  const [popInOpen, setPopInOpen] = useState(false);
  const [selectedExclusion, setSelectedExclusion] = useState<DataExclusion>();

  const [isNewExclusion, setIsNewExclusion] = useState<boolean>(true);

  const handleNewExclusion = () => {
    setPopInOpen(true);
    setIsNewExclusion(true);
  };

  const handleClosePopin = () => {
    setPopInOpen(false);
  };

  const handleDelete = (exclusion: DataExclusion) => {
    const updatedExclusion = site.dataExclusion.filter(x => x !== exclusion);
    const updateBodyExclusion = { dataExclusion: updatedExclusion };
    dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: updateBodyExclusion }));
  };

  const handlePopInOpen = (exc: DataExclusion) => {
    setSelectedExclusion(exc);
    setPopInOpen(true);
    setIsNewExclusion(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: intl.formatMessage({ id: 'dataExclusion.description' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 2,
    },
    {
      field: 'startDate',
      headerName: intl.formatMessage({ id: 'dataExclusion.startDate' }),
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      renderCell: params => {
        const date = new Date(params.value);
        return getFormattedDateWithMinute(date);
      },
      flex: 1,
    },
    {
      field: 'endDate',
      headerName: intl.formatMessage({ id: 'dataExclusion.endDate' }),
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      renderCell: params => {
        const date = new Date(params.value);
        return params.value ? getFormattedDateWithMinute(date) : '';
      },
      flex: 1,
    },
    {
      field: 'action',
      headerName: intl.formatMessage({ id: 'dataExclusion.edit' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: param => {
        return (
          isAdmin && (
            <Fragment key={param.row.description + param.row.startDate}>
              <div className={styles.actionContainer}>
                <IconButton color="actionButton" onClick={() => handlePopInOpen(param.row)}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  color="actionButton"
                  onClick={() => {
                    if (confirm(intl.formatMessage({ id: 'home.deletionConfirmation' }))) {
                      handleDelete(param.row);
                    }
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>
            </Fragment>
          )
        );
      },
    },
  ];

  return (
    <div className={clsx(styles.exclusionPageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined">
        <CardContent className={styles.parent_gap_container}>
          <div className={styles.exclusion_title}>
            <h1>
              <FormattedMessage id="dataExclusion.title" />
            </h1>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.exclusion}>
              <div className={styles.textContainer}>
                <div className={styles.subTitle}>
                  <FormattedMessage id="dataExclusion.explanationTitle" values={{ br: <br /> }} />
                </div>
                <div>
                  <FormattedMessage id="dataExclusion.explanation" values={{ br: <br /> }} />
                </div>
              </div>
              <div className={styles.exclusionButton}>
                {isAdmin && (
                  <CustomButton
                    variant="contained"
                    onClick={() => handleNewExclusion()}
                    disabled={site.name === ''}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    <FormattedMessage id="dataExclusion.exclusionButton" />
                  </CustomButton>
                )}
              </div>
            </div>
            <StripedDataGrid
              disableColumnMenu
              disableColumnFilter
              disableRowSelectionOnClick
              sortingOrder={['desc', 'asc']}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                sorting: {
                  sortModel: [{ field: 'endDate', sort: 'asc' }],
                },
              }}
              slotProps={{
                panel: {
                  sx: {
                    top: '-60px !important',
                    minWidth: '650px !important',
                  },
                },
              }}
              sx={{
                borderRadius: '16px',
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                },
              }}
              rows={site.dataExclusion}
              columns={columns}
              getRowId={row => row.description + row.startDate}
              getRowClassName={params =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              pageSizeOptions={[10]}
            />
            {popInOpen === true && (
              <ExclusionPopin
                exclusion={selectedExclusion}
                isOpen={popInOpen}
                isNewExclusion={isNewExclusion}
                handleClose={handleClosePopin}
              />
            )}
          </div>
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default Exclusion;
