import { useIntl } from 'react-intl';
import { PmdKpiMeanDto } from '../../../../../../corelogic/domain/models/pmdKpiMean/pmdKpiMeanDto.model';
import {
  convertMinutesToTime,
  getFormattedDateWithMinute,
} from '../../../../../../helpers/dateFunction';
import { ExtendedPlotData, KPI_CURVE } from '../../type';

export const kpiProductionPmdPlotlyDataBuilder = (
  pmdKpiMeanDtos: PmdKpiMeanDto[],
): ExtendedPlotData[] => {
  const date: string[] = [];
  const net_power_model_reliability_mean: Array<number | null> = [];
  const production_imbalance_mean: Array<number | null> = [];
  const real_production_imbalance_mean: Array<number | null> = [];
  const duration: Array<string | null> = [];

  pmdKpiMeanDtos.forEach((pmdKpiMean: PmdKpiMeanDto) => {
    date.push(getFormattedDateWithMinute(new Date(pmdKpiMean.start_pmd)));
    net_power_model_reliability_mean.push(pmdKpiMean.net_power_model_reliability_mean);
    production_imbalance_mean.push(pmdKpiMean.production_imbalance_mean);
    real_production_imbalance_mean.push(pmdKpiMean.real_production_imbalance_mean);
    duration.push(convertMinutesToTime(pmdKpiMean.duration));
  });
  const intl = useIntl();
  const data: ExtendedPlotData[] = [];

  data.push({
    x: date,
    y: real_production_imbalance_mean,
    name: intl.formatMessage({
      id: 'generalDashboard.imbalances.realProductionImbalance',
    }),
    type: 'bar',
    hovertemplate: '%{y:.2f} MW',
    marker: { color: '#D68100' },
    duration: duration,
  });

  data.push({
    x: date,
    y: production_imbalance_mean,
    name: intl.formatMessage({
      id: 'generalDashboard.imbalances.productionImbalance',
    }),
    type: 'bar',
    hovertemplate: '%{y:.2f} MW',
    marker: { color: '#87931A' },
    duration: duration,
  });

  data.push({
    x: date,
    y: net_power_model_reliability_mean,
    name: intl.formatMessage({
      id: 'generalDashboard.modelReliability',
    }),
    type: 'bar',
    hovertemplate: '%{y:.2f} MW',
    marker: { color: '#189EB2' },
    duration: duration,
  });

  return data;
};

export const kpiForecastPmdPlotlyDataBuilder = (
  param: string,
  pmdKpiMeanDtos: PmdKpiMeanDto[],
): ExtendedPlotData[] => {
  const date: string[] = [];
  const c_h_ratio_imbalance_mean: Array<number | null> = [];
  const lower_heating_value_imbalance_mean: Array<number | null> = [];
  const net_power_forecast_imbalance_mean: Array<number | null> = [];
  const network_frequency_imbalance_mean: Array<number | null> = [];
  const other_imbalance_mean: Array<number | null> = [];
  const pressure_imbalance_mean: Array<number | null> = [];
  const relative_humidity_imbalance_mean: Array<number | null> = [];
  const temperature_imbalance_mean: Array<number | null> = [];
  const total_others_imbalance_mean: Array<number | null> = [];
  const weather_imbalance_mean: Array<number | null> = [];
  const wind_speed_imbalance_mean: Array<number | null> = [];
  const duration: Array<string | null> = [];

  pmdKpiMeanDtos.forEach((pmdKpiMean: PmdKpiMeanDto) => {
    date.push(getFormattedDateWithMinute(new Date(pmdKpiMean.start_pmd)));
    c_h_ratio_imbalance_mean.push(pmdKpiMean.c_h_ratio_imbalance_mean);
    lower_heating_value_imbalance_mean.push(pmdKpiMean.lower_heating_value_imbalance_mean);
    net_power_forecast_imbalance_mean.push(pmdKpiMean.net_power_forecast_imbalance_mean);
    network_frequency_imbalance_mean.push(pmdKpiMean.network_frequency_imbalance_mean);
    other_imbalance_mean.push(pmdKpiMean.other_imbalance_mean);
    pressure_imbalance_mean.push(pmdKpiMean.pressure_imbalance_mean);
    relative_humidity_imbalance_mean.push(pmdKpiMean.relative_humidity_imbalance_mean);
    temperature_imbalance_mean.push(pmdKpiMean.temperature_imbalance_mean);
    total_others_imbalance_mean.push(pmdKpiMean.total_others_imbalance_mean);
    weather_imbalance_mean.push(pmdKpiMean.weather_imbalance_mean);
    wind_speed_imbalance_mean.push(pmdKpiMean.wind_speed_imbalance_mean);
    duration.push(convertMinutesToTime(pmdKpiMean.duration));
  });
  const intl = useIntl();
  const data: ExtendedPlotData[] = [];
  switch (param) {
    case KPI_CURVE.FORECAST_IMBALANCE:
      data.push({
        x: date,
        y: net_power_forecast_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.subTitle',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#00838F' },
        duration: duration,
      });
      break;

    case KPI_CURVE.WEATHER_TECHNICAL_FORECAST:
      data.push({
        x: date,
        y: weather_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.weather',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#2196F3' },
        duration: duration,
      });

      data.push({
        x: date,
        y: total_others_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.otherForecast',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#6FA136' },
        duration: duration,
      });
      break;

    case KPI_CURVE.WEATHER_FORECAST:
      data.push({
        x: date,
        y: temperature_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.ambientTemperature',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#FF5722' },
        duration: duration,
      });

      data.push({
        x: date,
        y: pressure_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.ambientPressure',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#80CBC4' },
        duration: duration,
      });

      data.push({
        x: date,
        y: relative_humidity_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.ambientRelativeHumidity',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#673AB7' },
        duration: duration,
      });

      data.push({
        x: date,
        y: wind_speed_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.ambientWindSpeed',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#D68100' },
        duration: duration,
      });

      break;

    case KPI_CURVE.TECHNICAL_FORECAST:
      data.push({
        x: date,
        y: network_frequency_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.network',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#FF5722' },
        duration: duration,
      });

      data.push({
        x: date,
        y: lower_heating_value_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.lhv',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#80CBC4' },
        duration: duration,
      });

      data.push({
        x: date,
        y: c_h_ratio_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.chRatio',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#673AB7' },
        duration: duration,
      });
      data.push({
        x: date,
        y: other_imbalance_mean,
        name: intl.formatMessage({
          id: 'generalDashboard.imbalances.other',
        }),
        type: 'bar',
        hovertemplate: '%{y:.2f} MW',
        marker: { color: '#D68100' },
        duration: duration,
      });
      break;
  }
  return data;
};
