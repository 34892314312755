import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import clsx from 'clsx';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { setCurrentProductionKpiCurveName } from '../../../../../../corelogic/redux/currentTimeseries/currentKpiCurveName/currentKpiCurveName.slice';
import { getKeyByValue } from '../../../../../../helpers/objectFunction';
import { Spinner } from '../../../../../../ui/spinner/spinner.component';
import { kpiCurveRecord } from './constant';
import styles from './dropdown-productionKpiCurveName-list.module.scss';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

const DropDownKpiCurveNameList: FC = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.site);
  const { productionkpiCurveName } = useSelector(
    (state: RootState) => state.currentProductionKpiCurveName,
  );

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const handleOptionChange = (e: SelectChangeEvent<string>) => {
    const keyKpiCurveRecord: string | undefined = getKeyByValue(kpiCurveRecord, e.target.value);
    if (keyKpiCurveRecord !== undefined) {
      dispatch(setCurrentProductionKpiCurveName(keyKpiCurveRecord));
    }
  };

  return (
    <div className={clsx('form-group', styles.displayDropDown)}>
      <Select
        value={kpiCurveRecord[productionkpiCurveName]}
        onChange={handleOptionChange}
        size="small"
        autoWidth={false}
        sx={{
          borderRadius: '8px',
          width: '30%',
        }}
      >
        {Object.keys(kpiCurveRecord).map((kpiCurve: string) => (
          <MenuItem value={kpiCurveRecord[kpiCurve]} key={kpiCurve}>
            {kpiCurveRecord[kpiCurve]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DropDownKpiCurveNameList;
