import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import { setCreateSiteFailure, setCreateSiteSuccess } from '../../../../redux/site/site.slice';
import { Site } from '../../../models/site/site.model';

export const createSiteEpic = (
  action$: Observable<PayloadAction<{ siteName: string }>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('site/setCreateSite'),
    switchMap(action => {
      return from(dependencies.siteGateway.createSite(action.payload.siteName)).pipe(
        map((site: Site) => setCreateSiteSuccess(site)),
        catchError(() =>
          of(setCreateSiteFailure(`Create site ${action.payload.siteName} has failed`)),
        ),
      );
    }),
  );
