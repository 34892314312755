import { FC } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import styles from './spinner.component.module.scss';

type Prop = {
  className?: string;
};

export const Spinner: FC<Prop> = ({ className }) => {
  const classNameToUse: string = className ?? styles.spinner;
  return <ImSpinner8 data-testid="spinner" className={classNameToUse} />;
};
