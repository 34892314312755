/* eslint-disable prettier/prettier */

import { KPI_CURVE } from "../../../../../adapters/primaries/react/components/type";

export interface CurrentProductionKpiCurveNameState {
    productionkpiCurveName: string;
    isLoading: boolean;
    error?: string;
}

export const initialCurrentProductionKpiCurveNameState: CurrentProductionKpiCurveNameState = {
    productionkpiCurveName: KPI_CURVE.FORECAST_IMBALANCE,
    isLoading: false,
};
