import { PayloadAction } from '@reduxjs/toolkit';
import { Gain } from '../../../domain/models/gain/gain.model';
import { GainState } from '../../states/gain/gain.state';

export const setGainReducer = (state: GainState, action: PayloadAction<Gain | null>) => {
  state.gain = action.payload;
  state.isLoading = false;
};

export const setRetrieveGainReducer = (
  state: GainState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<string | null>,
) => {
  state.isLoading = true;
};

export const setRetrieveGainSuccessReducer = (
  state: GainState,
  action: PayloadAction<Gain | null>,
) => {
  state.isLoading = false;
  state.gain = action.payload;
};

export const setRetrieveGainFailureReducer = (state: GainState, action: PayloadAction<string>) => {
  state.isLoading = false;
  state.error = action.payload;
};
