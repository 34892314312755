import { PayloadAction } from '@reduxjs/toolkit';
import { SuperAdminModeState } from '../states/isSuperAdminMode/is-super-admin-mode.state';

export const setIsSuperAdminModeReducer = (
  state: SuperAdminModeState,
  action: PayloadAction<boolean>,
) => {
  state.isSuperAdminMode = action.payload;
  state.isLoading = false;
};
