import { useTheme } from '@mui/material';
import { Layout } from 'plotly.js';
import Plot from 'react-plotly.js';
import { ExtendedPlotData } from '../../type';

type Props = {
  windowSize: number;
  dataPlotly: ExtendedPlotData[];
  xAxisName: string;
  yAxisName: string;
  barmode: Partial<Layout>;
};

const KpiGraph: React.FC<Props> = ({ dataPlotly, xAxisName, yAxisName, barmode, windowSize }) => {
  const xLabels: string[] = [];
  const theme = useTheme();

  dataPlotly.forEach(function (trace) {
    if (trace.duration !== undefined && trace.x !== undefined) {
      for (let i = 0; i < trace.x.length; i++) {
        xLabels.push(trace.x[i] + '<br>' + trace.duration[i]);
      }
    }
  });
  dataPlotly.forEach((pmdValue: ExtendedPlotData) => (pmdValue.x = xLabels));
  return (
    <div>
      <Plot
        config={{
          responsive: true,
          displaylogo: false,
          modeBarButtonsToRemove: [
            'toImage',
            'zoom2d',
            'pan2d',
            'sendDataToCloud',
            'hoverClosestCartesian',
            'autoScale2d',
            'lasso2d',
          ],
        }}
        data={dataPlotly}
        layout={{
          legend: {
            itemclick: false,
            itemdoubleclick: false,
            font: {
              color: theme.palette.text.primary,
            },
          },
          width: windowSize,
          height: 385,
          showlegend: true,
          hovermode: 'x',
          font: {
            family: 'Roboto',
            size: 12,
            color: '#222B2D',
          },
          plot_bgcolor: theme.palette.background.default,
          paper_bgcolor: theme.palette.backgroundLayer1.main,
          ...barmode,
          xaxis: {
            title: {
              text: xAxisName,
              font: { size: 14, color: '#8C9294' },
            },
            showspikes: true,
            spikemode: 'across',
            spikesnap: 'cursor',
            spikecolor: '#222B2D',
            spikedash: 'solid',
            showline: true,
            showgrid: true,
            rangemode: 'normal',
            gridwidth: 1,
            zerolinecolor: '#8C9294',
            zerolinewidth: 2,
            linecolor: '#D0D3D5',
            linewidth: 1,
            mirror: true,
            color: theme.palette.text.primary,
          },
          yaxis: {
            title: { text: yAxisName, font: { size: 14, color: '#8C9294' } },
            showgrid: true,
            zerolinecolor: '#8C9294',
            zerolinewidth: 2,
            gridwidth: 1,
            linecolor: '#D0D3D5',
            linewidth: 1,
            mirror: true,
            color: theme.palette.text.primary,
          },
          margin: {
            b: 100,
            t: 15,
            pad: 10,
          },
          autosize: true,
        }}
      />
    </div>
  );
};

export default KpiGraph;
