import { Gain } from '../../../domain/models/gain/gain.model';

export interface AllGainsState {
  gains: Gain[];
  isLoading: boolean;
  error?: string;
}

export const initialAllGainsState: AllGainsState = {
  isLoading: false,
  gains: [],
};
