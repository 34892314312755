import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { axiosP2MSchematicApiConfiguration } from '../../../configurations/axios.configuration';
import { SiteGateway } from '../../../corelogic/domain/gateways/site.gateway';
import { Gain } from '../../../corelogic/domain/models/gain/gain.model';
import { UrlModel } from '../../../corelogic/domain/models/mlModel/urlModel.model';
import { Site, SiteDeletion } from '../../../corelogic/domain/models/site/site.model';
import { acquireAccessToken } from '../providers/authentication/tokenProvider';
import { DailyCapacity } from '../../../corelogic/domain/models/dailyCapacity/dailyCapacity.model';

export const axiosClient: AxiosInstance = axios.create(axiosP2MSchematicApiConfiguration);
export class ApiSiteGateway implements SiteGateway {
  retrieveSite = async (siteId: string): Promise<Site> => {
    const response: AxiosResponse = await axiosClient.get<Site>(`/sites/${siteId}`, {
      ...axiosP2MSchematicApiConfiguration,
      headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
    });
    return response.data;
  };

  retrieveMlFileFromS3 = async (fileName: string): Promise<string> => {
    const response: AxiosResponse = await axiosClient.get<UrlModel>(
      `/sites/retrieveMlModel/${fileName}`,
      {
        ...axiosP2MSchematicApiConfiguration,
        headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
      },
    );
    return response.data.url;
  };

  retrieveDailyCapacityFileFromS3 = async (
    creationDate: string,
    siteId: string,
    type: string,
  ): Promise<string> => {
    const response: AxiosResponse = await axiosClient.get<UrlModel>(
      `/sites/retrieveDailyCapacity/file/${creationDate}?siteId=${siteId}&type=${type}`,
      {
        ...axiosP2MSchematicApiConfiguration,
        headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
      },
    );
    return response.data.url;
  };

  retrieveGain = async (siteId: string): Promise<Gain> => {
    const response: AxiosResponse = await axiosClient.get<Gain>(`/sites/${siteId}/gain`, {
      ...axiosP2MSchematicApiConfiguration,
      headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
    });
    return response.data;
  };

  retrieveAllGains = async (): Promise<Gain[]> => {
    const response: AxiosResponse = await axiosClient.get<Gain[]>(`/sites/gains`, {
      ...axiosP2MSchematicApiConfiguration,
      headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
    });
    return response.data;
  };

  updateSiteCurve = async (siteId: string, updateBody: FormData): Promise<Site> => {
    const response: AxiosResponse = await axiosClient.put<Site>(
      `/sites/${siteId}/curve`,
      updateBody,
      {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: 'Bearer ' + (await acquireAccessToken()),
        },
      },
    );
    return response.data;
  };

  updateSiteParameter = async (siteId: string, updateBody: Partial<Site>): Promise<Site> => {
    const response: AxiosResponse = await axiosClient.put<Site>(`/sites/${siteId}`, updateBody, {
      ...axiosP2MSchematicApiConfiguration,
      headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
    });
    return response.data;
  };

  createSite = async (siteName: string): Promise<Site> => {
    const payload = JSON.stringify({ siteName: siteName });
    const response: AxiosResponse = await axiosClient.post<string>(`/sites/`, payload, {
      ...axiosP2MSchematicApiConfiguration,
      headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
    });
    return response.data;
  };

  retrieveAdminRight = async (siteId: string): Promise<string[]> => {
    return (
      await axiosClient.get<string[]>(`/sites/${siteId}/getRoles`, {
        ...axiosP2MSchematicApiConfiguration,

        headers: {
          authorization: 'Bearer ' + (await acquireAccessToken()),
        },
      })
    ).data;
  };

  updateSiteModel = async (
    siteId: string,
    modelId: string,
    status: boolean,
    effectiveDate: string,
  ): Promise<Site> => {
    return (
      await axiosClient.put<Site>(
        `/sites/${siteId}/model/${modelId}?status=${status}&effectiveDate=${effectiveDate}`,
        {},
        {
          ...axiosP2MSchematicApiConfiguration,

          headers: {
            authorization: 'Bearer ' + (await acquireAccessToken()),
          },
        },
      )
    ).data;
  };

  deleteSite = async (siteId: string): Promise<SiteDeletion> => {
    const response: AxiosResponse = await axiosClient.delete<Site>(`/sites/${siteId}`, {
      ...axiosP2MSchematicApiConfiguration,
      headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
    });
    return response.data;
  };

  retrieveDailyCapacities = async (siteId: string): Promise<DailyCapacity[]> => {
    const response: AxiosResponse = await axiosClient.get<Site>(
      `/sites/retrieveDailyCapacities/${siteId}`,
      {
        ...axiosP2MSchematicApiConfiguration,
        headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
      },
    );
    return response.data;
  };
}
