import { createSlice } from '@reduxjs/toolkit';
import { initialDownloadUrlState } from '../states/downloadUrl/downloadUrl.state';
import {
  setRetrieveDailyCapacityFileFromS3Reducer,
  setRetrieveDailyCapacityFileFromS3SuccessReducer,
  setRetrieveDailyCapacityFileFromS3FailureReducer,
} from './dailyCapacityFile.reducer';

export const dailyCapacityFileSlice = createSlice({
  name: 'dailyCapacityFile',
  initialState: initialDownloadUrlState,
  reducers: {
    setRetrieveDailyCapacityFile: setRetrieveDailyCapacityFileFromS3Reducer,
    setRetrieveDailyCapacityFileSuccess: setRetrieveDailyCapacityFileFromS3SuccessReducer,
    setRetrieveDailyCapacityFileFailure: setRetrieveDailyCapacityFileFromS3FailureReducer,
  },
});

export const {
  setRetrieveDailyCapacityFile,
  setRetrieveDailyCapacityFileSuccess,
  setRetrieveDailyCapacityFileFailure,
} = dailyCapacityFileSlice.actions;

export const dailyCapacityFileReducers = dailyCapacityFileSlice.reducer;
