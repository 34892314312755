/* eslint-disable prettier/prettier */
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Gain } from '../../../../../corelogic/domain/models/gain/gain.model';
import { SiteNameIdDto } from '../../../../../corelogic/domain/models/site/siteNameId.dto';
import { setRetrieveAllGains } from '../../../../../corelogic/redux/allGains/allGains.slice';
import { RootState } from '../../../../../corelogic/redux/create-store';
import styles from './gains.module.scss';
import { CustomCard } from '../../../../../util/functionUtil';
import { FormattedMessage } from 'react-intl';

const Gains: FC = () => {
  const dispatch = useDispatch();
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const { siteNamesIds } = useSelector((state: RootState) => state.siteNamesIds);
  const { gains } = useSelector((state: RootState) => state.allGains);
  const [selectedYear, setSelectedYear] = useState('');
  const [years, setYears] = useState<string[]>([]);
  const { isDarkMode } = useSelector((state: RootState) => state.isDarkMode);
  const theme = useTheme();

  useEffect(() => {
    dispatch(setRetrieveAllGains());
  }, []);

  useEffect(() => {
    if (gains.length <= 0) return;
    const tmpYears: string[] = [];
    gains.forEach(gain => {
      gain.gains_counters.forEach(gainCounter => {
        if (!tmpYears.includes(gainCounter.year)) {
          tmpYears.push(gainCounter.year);
        }
      });
    });
    tmpYears.sort((a, b) => a.localeCompare(b));
    setYears(tmpYears);
    if (tmpYears.length > 0) setSelectedYear(tmpYears[tmpYears.length - 1]);
  }, [gains]);

  const getSiteName = (gain: Gain) => {
    const name =
      siteNamesIds.find((siteNameIdDto: SiteNameIdDto) => siteNameIdDto._id == gain.site_id)
        ?.name ?? '';

    return name;
  };

  function handleYearChange(event: SelectChangeEvent<string>): void {
    setSelectedYear(event.target.value);
  }

  return (
    <div className={clsx(styles.gainPageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined" sx={{ padding: 4, alignItems: 'flex-start' }}>
        <div className={styles.headerContainer}>
          <Typography variant="h5">
            <FormattedMessage id="home.gains" />
          </Typography>
          <FormControl>
            <InputLabel id="year">
              <FormattedMessage id="generalDashboard.year" />
            </InputLabel>
            <Select
              sx={{ minWidth: '100px' }}
              size="small"
              value={selectedYear}
              onChange={handleYearChange}
              label="year"
            >
              {years.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Typography variant="subtitle1" paddingBottom={2}>
          <FormattedMessage id="home.gainInfo" />
        </Typography>
        <Grid container spacing={2}>
          {[...gains]
            .sort((a, b) => getSiteName(a).localeCompare(getSiteName(b)))
            .map(gain => (
              <Grid xs={4} key={gain.site_id} item>
                <Card
                  sx={{
                    borderRadius: '16px',
                    boxShadow: '0px 2px 4px 0px #00000010',
                    height: 'auto',
                    border: isDarkMode ? 'solid 1px #C3C5C550' : undefined,
                  }}
                >
                  <CardHeader
                    sx={{
                      backgroundColor: theme.palette.backgroundLayer1active.main,
                      textAlign: 'left',
                    }}
                    titleTypographyProps={{
                      fontSize: 22,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto',
                    }}
                    title={getSiteName(gain)}
                  ></CardHeader>
                  <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                    <div className={styles.gain}>
                      <Typography align="left" variant="body2" component="h6" fontWeight="bold">
                        <FormattedMessage id="generalDashboard.gainCounter" />
                      </Typography>
                      <div className={styles.unitAndValue}>
                        <Typography variant="body1" fontWeight="bold" fontSize="26px">
                          {gain.gains_counters.find(
                            gainCounter => gainCounter.year === selectedYear,
                          )?.gain_counter ?? 'N/A'}
                        </Typography>
                        <Typography
                          color={theme.palette.text.secondary}
                          variant="caption"
                          fontSize="14px"
                        >
                          <FormattedMessage id="unit.mwh" />
                        </Typography>
                      </div>
                    </div>
                    <Divider
                      sx={{ margin: '8px' }}
                      variant="fullWidth"
                      orientation="vertical"
                      flexItem
                    />
                    <div className={styles.gainElement}>
                      <div className={styles.gainSubElement}>
                        <Typography align="left" variant="body2" component="h6">
                          <FormattedMessage id="generalDashboard.cumulated_pmd_hour" />
                        </Typography>
                        <div className={styles.unitAndValue}>
                          <Typography fontWeight="bold">
                            {gain.gains_counters.find(
                              gainCounter => gainCounter.year === selectedYear,
                            )?.cumulated_pmd_minutes ?? 'N/A'}
                          </Typography>
                          <Typography color={theme.palette.text.secondary} variant="caption">
                            <FormattedMessage id="unit.mwh/PMD" />
                          </Typography>
                        </div>
                      </div>

                      <div className={styles.gainSubElement}>
                        <Typography align="left" variant="body2" component="h6">
                          <FormattedMessage id="generalDashboard.mean_gain_counter_on_pmd" />
                        </Typography>
                        <Typography fontWeight="bold">
                          {gain.gains_counters.find(
                            gainCounter => gainCounter.year === selectedYear,
                          )?.mean_gain_counter_on_pmd ?? 'N/A'}
                        </Typography>
                        <Typography color={theme.palette.text.secondary} variant="caption">
                          <FormattedMessage id="unit.mwh/PMD" />
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </CustomCard>
    </div>
  );
};

export default Gains;
