import { createSlice } from '@reduxjs/toolkit';
import { initialKpiImbalanceState } from '../states/kpiImbalance/kpiImbalance.state';
import {
  setLoadedKpiImbalanceReducer,
  setLoadingKpiImbalanceReducer,
  setResetKpiImbalanceReducer,
  setRetrieveKpiImbalanceFailureReducer,
  setRetrieveKpiImbalanceReducer,
  setRetrieveKpiImbalanceSuccessReducer,
} from './kpi-imbalance.reducer';

export const kpiImbalanceSlice = createSlice({
  name: 'kpiImbalance',
  initialState: initialKpiImbalanceState,
  reducers: {
    setRetrieveKpiImbalance: setRetrieveKpiImbalanceReducer,
    setRetrieveKpiImbalanceSuccess: setRetrieveKpiImbalanceSuccessReducer,
    setRetrieveKpiImbalanceFailure: setRetrieveKpiImbalanceFailureReducer,
    setResetKpiImbalance: setResetKpiImbalanceReducer,
    setLoadedKpiImbalance: setLoadedKpiImbalanceReducer,
    setLoadingKpiImbalance: setLoadingKpiImbalanceReducer,
  },
});

export const {
  setRetrieveKpiImbalance,
  setRetrieveKpiImbalanceSuccess,
  setRetrieveKpiImbalanceFailure,
  setResetKpiImbalance,
  setLoadedKpiImbalance,
  setLoadingKpiImbalance,
} = kpiImbalanceSlice.actions;

export const kpiImbalanceReducers = kpiImbalanceSlice.reducer;
