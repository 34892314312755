import { Reference } from '../../../../../corelogic/domain/models/reference/reference.model';
/* eslint-disable prettier/prettier */
export enum operatorLogic {
  OR = 'OR',
  AND = 'AND',
  ORAND = 'ORAND',
}

export const referenceValidationLogic = {
  ambient_temperature: operatorLogic.AND,
  ambient_pressure: operatorLogic.AND,
  relative_humidity: operatorLogic.AND,
  network_frequency: operatorLogic.AND,
  excess_gt_inlet_pressure_drop: operatorLogic.AND,
  degradation: operatorLogic.AND,
  sulfur_content: operatorLogic.AND,
  gt_exhaust_pressure_drop: operatorLogic.AND,
  wind_speed: operatorLogic.AND,
  gas_temperature: operatorLogic.AND,
  anti_icing_dew_point_temperature: operatorLogic.OR,
  anti_icing_fixed_temperature_relative_humidity: operatorLogic.AND,
  load_limit: operatorLogic.ORAND,
  fogging: operatorLogic.AND,
  lower_heating_value_and_c_h_ratio: operatorLogic.AND,
} as const;

export const moreThan4Decimals = (value: number): boolean => {
  return !/^\d+(?:\.\d{0,4})?$/.test(value.toString());
};

const andLogic = (references: Reference[]): boolean => {
  const validReferenceLogic = references.every((reference: Reference) => {
    if (reference.value === null || moreThan4Decimals(reference.value)) {
      return false;
    }
    return true;
  });
  return validReferenceLogic;
};

const orAndLogic = (references: Reference[]): boolean => {
  let validReferenceLogic = false;
  const validDecimalsForAllReferences = references.some((reference: Reference) => {
    if (reference.value !== null && moreThan4Decimals(reference.value)) {
      return false;
    }
    return true;
  });
  if (validDecimalsForAllReferences === true) {
    validReferenceLogic = references.some((reference: Reference) => {
      if (reference.value !== null && !isNaN(reference.value)) {
        return true;
      }
      return false;
    });
  }
  return validReferenceLogic;
};

const orLogic = (references: Reference[]): boolean => {
  const validReferenceLogicOrAnd = orAndLogic(references);
  const validReferenceLogicAnd = andLogic(references);

  return validReferenceLogicAnd === false && validReferenceLogicOrAnd;
};

export const isReferenceValuesValid = (references: Reference[], parameterName: string): boolean => {
  if (references.length === 0 || parameterName === '') {
    return true;
  }
  const indexOfReferenceLogic = Object.keys(referenceValidationLogic).indexOf(parameterName);
  const valueofReferenceLogic = Object.values(referenceValidationLogic)[indexOfReferenceLogic];
  let isValid = false;
  if (valueofReferenceLogic === operatorLogic.AND) {
    isValid = andLogic(references);
  } else if (valueofReferenceLogic === operatorLogic.OR) {
    isValid = orLogic(references);
  } else if (valueofReferenceLogic === operatorLogic.ORAND) {
    isValid = orAndLogic(references);
  }
  return isValid;
};

export const isInputFieldDisabled = (
  references: Reference[],
  currentReference: Reference,
  parameterName: string,
): boolean => {
  const indexOfReferenceLogic = Object.keys(referenceValidationLogic).indexOf(parameterName);
  const valueofReferenceLogic = Object.values(referenceValidationLogic)[indexOfReferenceLogic];
  if (valueofReferenceLogic === operatorLogic.OR) {
    return references
      .filter((reference: Reference) => reference.name !== currentReference.name)
      .map((reference: Reference) => {
        if (reference.value !== null && !isNaN(reference.value)) {
          return true;
        }
        return false;
      })
      .some((disabled: boolean) => disabled === true);
  }
  return false;
};
