import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GainsByYearModel } from '../../../../../../corelogic/domain/models/gain/gain.model';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { setRetrieveYearOfCurrentGain } from '../../../../../../corelogic/redux/yearOfCurrentGain/yearOfCurrentGain.slice';
import { Spinner } from '../../../../../../ui/spinner/spinner.component';

const DropDownYearWithGainList: FC = () => {
  const dispatch = useDispatch();
  const { site, isLoading } = useSelector((state: RootState) => state.site);
  const { gain } = useSelector((state: RootState) => state.gain);
  const { year } = useSelector((state: RootState) => state.yearOfCurrentGain);
  const theme = useTheme();

  const handleOptionChange = (e: SelectChangeEvent<string>) => {
    dispatch(setRetrieveYearOfCurrentGain(e.target.value));
  };

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="form-group">
      <Select
        size="small"
        style={{
          backgroundColor: theme.palette.backgroundBackground.light,
          color: theme.palette.text.primary,
        }}
        value={year}
        onChange={handleOptionChange}
        disabled={site.name === ''}
      >
        {gain?.gains_counters.map((value: GainsByYearModel) => (
          <MenuItem key={value.year} value={value.year}>
            {value.year}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DropDownYearWithGainList;
