import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { FC } from 'react';

const FilterSpecificDate: FC<GridFilterInputValueProps> = props => {
  const { item, applyValue, apiRef } = props;

  const handleFilterChange = (newValue: unknown) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <DatePicker
      value={item.value || null}
      autoFocus
      format="dd/MM/yyyy"
      label={apiRef.current.getLocaleText('filterPanelInputLabel')}
      slotProps={{
        textField: {
          variant: 'standard',
        },
        inputAdornment: {
          sx: {
            '& .MuiButtonBase-root': {
              marginRight: -1,
            },
          },
        },
      }}
      onChange={handleFilterChange}
    />
  );
};

export default FilterSpecificDate;
