import { createSlice } from '@reduxjs/toolkit';
import { initialContextCurrentParameterState } from '../states/parameter/context-current-parameter.state';

import { setContextCurrentParameterReducer } from './context-current-parameter.reducers';

export const contextCurrentParameterSlice = createSlice({
  name: 'currentParameter',
  initialState: initialContextCurrentParameterState,
  reducers: {
    setContextCurrentParameter: setContextCurrentParameterReducer,
  },
});

export const { setContextCurrentParameter } = contextCurrentParameterSlice.actions;

export const contextCurrentParameterReducers = contextCurrentParameterSlice.reducer;
