import { createSlice } from '@reduxjs/toolkit';
import {
  setRetrieveAdminRightReducer,
  setRetrieveAdminRightFailureReducer,
  setRetrieveAdminRightSuccessReducer,
} from './adminRight.reducer';
import { initialAdminState } from '../states/adminRight/adminRight.state';

export const adminRightSlice = createSlice({
  name: 'adminRight',
  initialState: initialAdminState,
  reducers: {
    setRetrieveAdminRight: setRetrieveAdminRightReducer,
    setRetrieveAdminRightSuccess: setRetrieveAdminRightSuccessReducer,
    setRetrieveAdminRightFailure: setRetrieveAdminRightFailureReducer,
  },
});

export const { setRetrieveAdminRight, setRetrieveAdminRightSuccess, setRetrieveAdminRightFailure } =
  adminRightSlice.actions;

export const adminRightReducers = adminRightSlice.reducer;
