import { CardContent, Grid } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import CardComponent from '../../../cards/cardComponent/cardComponent';
import CardComponentStatus from '../../../cards/cardComponentStatus/cardComponentStatus';
import CardComponentWithIcon from '../../../cards/cardComponentWithIcon/cardComponentWithIcon';
import { EfficiencyData } from '../../../type';
import styles from './efficiency.module.scss';
type Props = { data: EfficiencyData };

const Efficiency: FC<Props> = ({ data }) => {
  const intl = useIntl();

  return (
    <CardContent className={styles.parent_gap_container}>
      <Grid container spacing={1.5}>
        <Grid item xs>
          <CardComponentWithIcon
            title={intl.formatMessage({ id: 'generalDashboard.runningProgram' })}
            icon={'icon.run'}
            iconColor="#FF5722"
            value={data.hhv_efficiency_running_program?.toFixed(2) ?? 'N/A'}
            unit={'%'}
            helper={intl.formatMessage({
              id: 'generalDashboard.helper.runningProgram',
            })}
          />
        </Grid>
        <Grid item xs>
          <CardComponentWithIcon
            title={intl.formatMessage({ id: 'generalDashboard.hhvEfficiency' })}
            icon={'icon.bolt'}
            iconColor="#2196F3"
            value={data.hhv_efficiency?.toFixed(2) ?? 'N/A'}
            unit={'%'}
            helper={intl.formatMessage({
              id: 'generalDashboard.helper.netActivePower',
            })}
          />
        </Grid>

        <Grid item xs>
          <CardComponentWithIcon
            title={data.hhv_efficiency_p2m_forecast_curveName}
            icon={'icon.event'}
            iconColor="#E91E63"
            value={data.hhv_efficiency_p2m_forecast?.toFixed(2) ?? 'N/A'}
            unit={'%'}
            helper={data.hhv_efficiency_p2m_forecast_helper}
          />
        </Grid>
        <Grid item xs>
          <CardComponentWithIcon
            title={data.hhv_efficiency_p2m_estimate_curveName}
            icon={'icon.modelTraining'}
            iconColor="#9C27B0"
            value={data.hhv_efficiency_p2m_estimate?.toFixed(2) ?? 'N/A'}
            unit={'%'}
            helper={data.hhv_efficiency_p2m_estimate_helper}
          />
        </Grid>
        <Grid item xs>
          <CardComponentWithIcon
            title={data.hhv_efficiency_oem_estimate_curveName}
            icon={'icon.lineAxis'}
            iconColor="#40A900"
            value={data.hhv_efficiency_oem_estimate?.toFixed(2) ?? 'N/A'}
            unit={'%'}
            helper={data.hhv_efficiency_oem_estimate_helper}
          />
        </Grid>
        <Grid item xs>
          <CardComponentWithIcon
            title={intl.formatMessage({ id: 'generalDashboard.modelReliability' })}
            icon={'icon.handshake'}
            iconColor="#E91E63"
            value={data.efficiency_model_reliability?.toFixed(2) ?? 'N/A'}
            unit={'pt%'}
            helper={data.hhv_efficiency_model_reliability_helper}
          />
        </Grid>
      </Grid>

      <CardComponent isCurves={true} isEfficiency={true} efficiencyData={data} />
      <div className={styles.imbalanceAndStatus}>
        <div className={styles.status}>
          <CardComponentStatus title="generalDashboard.status.title" />
        </div>
        <div className={styles.imbalances}>
          <CardComponent
            title="generalDashboard.imbalances.title"
            isImbalance={true}
            isEfficiency={true}
            efficiencyData={data}
          />
        </div>
      </div>
    </CardContent>
  );
};

export default Efficiency;
