import { PayloadAction } from '@reduxjs/toolkit';
import { HiddenCurveState } from '../states/hiddenCurve/hiddenCurve.state';

export const setHiddenCurvesReducer = (
  state: HiddenCurveState,
  action: PayloadAction<string[]>,
) => {
  state.hiddenCurveList = action.payload;
  state.isLoading = false;
};
