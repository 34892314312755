import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { KpiImbalance } from '../../../../../../../corelogic/domain/models/kpiImbalance/kpiImbalance.model';
import { RootState } from '../../../../../../../corelogic/redux/create-store';
import { setHiddenCurves } from '../../../../../../../corelogic/redux/hiddenCurve/hiddenCurve.slice';
import { CustomCard } from '../../../../../../../util/functionUtil';
import TabComponent from '../../../tabComponent/tabComponent';
import { EfficiencyData, PAGE } from '../../../type';
import Efficiency from '../efficenciency/efficiency';
import styles from './efficiencySelector.module.scss';

const EfficiencySelector: FC = () => {
  const { graphIndex } = useSelector((state: RootState) => state.graphIndex);
  const { kpiImbalance } = useSelector((state: RootState) => state.kpiImbalance);
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentKpi: KpiImbalance = kpiImbalance[graphIndex];
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const [selectedModelType, setSelectedModelType] = useState<string>('Max Efficiency');
  const { hiddenCurveList } = useSelector((state: RootState) => state.hiddenCurves);

  const fieldInTab: Record<string, string> = {
    'Max Efficiency': intl.formatMessage({ id: 'generalDashboard.max_efficiency' }),
    'Min Efficiency': intl.formatMessage({ id: 'generalDashboard.min_efficiency' }),
  };

  useEffect(() => {
    const filteredList = hiddenCurveList.filter(
      curveName =>
        curveName !==
        intl.formatMessage({
          id:
            selectedModelType !== 'Max Efficiency'
              ? 'generalDashboard.minEfficiencyModel'
              : 'generalDashboard.maxEfficiencyModel',
        }),
    );
    filteredList.push(
      intl.formatMessage({
        id:
          selectedModelType === 'Max Efficiency'
            ? 'generalDashboard.minEfficiencyModel'
            : 'generalDashboard.maxEfficiencyModel',
      }),
    );
    dispatch(setHiddenCurves(filteredList));
  }, [selectedModelType]);

  const efficiencyData: EfficiencyData =
    selectedModelType === 'Max Efficiency'
      ? {
          title: PAGE.MAX_EFFICIENCY,
          efficiency_model_reliability: currentKpi?.efficiency_model_reliability,
          hhv_efficiency: currentKpi?.hhv_efficiency,
          hhv_efficiency_p2m_forecast: currentKpi?.hhv_efficiency_p2m_forecast,
          hhv_efficiency_min_p2m_estimate: currentKpi?.hhv_efficiency_min_p2m_estimate,
          hhv_efficiency_oem_estimate: currentKpi?.hhv_efficiency_oem_estimate,
          hhv_efficiency_p2m_estimate: currentKpi?.hhv_efficiency_p2m_estimate,
          hhv_efficiency_running_program: currentKpi?.hhv_efficiency_running_program,
          hhv_efficiency_oem_estimate_curveName: intl.formatMessage({
            id: 'generalDashboard.maxEfficiencyOEM',
          }),
          hhv_efficiency_p2m_estimate_curveName: intl.formatMessage({
            id: 'generalDashboard.maxEfficiencyModel',
          }),
          hhv_efficiency_p2m_forecast_curveName: intl.formatMessage({
            id: 'generalDashboard.maxEfficiencyForecast',
          }),
          hhv_efficiency_min_p2m_estimate_curveName: intl.formatMessage({
            id: 'generalDashboard.minEfficiencyModel',
          }),
          min: false,
          hhv_efficiency_oem_estimate_helper: intl.formatMessage({
            id: 'generalDashboard.helper.efficiency.efficiencyPmaxOEM',
          }),
          hhv_efficiency_p2m_estimate_helper: intl.formatMessage({
            id: 'generalDashboard.helper.efficiency.efficiencyPmaxModel',
          }),
          hhv_efficiency_p2m_forecast_helper: intl.formatMessage({
            id: 'generalDashboard.helper.efficiency.efficiencyPmaxModelForecast',
          }),
          hhv_efficiency_model_reliability_helper: intl.formatMessage({
            id: 'generalDashboard.helper.efficiency.modelReliabilityMaxEfficiency',
          }),
        }
      : {
          title: PAGE.MIN_EFFICIENCY,
          efficiency_model_reliability: currentKpi?.efficiency_min_model_reliability,
          hhv_efficiency: currentKpi?.hhv_efficiency,
          hhv_efficiency_p2m_forecast: currentKpi?.hhv_efficiency_min_p2m_forecast,
          hhv_efficiency_min_p2m_estimate: currentKpi?.hhv_efficiency_min_p2m_estimate,
          hhv_efficiency_oem_estimate: currentKpi?.hhv_efficiency_min_oem_estimate,
          hhv_efficiency_p2m_estimate: currentKpi?.hhv_efficiency_min_p2m_estimate,
          hhv_efficiency_running_program: currentKpi?.hhv_efficiency_running_program,
          hhv_efficiency_oem_estimate_curveName: intl.formatMessage({
            id: 'generalDashboard.minEfficiencyOEM',
          }),
          hhv_efficiency_p2m_estimate_curveName: intl.formatMessage({
            id: 'generalDashboard.maxEfficiencyModel',
          }),
          hhv_efficiency_p2m_forecast_curveName: intl.formatMessage({
            id: 'generalDashboard.minEfficiencyForecast',
          }),
          hhv_efficiency_min_p2m_estimate_curveName: intl.formatMessage({
            id: 'generalDashboard.minEfficiencyModel',
          }),
          min: true,
          hhv_efficiency_oem_estimate_helper: intl.formatMessage({
            id: 'generalDashboard.helper.efficiency.minEfficiencyPmaxOEM',
          }),
          hhv_efficiency_p2m_estimate_helper: intl.formatMessage({
            id: 'generalDashboard.helper.efficiency.minEfficiencyPmaxModel',
          }),
          hhv_efficiency_p2m_forecast_helper: intl.formatMessage({
            id: 'generalDashboard.helper.efficiency.minEfficiencyPmaxModelForecast',
          }),
          hhv_efficiency_model_reliability_helper: intl.formatMessage({
            id: 'generalDashboard.helper.efficiency.modelReliabilityMinEfficiency',
          }),
        };
  return (
    <div className={clsx(styles.pageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined" sx={{ alignItems: 'flex-start' }}>
        <TabComponent
          selectedTab={selectedModelType}
          setSelectedTab={setSelectedModelType}
          fieldInTab={fieldInTab}
          buttonMessage={['Max Efficiency', 'Min Efficiency']}
        />
        <Efficiency data={efficiencyData} />
      </CustomCard>
    </div>
  );
};

export default EfficiencySelector;
