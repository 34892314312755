import { useTheme } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../../../../../../../../ui/spinner/spinner.component';
import styleSpinner from '../../../../../../../../../ui/spinner/spinner.component.module.scss';
import styles from './imbalanceDetails.module.scss';

type Details = {
  value?: number | null;
  name: string;
  isTitle: boolean;
  size: number;
  showLoader: boolean;
};

const ImbalanceDetail: FC<Details> = ({ value, name, isTitle, size, showLoader }) => {
  const theme = useTheme();
  return (
    <div className={isTitle ? styles.detailsTitle : styles.detailsElement}>
      <div>
        <FormattedMessage id={'generalDashboard.imbalances.' + name} />
      </div>
      {showLoader === true ? (
        <Spinner className={styleSpinner.spinnerSmall} />
      ) : (
        <div className={styles.valueAndUnit}>
          {value !== null && value != undefined ? (
            <div className={styles.value} style={{ fontSize: size }}>
              {value.toFixed(2)}
            </div>
          ) : (
            <div
              className={styles.value}
              style={{ fontSize: size, color: theme.palette.text.primary }}
            >
              <FormattedMessage id="generalDashboard.NA" />
            </div>
          )}
          <div
            className={styles.unit}
            style={{ fontSize: size, color: theme.palette.text.secondary }}
          >
            <FormattedMessage id="unit.mw" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImbalanceDetail;
