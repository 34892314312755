import { PlotData } from 'plotly.js';

/* eslint-disable prettier/prettier */
export type MarginGraph = {
  l: number;
  r: number;
  b: number;
  t: number;
  pad: number;
};

export type ErrorByCurve = {
  name: string;
  errorMessage: Error;
};

export enum TYPE_CURVE_PARAMETER {
  MonoVariable = 'monovariable',
  Bivariable = 'bivariable',
}

export enum CURVE_TYPE {
  PowerOutput = 'Power Output',
  HeatRate = 'Heat Rate',
}

export type DateAndKpiValue = {
  keys: string[];
  values: number[];
};

export type tabWithSubtab = {
  tabName: string;
  subTabName: string;
  path: string;
};

export type EfficiencyData = {
  title: string;
  min: boolean;
  hhv_efficiency: number | null;
  hhv_efficiency_running_program: number | null;
  hhv_efficiency_p2m_forecast: number | null;
  hhv_efficiency_p2m_estimate: number | null;
  hhv_efficiency_min_p2m_estimate: number | null;
  hhv_efficiency_oem_estimate: number | null;
  efficiency_model_reliability: number | null;
  hhv_efficiency_min_p2m_estimate_curveName: string;
  hhv_efficiency_oem_estimate_curveName: string;
  hhv_efficiency_p2m_forecast_curveName: string;
  hhv_efficiency_p2m_estimate_curveName: string;
  hhv_efficiency_oem_estimate_helper: string;
  hhv_efficiency_p2m_estimate_helper: string;
  hhv_efficiency_p2m_forecast_helper: string;
  hhv_efficiency_model_reliability_helper: string;
};

export enum PARAMETERS_LIST {
  AMBIENT_TEMPERATURE = 'Ambient Temperature',
  AMBIENT_PRESSURE = 'Ambient Pressure',
  RELATIVE_HUMIDITY = 'Relative Humidity',
  GRID_FREQUENCY = 'Grid Frequency',
  LOWER_HEATING_VALUE_CH = 'Lower Heating Value and C/H',
  EXCESS_GT_INLET_PRESSURE_DROP = 'Excess GT inlet Pressure Drop',
  ANTI_ICING_DEW_POINT_TEMPERATURE = 'Anti-icing Dew Point Temperature',
  ANTI_ICING_FIXED_TEMPERATURE_RELATIVE_HUMIDITY = 'Anti-icing Fixed Temperature - Relative Humidity',
  DEGRADATION = 'Degradation',
  SULFUR_CONTENT = 'Sulfur Content',
  GT_EXHAUST_PRESSURE_DROP = 'GT Exhaust Pressure Drop',
  WIND_SPEED = 'Wind Speed',
  GT_FUEL_GAS_INLET_TEMPERATURE = 'GT Fuel Gas Inlet Temperature',
  FOGGING = 'Fogging',
  LOAD_LIMIT = 'Load Limit',
}

export enum KPIS_LIST {
  PMD = 'pmd',
  ANTI_ICING_CONTROL = 'anti_icing_control',
  IGV = 'igv',
  NET_ACTIVE_POWER = 'netActivePower',
  RUNNING_PROGRAM = 'runningProgram',
  P_MAX_MODEL = 'pmaxModel',
  P_MAX_OEM_CURVES = 'pmaxOemCurves',
  P_MAX_MODEL_FORECAST = 'pmaxModelForecast',
  HHV_EFFICIENCY_OEM_ESTIMATE = 'hhvEfficiencyOemEstimate',
  HHV_EFFICIENCY = 'hhvEfficiency',
  HHV_EFFICIENCY_P2M_ESTIMATE = 'hhvEfficiencyP2mEstimate',
  HHV_EFFICIENCY_P2M_FORECAST = 'hhvEfficiencyP2mForecast',
  MODEL_RELIABILITY = 'modelReliability',
}

export enum IMBALANCE_TYPE {
  weather = 'weather',
  ambientTemperature = 'ambientTemperature',
  ambientPressure = 'ambientPressure',
  ambientRelativeHumidity = 'ambientRelativeHumidity',
  ambientWindSpeed = 'ambientWindSpeed',
  networkFrequency = 'network',
  gasLHV = 'lhv',
  gasCHRatio = 'chRatio',
  other = 'other',
  otherForecast = 'otherForecast',
}

export enum PAGE {
  EFFICIENCY = 'Efficiency',
  MAX_EFFICIENCY = 'Max Efficiency',
  MIN_EFFICIENCY = 'Min Efficiency',
  POWER = 'Power',
}

export enum KPI_CURVE {
  FORECAST_IMBALANCE = 'forecastImbalance',
  WEATHER_TECHNICAL_FORECAST = 'weatherAndTechnicalForecast',
  WEATHER_FORECAST = 'weatherForecast',
  TECHNICAL_FORECAST = 'technicalForecast',
}

export type ExtendedPlotData = Partial<PlotData> & { duration?: (string | null)[] };

export enum COLUMN_LABEL {
  NAME = 'name',
  FILE_NAME = 'fileName',
  STATUS = 'status',
  CREATION_DATE = 'creationDate',
  EFFECTIVE_DATE = 'effectiveDate',
  DOWNLOAD = 'download',
  MODEL_TYPE = 'modelType',
}

export enum COLUMN_LABEL_DAILY_CAPACITY {
  NAME = 'name',
  FILE_NAME = 'file_name',
  FILE_SITE_NAME = 'file_site_name',
  STATUS = 'status',
  CREATION_DATE = 'creation_date',
  DOWNLOAD = 'download',
  MODEL_TYPE = 'modelType',
}

export enum COLUMN_LABEL_EDIT_TAGS {
  NAME = 'name',
  TAG = 'tag',
  CONSTANT = 'constant',
  LAST_MODIFICATION = 'lastModification',
}

export enum MODEL_TYPE {
  MAX_EFFICIENCY = 'EfficiencyMax',
  MIN_EFFICIENCY = 'EfficiencyMin',
  POWER = 'Power',
}
