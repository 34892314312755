import { useMsal } from '@azure/msal-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  styled,
  useTheme,
} from '@mui/material';
import logo from '@totalenergiescode/design-system/dist/TotalEnergies_logo_2x.png';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import clsx from 'clsx';
import { addDays } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../../constants/path';
import { SiteNameIdDto } from '../../../../../corelogic/domain/models/site/siteNameId.dto';
import { Roles } from '../../../../../corelogic/domain/usecases/site/adminRight/adminRight.usecase';
import { setRetrieveAdminRight } from '../../../../../corelogic/redux/adminRight/adminRight.slice';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setGraphIndex } from '../../../../../corelogic/redux/currentTimeseries/graphIndex/graphDate/graphIndex.slice';
import { setIsDarkMode } from '../../../../../corelogic/redux/isDarkMode/is-dark-mode.slice';
import { setContextCurrentMenuStatus } from '../../../../../corelogic/redux/isMenuOpened/current-menu-status.slice';
import { setIsSuperAdminMode } from '../../../../../corelogic/redux/isSuperAdminMode/is-super-admin-mode.slice';
import { setRetrieveKpiImbalance } from '../../../../../corelogic/redux/kpiImbalance/kpi-imbalance.slice';
import {
  setDeleteSite,
  setRetrieveSite,
  setUpdateSiteParameter,
} from '../../../../../corelogic/redux/site/site.slice';
import { setRetrieveSiteNamesIds } from '../../../../../corelogic/redux/siteNamesIds/siteNamesIds.slice';
import { setIsUpdatedSiteName } from '../../../../../corelogic/redux/updatedSiteNameBoolean/is-updated-site-name.slice';
import { CustomButton, MaterialUISwitch } from '../../../../../util/functionUtil';
import { LocalStorageGateway } from '../../../../secondaries/gateways/browser-storage/local-storage.gateway';
import { NUMBER_OF_DAYS } from '../graph/timeseriesGraph/constant';
import MenuComponent from '../menu/menu';
import NewSitePopin from '../newSitePopin/newSitePopin';
import styles from './headerPage.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WalletIcon from '@mui/icons-material/Wallet';
import { Link } from 'react-router-dom';
type Props = { language: string; setLanguage: (language: string) => void };

const Header: FC<Props> = ({ language, setLanguage }) => {
  const localStorage = new LocalStorageGateway();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { siteNamesIds } = useSelector((state: RootState) => state.siteNamesIds);
  const { site } = useSelector((state: RootState) => state.site);
  const { kpiImbalance } = useSelector((state: RootState) => state.kpiImbalance);
  const { admin } = useSelector((state: RootState) => state.admin);
  const { isSuperAdminMode } = useSelector((state: RootState) => state.isSuperAdminMode);
  const { isDarkMode } = useSelector((state: RootState) => state.isDarkMode);
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const { startDate } = useSelector((state: RootState) => state.startDate);

  const { accounts } = useMsal();
  const intl = useIntl();
  const theme = useTheme();
  const location = useLocation().pathname;

  const [open, setOpen] = useState(false);
  const [openEditSiteName, setOpenEditSiteName] = useState(false);
  const [siteNewName, setSiteNewName] = useState<string | null>(null);
  const handleEditSiteNameOpen = () => setOpenEditSiteName(true);
  const handleEditSiteNameClose = (newName?: boolean) => {
    if (newName && siteNewName) {
      const newSiteName = { name: siteNewName };
      dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: newSiteName }));
      setSiteNewName('');
    }
    setOpenEditSiteName(false);
  };

  useEffect(() => {
    dispatch(setRetrieveSiteNamesIds());
    dispatch(setIsSuperAdminMode(admin.some((role: string) => role === Roles.superAdmin)));
  }, []);

  useEffect(() => {
    dispatch(setGraphIndex(kpiImbalance.length - 1));
  }, [kpiImbalance]);

  const onClickButton = async (siteId: string) => {
    dispatch(setRetrieveSite(siteId));
    dispatch(setIsUpdatedSiteName({ errorTitleMessage: '', isUpdatedSiteName: false }));
    dispatch(
      setRetrieveKpiImbalance({
        siteId,
        startDate: startDate,
        endDate: addDays(new Date(startDate), NUMBER_OF_DAYS).toISOString(),
      }),
    );
    dispatch(setRetrieveAdminRight(siteId));
    navigate(PATHS.GENERAL_DASHBOARD);
    localStorage.store('favouriteSite', siteId);
  };

  const onClickDelete = async (siteId: string) => {
    dispatch(setDeleteSite({ siteId: siteId }));
    onClickButton(siteNamesIds.filter(id => id._id != siteId)[0]._id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (site._id == '' && siteNamesIds.length > 0) {
    onClickButton(siteNamesIds[0]._id);
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const nameTaken = (name: string | null) => {
    if (name === null) return false;
    let taken = false;
    siteNamesIds.forEach(site => {
      if (site.name === name) {
        taken = true;
      }
    });
    return taken;
  };

  const handleDrawerOpen = () => {
    dispatch(setContextCurrentMenuStatus(true));
  };

  const handleDrawerClose = () => {
    dispatch(setContextCurrentMenuStatus(false));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsSuperAdminMode(event.target.checked));
  };

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsDarkMode(event.target.checked));
  };

  function getErrorMessage(siteNewName: string | null): import('react').ReactNode {
    if (siteNewName === '') {
      return intl.formatMessage({ id: 'errorMessage.emptyName' });
    } else if (nameTaken(siteNewName)) return intl.formatMessage({ id: 'errorMessage.nameTaken' });
  }

  useEffect(() => {
    if (location === PATHS.OVERVIEW) {
      dispatch(setContextCurrentMenuStatus(false));
    }
  });

  return (
    <nav className="navbar navbar-expand-sm flex-column" aria-label="navbar">
      {!location.includes(PATHS.OVERVIEW) && (
        <Drawer
          sx={{
            width: 250,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 250,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={menuStatus}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <span className="material-icons-outlined">menu_open</span>
            </IconButton>
          </DrawerHeader>
          <MenuComponent />
        </Drawer>
      )}
      <div
        style={{ backgroundColor: theme.palette.background.paper, paddingRight: 0 }}
        className={clsx('container-fluid', 'header-with-burger', menuStatus && styles.menuOn)}
      >
        {!location.includes(PATHS.OVERVIEW) && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(menuStatus && { display: 'none' }) }}
          >
            <span className="material-icons-outlined">menu</span>
          </IconButton>
        )}
        <div className="navbar-brand text-primary">
          <img src="P2M-Vector.svg" width={40} height={40} />
        </div>
        <span
          style={{
            fontWeight: '700',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
          }}
        >
          <FormattedMessage id="home.Power2Market" />
        </span>
        <ul className="navbar-nav ms-auto d-flex flex-row">
          {!location.includes(PATHS.OVERVIEW) && !location.includes(PATHS.GAINS) && (
            <li className="nav-item d-none d-xl-block">
              <div className="dropdown ms-4">
                <div className="d-flex">
                  <span className="navbar-text" style={{ color: theme.palette.text.secondary }}>
                    <strong>
                      <FormattedMessage id="home.CCGT" />
                    </strong>
                  </span>
                  <CustomButton
                    endIcon={<ExpandMoreIcon />}
                    type="button"
                    id="localisation3"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    sx={{
                      ':hover': {
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                      },
                    }}
                    style={{ color: theme.palette.text.primary, textTransform: 'uppercase' }}
                  >
                    {site !== undefined ? site.name : 'Bayet'}
                  </CustomButton>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="localisation3"
                    style={{ backgroundColor: theme.palette.background.paper }}
                  >
                    {siteNamesIds.map((siteNameId: SiteNameIdDto) => (
                      <li key={siteNameId?._id}>
                        <div className={styles.newSiteDropdown}>
                          <button
                            className="dropdown-item"
                            style={{ color: theme.palette.text.primary }}
                            onClick={() => onClickButton(siteNameId?._id)}
                          >
                            {siteNameId?.name}
                          </button>
                          {admin.some(a => a === Roles.superAdmin) && (
                            <CustomButton
                              sx={{ minWidth: '0px' }}
                              size="small"
                              className={styles.dropdownItem}
                              onClick={() => {
                                if (
                                  confirm(intl.formatMessage({ id: 'home.deletionConfirmation' }))
                                ) {
                                  onClickDelete(siteNameId?._id);
                                }
                              }}
                            >
                              <span className="material-icons-outlined">
                                <FormattedMessage id="icon.delete" />
                              </span>
                            </CustomButton>
                          )}
                        </div>
                      </li>
                    ))}
                    {admin.some(a => a === Roles.superAdmin) && (
                      <li>
                        <CustomButton
                          className={clsx('dropdown-item', styles.newSite)}
                          onClick={handleOpen}
                          type="button"
                        >
                          <span className="material-icons-outlined">add_box</span>
                          <FormattedMessage id="addNewPopin.addNew" />
                        </CustomButton>
                        {open === true && <NewSitePopin isOpen={open} handleClose={handleClose} />}
                      </li>
                    )}
                  </ul>
                  <Dialog
                    open={openEditSiteName}
                    onClose={() => handleEditSiteNameClose()}
                    slotProps={{
                      backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } },
                    }}
                    PaperProps={{ style: { borderRadius: '16px' } }}
                  >
                    <DialogTitle>
                      <FormattedMessage id="addNewPopin.name" />:
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        id="name"
                        variant="outlined"
                        value={siteNewName ?? site.name}
                        onChange={e => setSiteNewName(e.target.value)}
                        error={siteNewName === '' || nameTaken(siteNewName)}
                        helperText={getErrorMessage(siteNewName)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <CustomButton onClick={() => handleEditSiteNameClose()} variant="outlined">
                        <FormattedMessage id="button.cancel" />
                      </CustomButton>
                      <CustomButton
                        onClick={() => handleEditSiteNameClose(true)}
                        type="submit"
                        variant="contained"
                      >
                        <FormattedMessage id="button.save" />
                      </CustomButton>
                    </DialogActions>
                  </Dialog>
                  {admin.some(a => a === Roles.admin) && (
                    <CustomButton
                      sx={{ minWidth: '0px' }}
                      size="small"
                      className={styles.dropdownItem}
                      onClick={handleEditSiteNameOpen}
                    >
                      <span className="material-icons-outlined">
                        <FormattedMessage id="icon.edit" />
                      </span>
                    </CustomButton>
                  )}
                </div>
              </div>
            </li>
          )}
          <IconButton component={Link} to="/gains">
            <WalletIcon color="primary" />
          </IconButton>
          <li className="nav-item">
            <div className={clsx('d-flex ms-md-4', styles.nameAndAdmin)}>
              {accounts.length > 0 && (
                <span
                  data-hj-suppress
                  className="navbar-text d-md-inline"
                  style={{ color: theme.palette.text.secondary, textTransform: 'capitalize' }}
                >
                  {accounts[0].name?.toLowerCase()}
                </span>
              )}
              {admin.some(a => a === Roles.superAdmin) && (
                <FormControlLabel
                  control={
                    <Switch checked={isSuperAdminMode} color="primary" onChange={handleChange} />
                  }
                  label={intl.formatMessage({ id: 'button.superAdminMode' })}
                  sx={{
                    color: isSuperAdminMode
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  }}
                />
              )}
              <MaterialUISwitch
                style={{ display: 'flex', alignSelf: 'center' }}
                checked={isDarkMode}
                onChange={handleThemeChange}
              />
            </div>
          </li>
          <li className="nav-item d-none d-xl-block">
            <div className="dropdown ms-4">
              <div className="d-flex">
                <CustomButton
                  type="button"
                  id="localisation3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  sx={{
                    color: theme.palette.text.primary,
                    textTransform: 'uppercase',
                    ':hover': {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  }}
                  endIcon={<ExpandMoreIcon />}
                >
                  {language}
                </CustomButton>

                <ul
                  className="dropdown-menu dropdown-menu-end"
                  style={{ backgroundColor: theme.palette.background.paper }}
                  aria-labelledby="localisation3"
                >
                  {language != 'fr' && (
                    <li>
                      <Button
                        className="dropdown-item"
                        style={{ color: theme.palette.text.primary }}
                        onClick={() => {
                          window.localStorage.setItem('language', 'fr');
                          setLanguage('fr');
                        }}
                      >
                        <div className={styles.langAndFlag}>{'Fr'}</div>
                      </Button>
                    </li>
                  )}
                  {language != 'en' && (
                    <li>
                      <Button
                        className="dropdown-item"
                        style={{ color: theme.palette.text.primary }}
                        onClick={() => {
                          window.localStorage.setItem('language', 'en');
                          setLanguage('en');
                        }}
                      >
                        <div className={styles.langAndFlag}>{'En'}</div>
                      </Button>
                    </li>
                  )}
                  {language != 'es' && (
                    <li>
                      <Button
                        className="dropdown-item"
                        style={{ color: theme.palette.text.primary }}
                        onClick={() => {
                          window.localStorage.setItem('language', 'es');
                          setLanguage('es');
                        }}
                      >
                        <div className={styles.langAndFlag}>{'Es'}</div>
                      </Button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </li>
        </ul>
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
      </div>
    </nav>
  );
};

export default Header;
