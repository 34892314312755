import { createSlice } from '@reduxjs/toolkit';
import {
  setResetOverviewReducer,
  setRetrieveOverviewFailureReducer,
  setRetrieveOverviewReducer,
  setRetrieveOverviewSuccessReducer,
} from './overview.reducer';
import { initialOverviewState } from '../states/overview/overview.state';

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: initialOverviewState,
  reducers: {
    setRetrieveOverview: setRetrieveOverviewReducer,
    setRetrieveOverviewSuccess: setRetrieveOverviewSuccessReducer,
    setRetrieveOverviewFailure: setRetrieveOverviewFailureReducer,
    setResetOverview: setResetOverviewReducer,
  },
});

export const {
  setRetrieveOverview,
  setRetrieveOverviewSuccess,
  setRetrieveOverviewFailure,
  setResetOverview,
} = overviewSlice.actions;

export const overviewReducers = overviewSlice.reducer;
