import { PayloadAction } from '@reduxjs/toolkit';
import { OverviewDTO } from '../../domain/models/overview/overview.dto';
import { OverviewState } from '../states/overview/overview.state';

export const setRetrieveOverviewReducer = (
  state: OverviewState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
) => {
  state.isLoading = true;
};

export const setRetrieveOverviewSuccessReducer = (
  state: OverviewState,
  action: PayloadAction<OverviewDTO[]>,
) => {
  state.isLoading = false;
  state.overview = action.payload;
};

export const setRetrieveOverviewFailureReducer = (
  state: OverviewState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false;
  state.error = action.payload;
  state.overview = [];
};

export const setResetOverviewReducer = (state: OverviewState) => {
  state.overview = [];
  state.isLoading = false;
};
