import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { CardContent } from '@mui/material';
import '@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
import '@totalenergiescode/design-system/dist/js/totalenergies-design-system.min.js';
import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CorrectionMap } from '../../../../../corelogic/domain/models/correctionMap/correctionMap.model';
import { Curve } from '../../../../../corelogic/domain/models/curve/curve.model';
import { CurveData } from '../../../../../corelogic/domain/models/curve/curveData.model';
import {
  Reference,
  SiteGroupReference,
} from '../../../../../corelogic/domain/models/reference/reference.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setIsUpdatedSiteName } from '../../../../../corelogic/redux/updatedSiteNameBoolean/is-updated-site-name.slice';
import { getFormattedDate } from '../../../../../helpers/dateFunction';
import { CustomButton, CustomCard, findMinMax } from '../../../../../util/functionUtil';
import DropDownParameterList from '../dropdown/dropDownSiteList/dropdown-parameters-list';
import ConstructorCurveGraph from '../graph/constructorCurveGraph/constructor-curve-graph';
import { createPlotlyData } from '../graph/constructorCurveGraph/function-data-constructor';
import IdentitySite from '../identitySite/identitySite';
import P2MPopin from '../popinCurve/popin';
import { CURVE_TYPE, MarginGraph } from '../type';
import { referencesMapping } from '../uploadPopinFiles/parameterNameMapping';
import { ParameterNameVariableMapper } from '../uploadPopinFiles/parameterNameMonoMulti';
import styles from './manufacturerInformation.module.scss';

const ManufacturerInformation: FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { site } = useSelector((state: RootState) => state.site);
  const { isAdmin } = useSelector((state: RootState) => state.isAdmin);
  const { parameterName } = useSelector((state: RootState) => state.currentParameter);
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);
  const curvesRef = useRef<HTMLDivElement>(null);
  const curveContainerRef = useRef<HTMLDivElement>(null);
  const [curvesWidth, setCurvesWidth] = useState(0);

  function handleResize() {
    if (curvesRef?.current?.clientWidth) {
      setCurvesWidth(curvesRef?.current?.clientWidth);
    }
  }

  useEffect(() => {
    handleResize();
  }, [curveContainerRef.current?.clientWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const siteGroupReference: SiteGroupReference | undefined = site.siteGroupReferences
    ? site.siteGroupReferences.find((siteGroupReference: SiteGroupReference) => {
        return siteGroupReference.name === parameterName;
      })
    : undefined;

  const correctionCurve: Curve[] | undefined = site.correction_parameters
    ? site.correction_parameters
        .filter((parameter: CorrectionMap) => parameter.name === parameterName)
        .map((curve: CorrectionMap) => curve.correction)[0]
    : undefined;

  const isCurvesDefined: boolean | undefined = correctionCurve
    ?.map((curve: Curve) => curve.data)
    .some((data: CurveData) => data.x1.length !== 0);

  const isReferencesValueDefined: boolean | undefined = siteGroupReference?.references
    .map((reference: Reference) => reference.value)
    .some((value: number | null) => value !== null);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    dispatch(setIsUpdatedSiteName({ errorTitleMessage: '', isUpdatedSiteName: false }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const marginGraphInCard: MarginGraph = {
    l: 90,
    r: 50,
    b: 70,
    t: 15,
    pad: 10,
  };

  return (
    <div className={clsx(styles.manufacturerPageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined">
        <CardContent className={styles.parent_gap_container}>
          <div className={styles.manufacturer_information_title}>
            <h1>
              <FormattedMessage id="home.manufacturerInformation" />
            </h1>
          </div>
          <IdentitySite />
          <div className={styles.parameterCardAndGraphContainer} ref={curveContainerRef}>
            <div className={styles.parameterAndReferenceInformationContainer}>
              <div className={styles.textAndButtonContainer}>
                <div className={styles.iconAndTextContainer}>
                  <StackedLineChartIcon color="primary" />
                  <h3 className={clsx('text-start', styles.subTitle)}>
                    <FormattedMessage id="home.OEM" />
                    <DropDownParameterList />
                  </h3>
                </div>
                {isAdmin && (
                  <CustomButton
                    variant="outlined"
                    type="button"
                    onClick={handleOpen}
                    disabled={site.name === ''}
                    className={styles.buttonContainer}
                  >
                    <FormattedMessage id="button.editCorrectionCurves" />
                  </CustomButton>
                )}
                {open === true && <P2MPopin isOpen={open} handleClose={handleClose} />}
              </div>
              {siteGroupReference !== undefined &&
                parameterName !== undefined &&
                isReferencesValueDefined &&
                siteGroupReference.references.length > 0 && (
                  <div className={styles.referenceAndDateOfModification}>
                    <div className={styles.referenceValueContainer}>
                      <FormattedMessage id="home.referenceValue" />
                    </div>
                    <div className={styles.modificationDateContainer}>
                      <FormattedMessage id="home.lastModification" />
                      {siteGroupReference?.last_modification &&
                        getFormattedDate(new Date(siteGroupReference?.last_modification))}
                    </div>
                  </div>
                )}
              <div className={styles.referencesContainer}>
                {siteGroupReference !== undefined &&
                isReferencesValueDefined &&
                parameterName !== undefined ? (
                  siteGroupReference.references.map((reference: Reference) => {
                    return (
                      reference.value !== null && (
                        <div key={reference.name}>
                          <div className={styles.referenceContainer}>
                            <div className={styles.referenceNameContainer}>
                              {referencesMapping[reference.name as keyof typeof referencesMapping]}
                            </div>
                            <div className={styles.valueAndUnit}>
                              <div className={styles.referenceValue}> {reference.value}</div>
                              <div className={styles.unit}> {reference.unit}</div>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className={styles.errorMessage}>
                    <FormattedMessage id="home.noInformation" />
                  </div>
                )}
              </div>
            </div>
            {correctionCurve !== undefined && isCurvesDefined && (
              <div className={styles.curvesContainer} ref={curvesRef}>
                {[...correctionCurve]
                  .sort((a: Curve, b: Curve) => b.name.localeCompare(a.name))
                  .map((curve: Curve) => (
                    <div key={curve.name} className={styles.curveAndTitle}>
                      <div className={styles.curveNameAndDate}>
                        <div className={styles.importCurveNameContainer}>{curve.name}</div>
                        <div className={styles.modificationDateContainer}>
                          <FormattedMessage id="home.lastModification" />
                          {getFormattedDate(new Date(curve.last_update))}
                        </div>
                      </div>
                      {curve.data.x1.length !== 0 && (
                        <ConstructorCurveGraph
                          width={curvesWidth / 2 - 20}
                          height={400}
                          margin={marginGraphInCard}
                          xAxis={findMinMax(curve.data.x2)}
                          yAxis={findMinMax(curve.data.x1)}
                          dataPlotly={createPlotlyData(curve)}
                          xAxisName={
                            ParameterNameVariableMapper[
                              parameterName as keyof typeof ParameterNameVariableMapper
                            ].x_axis_name
                          }
                          yAxisName={
                            curve.name === CURVE_TYPE.HeatRate
                              ? intl.formatMessage({ id: 'home.heatRateCoefficient' })
                              : intl.formatMessage({ id: 'home.powerCoefficient' })
                          }
                        />
                      )}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default ManufacturerInformation;
