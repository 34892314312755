import { PayloadAction } from '@reduxjs/toolkit';
import { AdminState } from '../states/isAdmin/is-admin.state';

export const setIsAdminReducer = (
  state: AdminState,
  action: PayloadAction<boolean | undefined>,
) => {
  state.isAdmin = action.payload;
  state.isLoading = false;
};
