import { PayloadAction } from '@reduxjs/toolkit';
import { StateObservable, ofType } from 'redux-observable';
import { from, map, Observable, catchError, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import {
  setRetrieveAllGainsFailure,
  setRetrieveAllGainsSuccess,
} from '../../../../redux/allGains/allGains.slice';
import { Gain } from '../../../models/gain/gain.model';

export const retrieveAllGainEpic = (
  action$: Observable<PayloadAction<string>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('allGains/setRetrieveAllGains'),
    switchMap(() => {
      return from(dependencies.siteGateway.retrieveAllGains()).pipe(
        map((gain: Gain[]) => setRetrieveAllGainsSuccess(gain)),
        catchError(() => of(setRetrieveAllGainsFailure(`Retrieving all gains has failed`))),
      );
    }),
  );
