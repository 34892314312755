import { ForecastInformationDTO } from './../../../corelogic/domain/models/overview/forecastInformation.dto';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { axiosP2MSchematicApiConfiguration } from '../../../configurations/axios.configuration';
import { OverviewGateway } from '../../../corelogic/domain/gateways/overview.gateways';
import { OverviewDTO } from '../../../corelogic/domain/models/overview/overview.dto';
import { acquireAccessToken } from '../providers/authentication/tokenProvider';

const axiosClient: AxiosInstance = axios.create(axiosP2MSchematicApiConfiguration);
export class ApiOverviewGateway implements OverviewGateway {
  retrieveOverview = async (): Promise<OverviewDTO[]> => {
    const response: AxiosResponse = await axiosClient.get<OverviewDTO[]>(`/overview`, {
      ...axiosP2MSchematicApiConfiguration,
      headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
    });
    return response.data.map((item: any) => ({
      ...item,
      overviewData: item.overviewData.map((data: any) => ({
        ...data,
        timestamp: new Date(data.timestamp),
      })),
    })) as OverviewDTO[];
  };

  retrieveForecastInformation = async (
    siteId: string,
    startDate: string,
    endDate: string,
  ): Promise<ForecastInformationDTO[]> => {
    const response: AxiosResponse = await axiosClient.get<ForecastInformationDTO[]>(
      `/overview/${siteId}`,
      {
        ...axiosP2MSchematicApiConfiguration,
        headers: { Authorization: 'Bearer ' + (await acquireAccessToken()) },
        params: { startDate, endDate },
      },
    );
    return response.data;
  };
}
