import { PayloadAction } from '@reduxjs/toolkit';
import { StateObservable, ofType } from 'redux-observable';
import { switchMap, from, map, Observable, catchError, of } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import {
  setRetrieveGainFailure,
  setRetrieveGainSuccess,
} from '../../../../redux/currentTimeseries/gain/gain.slice';
import { Gain } from '../../../models/gain/gain.model';

export const retrieveGainEpic = (
  action$: Observable<PayloadAction<string>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('gain/setRetrieveGain'),
    switchMap(action => {
      return from(dependencies.siteGateway.retrieveGain(action.payload)).pipe(
        map((gain: Gain) => setRetrieveGainSuccess(gain)),
        catchError(() =>
          of(setRetrieveGainFailure(`Retrieving site gain ${action.payload} has failed`)),
        ),
      );
    }),
  );
