import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import { SiteDeletion } from '../../../models/site/site.model';
import { setDeleteSiteSuccess, setDeleteSiteFailure } from '../../../../redux/site/site.slice';

export const deleteSiteEpic = (
  action$: Observable<PayloadAction<{ siteId: string }>>,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('site/setDeleteSite'),
    switchMap(action => {
      return from(dependencies.siteGateway.deleteSite(action.payload.siteId)).pipe(
        map((site: SiteDeletion) => setDeleteSiteSuccess(site)),
        catchError(() =>
          of(setDeleteSiteFailure(`Delete site ${action.payload.siteId} has failed`)),
        ),
      );
    }),
  );
