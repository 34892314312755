import { PayloadAction } from '@reduxjs/toolkit';
import { ContextCurrentMenuStatusState } from '../states/menuStatus/context-current-menu-status.state';

export const setContextCurrentMenuStatusReducer = (
  state: ContextCurrentMenuStatusState,
  action: PayloadAction<boolean>,
) => {
  state.menuStatus = action.payload;
  state.isLoading = false;
};
