/* eslint-disable prettier/prettier */
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Identity } from '../../../../../corelogic/domain/models/site/identity.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setUpdateSiteParameter } from '../../../../../corelogic/redux/site/site.slice';
import { getFormattedDateISO } from '../../../../../helpers/dateFunction';
import { CustomButton, CustomTextField, isNullOrUndefined } from '../../../../../util/functionUtil';
import styles from './popinIdentity.module.scss';

type Props = { isOpen: boolean; handleClose: () => void };

const PopinIdentity: React.FC<Props> = ({ isOpen, handleClose }) => {
  const { site } = useSelector((state: RootState) => state.site);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [updatedName, setUpdatedName] = useState<string | null>(site.identity.manufacturerName);
  const [updatedCountry, setUpdatedCountry] = useState<string | null>(site.identity.country);
  const [updatedLastCC, setUpdatedLastCC] = useState<string | null>(site.identity.lastRevisionDate);
  const [updatedPowerOutput, setUpdatedPowerOutput] = useState<number | null>(
    site.identity.powerOutput,
  );
  const [updatedIGV, setUpdatedIGV] = useState<number | null>(site.identity.igvPosition);
  const [updatedPmin, setUpdatedPmin] = useState<number | null>(site.identity.pMin);
  const [updatedPminPercentage, setUpdatedPminPercentage] = useState<number | null>(
    site.identity.pMinPercentage,
  );
  const [updatedFloor, setUpdatedFloor] = useState<number | null>(site.identity.floor);
  const [updatedLHV, setUpdatedLHV] = useState<number | null>(site.identity.heatRate);
  const [updatedNHR, setUpdatedNHR] = useState<number | null>(site.identity.netHeatRate);
  const [updatedMajorMaintenance, setUpdatedMajorMaintenance] = useState<string | null>(
    site.identity.lastMajorMaintenance,
  );

  const P_MIN = 'fixed';
  const P_MIN_PERCENTAGE = 'variable';

  let pminOptionInit = '';
  if (site.identity.pMin !== null) {
    pminOptionInit = P_MIN;
  } else if (site.identity.pMinPercentage !== null) {
    pminOptionInit = P_MIN_PERCENTAGE;
  }

  const [pminOption, setPminOption] = useState<string>(pminOptionInit);

  const updateSiteIdentity = () => {
    const id: Identity = {
      manufacturerName: updatedName ?? '',
      heatRate: updatedLHV,
      netHeatRate: updatedNHR,
      igvPosition: updatedIGV,
      lastMajorMaintenance: updatedMajorMaintenance ?? '',
      lastRevisionDate: updatedLastCC ?? '',
      powerOutput: updatedPowerOutput,
      pMin: updatedPmin,
      pMinPercentage: updatedPminPercentage,
      floor: updatedFloor,
      country: updatedCountry,
    };
    const updatedBodyIdentity = { identity: id };
    dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: updatedBodyIdentity }));
    handleClose();
  };

  const isInputInvalid = (): boolean => {
    if (
      isNullOrUndefined(updatedName) ||
      isNullOrUndefined(updatedLastCC) ||
      isNullOrUndefined(updatedPowerOutput) ||
      isNullOrUndefined(updatedLHV) ||
      isNullOrUndefined(updatedIGV) ||
      isNullOrUndefined(updatedNHR) ||
      (isNullOrUndefined(updatedPmin) && isNullOrUndefined(updatedPminPercentage))
    ) {
      return true;
    }

    if (updatedPmin && updatedPmin < 0) {
      return true;
    }
    if (updatedPminPercentage && (updatedPminPercentage < 0 || updatedPminPercentage > 100)) {
      return true;
    }
    if (updatedFloor && updatedFloor < 0) {
      return true;
    }
    if ((updatedFloor && !updatedPminPercentage) || (!updatedFloor && updatedPminPercentage)) {
      return true;
    }
    return false;
  };

  return (
    <Dialog
      PaperProps={{ sx: { borderRadius: '16px' } }}
      className={styles.dialogContainer}
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormattedMessage id="identity.edit" />
          <div className={styles.buttonContainerIdentity}>
            <CustomButton
              variant="outlined"
              type="button"
              className="btn btn-secondary "
              onClick={handleClose}
              color="primary"
            >
              <FormattedMessage id="button.cancel" />
            </CustomButton>
            <CustomButton
              variant="contained"
              type="button"
              disabled={isInputInvalid()}
              className="btn btn-primary "
              onClick={updateSiteIdentity}
              color="primary"
            >
              <FormattedMessage id="button.publish" />
            </CustomButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.mandatoryTextContainer}>
          <div>
            <FormattedMessage id="popin.field" />
          </div>
          <div style={{ color: 'red' }}> * </div>
          <div>
            <FormattedMessage id="popin.mandatoryField" />
          </div>
        </div>
        <Typography className={styles.advice} variant="body2">
          <FormattedMessage id="identity.advice" />
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              label={intl.formatMessage({ id: 'identity.manufacturerName' })}
              size="small"
              InputLabelProps={{ shrink: true }}
              type="text"
              onChange={e => {
                if (e.target.value === '') {
                  setUpdatedName(null);
                } else {
                  setUpdatedName(e.target.value);
                }
              }}
              defaultValue={updatedName ?? '-'}
              id="manufacturerName"
              required
            ></CustomTextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: 'country.country' })}
              size="small"
              sx={{ borderRadius: '8px' }}
              onChange={e => {
                setUpdatedCountry(e.target.value);
              }}
              defaultValue={updatedCountry ?? '-'}
              id="country"
              required
              select
            >
              <MenuItem value={'france'}>
                <FormattedMessage id={'country.france'} />
              </MenuItem>
              <MenuItem value={'belgium'}>
                <FormattedMessage id={'country.belgium'} />
              </MenuItem>
              <MenuItem value={'spain'}>
                <FormattedMessage id={'country.spain'} />
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              size="small"
              type="number"
              label={intl.formatMessage({ id: 'identity.powerOutput' })}
              defaultValue={updatedPowerOutput ?? '-'}
              InputLabelProps={{ shrink: true }}
              onChange={e => {
                if (e.target.value === '') {
                  setUpdatedPowerOutput(null);
                } else {
                  setUpdatedPowerOutput(Number.parseFloat(e.target.value));
                }
              }}
              id="powerOutput"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FormattedMessage id="unit.mw" />
                  </InputAdornment>
                ),
              }}
            ></CustomTextField>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              size="small"
              type="number"
              label={intl.formatMessage({ id: 'identity.heatRate' })}
              InputLabelProps={{ shrink: true }}
              onChange={e => {
                if (e.target.value === '') {
                  setUpdatedLHV(null);
                } else {
                  setUpdatedLHV(Number.parseFloat(e.target.value));
                }
              }}
              defaultValue={updatedLHV ?? '-'}
              id="heatRate"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FormattedMessage id="unit.kjkwh" />
                  </InputAdornment>
                ),
              }}
            ></CustomTextField>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              sx={{ width: '100%' }}
              size="small"
              label={intl.formatMessage({ id: 'identity.netHeatRate' })}
              type="number"
              onChange={e => {
                if (e.target.value === '') {
                  setUpdatedNHR(null);
                } else {
                  setUpdatedNHR(Number.parseFloat(e.target.value));
                }
              }}
              defaultValue={updatedNHR ?? '-'}
              id="netHeatRate"
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FormattedMessage id="unit.kjkwh" />
                  </InputAdornment>
                ),
              }}
            ></CustomTextField>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              type="number"
              label={intl.formatMessage({ id: 'identity.igvPosition' })}
              onChange={e => {
                if (e.target.value === '') {
                  setUpdatedIGV(null);
                } else {
                  setUpdatedIGV(Number.parseFloat(e.target.value));
                }
              }}
              id="igvPosition"
              defaultValue={updatedIGV ?? '-'}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            ></CustomTextField>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <CustomTextField
                    label={intl.formatMessage({ id: 'identity.pmin' })}
                    value={pminOption}
                    onChange={event => setPminOption(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                    select
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      <FormattedMessage id="button.select" />
                    </MenuItem>
                    <MenuItem value={P_MIN}>
                      <FormattedMessage id="identity.pminFixed" />
                    </MenuItem>
                    <MenuItem value={P_MIN_PERCENTAGE}>
                      <FormattedMessage id="identity.pminVariable" />
                    </MenuItem>
                  </CustomTextField>
                </FormControl>
              </Grid>
              {pminOption == P_MIN && (
                <Grid item xs={6}>
                  <CustomTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={e => {
                      if (e.target.value === '') {
                        setUpdatedPmin(null);
                      } else {
                        setUpdatedPmin(Number.parseFloat(e.target.value));
                        setUpdatedPminPercentage(null);
                        setUpdatedFloor(null);
                      }
                    }}
                    error={!!(updatedPmin && updatedPmin < 0)}
                    helperText={
                      updatedPmin && updatedPmin < 0
                        ? intl.formatMessage({ id: 'errorMessage.noNegative' })
                        : null
                    }
                    id="lastMajorMaintenance"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">MW</InputAdornment>,
                    }}
                    defaultValue={updatedPmin}
                    required
                  ></CustomTextField>
                </Grid>
              )}
              {pminOption == P_MIN_PERCENTAGE && (
                <Fragment>
                  <Grid item xs={3}>
                    <CustomTextField
                      fullWidth
                      size="small"
                      type="number"
                      error={
                        !!(
                          updatedPminPercentage &&
                          (updatedPminPercentage < 0 || updatedPminPercentage > 100)
                        )
                      }
                      helperText={
                        updatedPminPercentage &&
                        (updatedPminPercentage < 0 || updatedPminPercentage > 100)
                          ? intl.formatMessage({ id: 'errorMessage.percentage' })
                          : null
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      onChange={e => {
                        if (e.target.value === '') {
                          setUpdatedPminPercentage(null);
                        } else {
                          setUpdatedPminPercentage(Number.parseFloat(e.target.value));
                          setUpdatedPmin(null);
                        }
                      }}
                      id="lastMajorMaintenance"
                      defaultValue={updatedPminPercentage}
                    ></CustomTextField>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      fullWidth
                      label="Floor"
                      variant="outlined"
                      size="small"
                      type="number"
                      error={!!(updatedFloor && updatedFloor < 0)}
                      InputLabelProps={{ shrink: true }}
                      helperText={
                        updatedFloor && updatedFloor < 0
                          ? intl.formatMessage({ id: 'errorMessage.floor' })
                          : null
                      }
                      InputProps={{
                        endAdornment: <InputAdornment position="end">MW</InputAdornment>,
                      }}
                      onChange={e => {
                        if (e.target.value === '') {
                          setUpdatedFloor(null);
                        } else {
                          setUpdatedFloor(Number.parseFloat(e.target.value));
                          setUpdatedPmin(null);
                        }
                      }}
                      id="lastMajorMaintenance"
                      defaultValue={updatedFloor}
                    ></CustomTextField>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              label={intl.formatMessage({ id: 'identity.lastMajorMaintenance' })}
              size="small"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={e => setUpdatedMajorMaintenance(e.target.value)}
              id="lastMajorMaintenance"
              defaultValue={
                updatedMajorMaintenance !== null
                  ? getFormattedDateISO(new Date(updatedMajorMaintenance))
                  : ''
              }
              required
            ></CustomTextField>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              label={intl.formatMessage({ id: 'identity.lastRevisionDate' })}
              InputLabelProps={{ shrink: true }}
              size="small"
              defaultValue={
                updatedLastCC !== null ? getFormattedDateISO(new Date(updatedLastCC)) : ''
              }
              onChange={e => {
                if (e.target.value === '') {
                  setUpdatedLastCC(null);
                } else {
                  setUpdatedLastCC(e.target.value);
                }
              }}
              type="date"
              id="lastRevisionDate"
              required
            ></CustomTextField>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PopinIdentity;
