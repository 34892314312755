import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Failure } from '../../../../../corelogic/domain/models/failures/failures.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setUpdateSiteParameter } from '../../../../../corelogic/redux/site/site.slice';
import { getFormattedDateISO } from '../../../../../helpers/dateFunction';
import { CustomButton } from '../../../../../util/functionUtil';
import styles from './failuresPopin.module.scss';

type Props = {
  failure?: Failure;
  isNew: boolean;
  isOpen: boolean;
  handleClose: () => void;
};

const FailuresPopin: React.FC<Props> = ({ failure, isOpen, isNew, handleClose }) => {
  const intl = useIntl();
  const theme = useTheme();
  const { site } = useSelector((state: RootState) => state.site);
  const dispatch = useDispatch();
  const [updateDescriptionState, setUpdateDescriptionState] = useState<string>(
    failure?.description ?? '',
  );
  const [updateStartDateState, setUpdateStartDateState] = useState<string>(
    failure?.startDate ?? '',
  );
  const [updateEndDateState, setUpdateEndDateState] = useState<string>(failure?.endDate ?? '');
  const [updateImpactHeatState, setUpdateImpactHeatState] = useState<string>();
  const [updateImpactPowerState, setUpdateImpactPowerState] = useState<string>(
    failure?.impactPower.toString() ?? '',
  );

  function getHeatImpact(): string | undefined {
    if (failure?.impactHeat) return failure.impactHeat?.toString();
    else return undefined;
  }

  function getEndDate(): string {
    if (failure?.endDate) return getFormattedDateISO(new Date(failure.endDate?.toString()));
    else return '';
  }

  function resetState(): void {
    setUpdateDescriptionState('');
    setUpdateStartDateState('');
    setUpdateEndDateState('');
    setUpdateImpactHeatState('');
    setUpdateImpactPowerState('');
  }

  function updateSiteFailures(): void {
    const newFailure: Failure = {
      description: updateDescriptionState,
      endDate: updateEndDateState || null,
      impactHeat: updateImpactHeatState !== undefined ? parseInt(updateImpactHeatState) : null,
      impactPower: parseFloat(updateImpactPowerState),
      startDate: updateStartDateState,
    };

    const updatedFailures = [...site.failures];
    if (isNew) {
      updatedFailures.push(newFailure);
    } else {
      const index = updatedFailures.findIndex(e => e == failure);
      updatedFailures[index] = newFailure;
    }

    const updateBodyFailures = { failures: updatedFailures };
    dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: updateBodyFailures }));
    resetState();
    handleClose();
  }

  return (
    <Dialog
      className={styles.dialogContainer}
      autoFocus
      id="failure"
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '90%', width: '50%' },
      }}
    >
      <DialogTitle>
        <div className={styles.titleAndButtonsPopin}>
          <div>
            <FormattedMessage id="failures.declare" />
          </div>
          <div className={styles.buttonContainerTags}>
            <div className={styles.buttonTags}>
              <CustomButton
                variant="outlined"
                type="button"
                className="btn btn-secondary "
                onClick={handleClose}
                color="primary"
              >
                <FormattedMessage id="button.cancel" />
              </CustomButton>
            </div>
            <div className={styles.buttonTags}>
              <CustomButton
                type="button"
                variant="contained"
                className="btn btn-primary "
                onClick={updateSiteFailures}
                color="primary"
                disabled={
                  updateDescriptionState == '' ||
                  updateStartDateState === '' ||
                  updateImpactPowerState === ''
                }
              >
                <FormattedMessage id="button.publish" />
              </CustomButton>
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          className={styles.mandatoryTextContainer}
          style={{ color: theme.palette.text.secondary }}
        >
          <div>
            <FormattedMessage id="popin.field" />
          </div>
          <div style={{ color: 'red' }}> * </div>
          <div>
            <FormattedMessage id="popin.mandatoryField" />
          </div>
        </div>
        <div>
          <div className={styles.descriptionContainer}>
            <div className={styles.titleContainer}>
              <span style={{ color: 'red' }}> * </span>{' '}
              <div className={styles.infoTitleContainer}>
                <FormattedMessage id="failures.description" />
              </div>
            </div>
            <textarea
              className={clsx(styles.inputFailuresContainer, styles.description)}
              id="description"
              required
              onChange={e => setUpdateDescriptionState(e.target.value)}
              placeholder={intl.formatMessage({ id: 'failures.defautMessageMetroscopeFailure' })}
              defaultValue={isNew ? undefined : failure?.description}
              style={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
            ></textarea>
          </div>
          <div className={styles.infoContainer}>
            <div>
              <div className={styles.titleContainer}>
                <span style={{ color: 'red' }}> * </span>{' '}
                <div className={styles.infoTitleContainer}>
                  <FormattedMessage id="failures.start" />
                </div>
              </div>
              <DatePicker
                onChange={e => setUpdateStartDateState(e?.toISOString() ?? '')}
                maxDate={new Date()}
                defaultValue={isNew ? undefined : failure && parseISO(failure.startDate)}
                slotProps={{ textField: { size: 'small' }, openPickerButton: { color: 'primary' } }}
                sx={{
                  '.MuiInputBase-root': {
                    borderRadius: '8px',
                  },
                }}
              ></DatePicker>
            </div>
            <div>
              <div className={styles.titleContainer}>
                <span style={{ color: 'red' }}> * </span>
                <div className={styles.infoTitleContainer}>
                  <FormattedMessage id="failures.impactPower" />
                </div>
              </div>
              <div className={styles.inputContainer}>
                <FormControl>
                  <OutlinedInput
                    id="impactPower"
                    startAdornment="-"
                    endAdornment={intl.formatMessage({ id: 'unit.mw' })}
                    onChange={e => setUpdateImpactPowerState(e.target.value)}
                    className={styles.inputFailuresContainer}
                    required
                    type="number"
                    style={{ borderRadius: '8px' }}
                    defaultValue={isNew ? undefined : failure?.impactPower}
                  ></OutlinedInput>
                  <div></div>
                </FormControl>
              </div>
            </div>
            <div>
              <div className={styles.titleContainer}>
                <div className={styles.infoTitleContainer}>
                  <FormattedMessage id="failures.impactHeat" />
                </div>
              </div>
              <div className={styles.inputContainer}>
                <FormControl fullWidth>
                  <OutlinedInput
                    style={{ borderRadius: '8px' }}
                    id="impactHeat"
                    onChange={e => setUpdateImpactHeatState(e.target.value)}
                    className={styles.inputFailuresContainer}
                    type="number"
                    defaultValue={isNew ? undefined : getHeatImpact()}
                    startAdornment="+"
                    endAdornment={intl.formatMessage({ id: 'unit.kjkwh' })}
                  ></OutlinedInput>
                </FormControl>
              </div>
            </div>
            <div>
              <div className={styles.titleContainer}>
                <div className={styles.infoTitleContainer}>
                  <FormattedMessage id="failures.end" />
                </div>
              </div>
              <DatePicker
                onChange={e => setUpdateEndDateState(e?.toISOString() ?? '')}
                defaultValue={isNew ? undefined : failure && parseISO(getEndDate())}
                slotProps={{ textField: { size: 'small' }, openPickerButton: { color: 'primary' } }}
                sx={{
                  '.MuiInputBase-root': {
                    borderRadius: '8px',
                  },
                }}
              ></DatePicker>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FailuresPopin;
