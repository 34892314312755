import { CardContent } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { GainsByYearModel } from '../../../../../../../corelogic/domain/models/gain/gain.model';
import { RootState } from '../../../../../../../corelogic/redux/create-store';
import { Spinner } from '../../../../../../../ui/spinner/spinner.component';
import { CustomCard } from '../../../../../../../util/functionUtil';
import DropDownYearWithGainList from '../../../dropdown/dropDownYearWithGainList/dropdown-year-with-gain-list';
import styles from './gainCounter.module.scss';

const GainCounter: FC = () => {
  const { isLoading, gain } = useSelector((state: RootState) => state.gain);
  const { year } = useSelector((state: RootState) => state.yearOfCurrentGain);

  const minutesOfPmd: number | undefined =
    gain !== null
      ? gain.gains_counters.find((gain: GainsByYearModel) => gain.year === year)
          ?.cumulated_pmd_minutes
      : undefined;

  const intl = useIntl();
  if (isLoading === true) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <CustomCard
      boxShadow="unset"
      sx={{
        padding: '0px',
        height: '100%',
        minWidth: '338px',
      }}
    >
      <CardContent
        sx={{
          padding: '0px 32px',
          '&:last-child': { pb: 0 },
          width: '100%',
        }}
      >
        <div className={styles.yearAndGains}>
          <div className={styles.infoContainer}>
            <div className={styles.gainInformationPrimary}>
              <div className={styles.gainCounterTitlePrimary}>
                <FormattedMessage id="generalDashboard.gainCounter" />
              </div>
              <span className={styles.gainValueAndUnit}>
                <div className={styles.gainCounterValuePrimary}>
                  {gain !== null ? (
                    gain.gains_counters
                      .find((gain: GainsByYearModel) => gain.year === year)
                      ?.gain_counter.toFixed(2)
                  ) : (
                    <FormattedMessage id="generalDashboard.NA" />
                  )}
                </div>
                <div className={styles.unit}>{intl.formatMessage({ id: 'unit.mwh' })}</div>
              </span>
            </div>
            <div className={styles.gainSecondary}>
              <div className={styles.gainInformation}>
                <div className={styles.gainCounterTitleSecondary}>
                  <FormattedMessage id="generalDashboard.cumulated_pmd_hour" />
                </div>
                <span className={styles.gainValueAndUnit}>
                  <div className={styles.gainCounterValueSecondary}>
                    {minutesOfPmd !== undefined ? (
                      (minutesOfPmd / 60).toFixed(2)
                    ) : (
                      <FormattedMessage id="generalDashboard.NA" />
                    )}
                  </div>
                  <div className={styles.unit}>{'h'}</div>
                </span>
              </div>
              <div className={styles.gainInformation}>
                <div className={styles.gainCounterTitleSecondary}>
                  <FormattedMessage id="generalDashboard.mean_gain_counter_on_pmd" />
                </div>
                <span className={styles.gainValueAndUnit}>
                  <div className={styles.gainCounterValueSecondary}>
                    {gain !== null ? (
                      gain.gains_counters
                        .find((gain: GainsByYearModel) => gain.year === year)
                        ?.mean_gain_counter_on_pmd.toFixed(2)
                    ) : (
                      <FormattedMessage id="generalDashboard.NA" />
                    )}
                  </div>
                  <div className={styles.unit}>{intl.formatMessage({ id: 'unit.mwh/PMD' })}</div>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.dropDown}>
            <DropDownYearWithGainList />
          </div>
        </div>
      </CardContent>
    </CustomCard>
  );
};

export default GainCounter;
